// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
//import useSWR from "swr";
import { useEffect, useState } from "react";
//import { Token } from "utils/clientFunctions";
import MDButton from "components/MDButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  Tooltip,
} from "@mui/material";
//import { settingDefault } from "utils/clientFunctions";
import { ErrorMessage, Field, Form, Formik } from "formik";
import MDInput from "components/MDInput";
import * as Yup from "yup";
import { updateData } from "utils/clientFunctions";

import { fetchData } from "utils/clientFunctions";
import useSWR from "swr";

import { postData, doUpload } from "utils/clientFunctions";
import { ToastProvider, useToasts } from "react-toast-notifications";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button } from "@mui/material";
import { useNavigate, useParams, Navigate, Link } from "react-router-dom";
import MDAvatar from "components/MDAvatar";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "../../../../src/assets/css/Custom.css";
import { useFormik } from "formik";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import { removeDuplicates } from "utils/clientFunctions";
import { bookingSchema } from "../components/schemas";
import { useSelector, useDispatch } from "react-redux";
import { getPermissions, selectPermissions } from "reducers/permissionSlice";
function SaveBooking() {
  const { id: bookingId } = useParams();

  const [countryList, setCountryList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [bookingTypeList, setBookingTypeList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [documents, setDocuments] = useState([]);
  const { addToast } = useToasts();
  const navigate = useNavigate();
  //Start : For permission
  const dispatch = useDispatch();
  const permissions = useSelector(selectPermissions);
  if (bookingId) {
    if (
      !(
        permissions?.user_id === 1 ||
        permissions?.permission?.includes("bokEdit")
      )
    ) {
      navigate("/booking_list");
    }
  } else {
    if (
      !(
        permissions?.user_id === 1 ||
        permissions?.permission?.includes("bokCreate")
      )
    ) {
      navigate("/booking_list");
    }
  }
  useEffect(() => {
    dispatch(getPermissions());
  }, []);
  //End : For permission

  const [formData, setFormData] = useState({
    booking_type: "",
    booking_date: "",
    reason: "",
    person_name: "",
    contact_code_id: "",
    contact_number: "",
    booking_place_name: "",
    currency_id: "",
    amount: "",
    status_id: "",
    remarks: "",
  });

  const getFetchData = async (url) => {
    return await fetchData(url);
  };

  //Get applicant, docs, religions, countries, position list and size of the company list
  useEffect(() => {
    if (bookingId) {
      const getcallReportUrl = `booking/${bookingId}`;
      getFetchData(getcallReportUrl).then((res) => {
        if (res.data) {
          let {
            booking_type,
            booking_date,
            reason,
            person_name,
            contact_number,
            booking_place_name,
            currency_info,
            amount,
            booking_type_info,
            contact_code_info,
            status_info,
            document_list,
            remarks,
          } = res.data;

          setFormData({
            booking_type: booking_type_info,
            booking_date,
            reason,
            person_name,
            contact_code_id: contact_code_info,
            contact_number,
            booking_place_name,
            currency_id: currency_info,
            amount,
            status_id: status_info,
            remarks,
          });

          let documentList = [];
          document_list?.map((document) => {
            const {
              document_name,
              issue_date,
              expiry_date,
              upload_document,
              issue_country_info,
            } = document;
            documentList.push({
              name: document_name,
              issue_country_id: issue_country_info,
              issue_date,
              expiry_date,
              document: upload_document,
            });
          });
          setDocuments(documentList);
        }
      });
    }

    //Get countries list
    const countryUrl = `country/list`;
    getFetchData(countryUrl).then((res) => {
      let countryList = res.data;
      setCountryList(countryList);
      let CurrencyList = removeDuplicates(countryList, "currency_code"); //countryList?.filter((cr)=>cr.currency_code != "")
      setCurrencyList(CurrencyList);
    });

    //Get booking type status list
    const bookingTypeUrl = `setting/type/14`;
    getFetchData(bookingTypeUrl).then((res) => {
      let bookingListResult = res.data;
      setBookingTypeList(bookingListResult);
    });

    //Get call report status list
    const statusUrl = `setting/type/8`;
    getFetchData(statusUrl).then((res) => {
      let statusListResult = res.data;
      setStatusList(statusListResult);
    });
  }, []);

  const handleFormSubmit = async (data, resetForm) => {
    //Collect final Data
    let submitData = {};
    submitData = data;

    //Update below fields to send only ids
    submitData.currency_id = data.currency_id?.country_id;
    submitData.booking_type = data.booking_type?.setting_id;
    submitData.contact_code_id = data.contact_code_id?.country_id;
    submitData.status_id = data.status_id?.setting_id;

    //Convert documents into array
    let allDocuments = [];
    if (documents.length) {
      documents?.map((doc) => {
        const { name, issue_country_id, issue_date, expiry_date, document } =
          doc;
        if (document) {
          allDocuments.push({
            document_name: name,
            issue_country_id: issue_country_id?.country_id,
            issue_date: issue_date ? issue_date : null,
            expiry_date: expiry_date ? expiry_date : null,
            upload_document: document,
          });
        }
      });
    }
    submitData.documents = allDocuments;

    const bookingUrl = `booking`;
    const response = bookingId
      ? await updateData(`${bookingUrl}/${bookingId}`, submitData)
      : await postData(bookingUrl, submitData);

    if (!response.status) {
      if (response.message) {
        addToast(response.message, { appearance: "error", autoDismiss: true });
      }
      if (response.errors) {
        let apiErrors = response.errors;
        for (let i = 0; i < apiErrors.length; i++) {
          let errorMessage = apiErrors[i].message;
          errorMessage = errorMessage.replace(/['"]+/g, "").replace(/_/g, " ");
          errorMessage =
            errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
          addToast(errorMessage, { appearance: "error", autoDismiss: true });
        }
      }
    } else {
      let successMessage = bookingId
        ? "Booking has been updated successfully"
        : "New booking has been created successfully";

      addToast(successMessage, {
        appearance: "success",
        autoDismiss: true,
      });
      navigate(-1);
    }
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: formData,
    enableReinitialize: true,
    validationSchema: bookingSchema,
    onSubmit: (values, action) => {
      handleFormSubmit(values, action);
    },
  });

  // START Manage Document
  const addMoreDocument = () => {
    setDocuments([...documents, {}]);
  };
  const handleDocumentChange = async (e, i) => {
    const { name, value } = e.target;
    const onchangeVal = [...documents];
    if (name == "document") {
      let val = e.target.files[0];
      let uploadFile = { file: val };
      const url = `upload_any/do_upload`;
      const response = await doUpload(url, uploadFile);
      if (response.status && response.uploadedFile) {
        let filePath = response.uploadedFile.path;
        onchangeVal[i][name] = filePath;
      } else {
        onchangeVal[i][name] = "";
      }
    } else {
      onchangeVal[i][name] = value;
    }
    setDocuments(onchangeVal);
  };
  const handleDocumentDelete = (i) => {
    const deleteVal = [...documents];
    deleteVal.splice(i, 1);
    setDocuments(deleteVal);
  };
  // END Manage Document

  const commaonError = (fieldName) => {
    return errors?.[fieldName] && touched?.[fieldName] ? (
      <p className="formError">{errors[fieldName]}</p>
    ) : null;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Formik
          initialValues={formData}
          enableReinitialze={true}
          onSubmit={handleSubmit}
        >
          <Form>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-5}
                    py={1}
                    borderRadius="lg"
                    px={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    variant="gradient"
                    bgColor="info"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      {`${bookingId ? "Update" : "New"} `} Booking
                    </MDTypography>
                    <MDButton
                      variant="outlined"
                      onClick={() => navigate(-1)}
                      color="white"
                      size="small"
                    >
                      Back
                    </MDButton>
                  </MDBox>
                  <MDBox
                    variant="contained"
                    bgColor="white"
                    color="black"
                    borderRadius="md"
                    shadow="md"
                  >
                    <DialogContent>
                      <Box sx={{ flexGrow: 1 }}>
                        <MDBox pt={1} pb={1}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.booking_type}
                                  size="small"
                                  name="booking_type"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue("booking_type", newValue)
                                      : setFieldValue("booking_type", "")
                                  }
                                  options={bookingTypeList}
                                  getOptionLabel={(bookingTypeList) =>
                                    bookingTypeList.setting_value || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Booking Type"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {commaonError("booking_type")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  type="date"
                                  name="booking_date"
                                  label="Booking Date"
                                  fullWidth
                                  value={values.booking_date}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("booking_date")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  type="text"
                                  name="reason"
                                  label="Booking Reason"
                                  fullWidth
                                  value={values.reason}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("reason")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  type="text"
                                  name="booking_place_name"
                                  label="Place Name"
                                  fullWidth
                                  value={values.booking_place_name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("booking_place_name")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  type="text"
                                  name="person_name"
                                  label="Person Name"
                                  fullWidth
                                  value={values.person_name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("person_name")}
                              </MDBox>
                            </Grid>

                            <Grid item container xs={12} md={3} spacing={0}>
                              <Grid item xs={6} md={6}>
                                <MDBox mb={2} className="mdsAutocompleteGrid">
                                  <Autocomplete
                                    value={values.contact_code_id}
                                    size="small"
                                    name="contact_code_id"
                                    onChange={(event, newValue) =>
                                      newValue
                                        ? setFieldValue(
                                            "contact_code_id",
                                            newValue
                                          )
                                        : setFieldValue("contact_code_id", "")
                                    }
                                    options={countryList}
                                    getOptionLabel={(countryList) => {
                                      if (
                                        countryList.dial_code &&
                                        countryList.name
                                      ) {
                                        return (
                                          countryList.dial_code +
                                          " " +
                                          countryList.name
                                        );
                                      }
                                      return "";
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Country Code"
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    )}
                                  />
                                  {commaonError("contact_code_id")}
                                </MDBox>
                              </Grid>
                              <Grid item xs={6} md={6}>
                                <MDBox mb={2}>
                                  <MDInput
                                    name="contact_number"
                                    type="text"
                                    label="Contact Number"
                                    fullWidth
                                    value={values.contact_number}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {commaonError("contact_number")}
                                </MDBox>
                              </Grid>
                            </Grid>

                            <Grid item container xs={12} md={3} spacing={0}>
                              <Grid item xs={6} md={6}>
                                <MDBox mb={2} className="mdsAutocompleteGrid">
                                  <Autocomplete
                                    value={values.currency_id}
                                    size="small"
                                    name="currency_id"
                                    onChange={(event, newValue) =>
                                      newValue
                                        ? setFieldValue("currency_id", newValue)
                                        : setFieldValue("currency_id", "")
                                    }
                                    options={currencyList}
                                    getOptionLabel={(currencyList) =>
                                      currencyList.currency_code || ""
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Currency"
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    )}
                                  />
                                  {commaonError("currency_id")}
                                </MDBox>
                              </Grid>
                              <Grid item xs={6} md={6}>
                                <MDBox mb={2}>
                                  <MDInput
                                    name="amount"
                                    type="text"
                                    label="Amount"
                                    fullWidth
                                    value={values.amount}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {commaonError("amount")}
                                </MDBox>
                              </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.status_id}
                                  size="small"
                                  name="status_id"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue("status_id", newValue)
                                      : setFieldValue("status_id", "")
                                  }
                                  options={statusList}
                                  getOptionLabel={(statusList) =>
                                    statusList.setting_value || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Status"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {commaonError("status_id")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={12}>
                              <MDBox mb={2}>
                                <TextField
                                  id="outlined-multiline-static"
                                  label="Remarks *"
                                  name="remarks"
                                  InputLabelProps={{ shrink: true }}
                                  multiline
                                  fullWidth
                                  rows={3}
                                  value={values.remarks}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("remarks")}
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Box>
                    </DialogContent>
                  </MDBox>
                </Card>

                {/* Start Document Management */}
                <Card className="mt4rem">
                  <MDBox
                    mx={2}
                    mt={-5}
                    py={1}
                    borderRadius="lg"
                    px={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    variant="gradient"
                    bgColor="info"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Document Management
                    </MDTypography>
                    <MDButton
                      variant="outlined"
                      onClick={() => addMoreDocument()}
                      color="white"
                      size="small"
                    >
                      Add Document
                    </MDButton>
                  </MDBox>

                  <MDBox
                    variant="contained"
                    bgColor="white"
                    color="black"
                    borderRadius="md"
                    shadow="md"
                  >
                    <DialogContent>
                      <Box sx={{ flexGrow: 1 }}>
                        <MDBox pt={1} pb={1}>
                          <Grid container spacing={2}>
                            {documents.map((val, i) => (
                              <Grid
                                item
                                className="borderBottom"
                                style={{
                                  borderBottom: "1px solid #33333338",
                                  marginBottom: "10px",
                                }}
                                container
                                xs={12}
                                md={12}
                                key={i}
                                spacing={2}
                              >
                                <Grid item xs={12} md={3}>
                                  <MDBox mb={2}>
                                    <MDInput
                                      name="name"
                                      type="text"
                                      required
                                      fullWidth
                                      value={val.name}
                                      onChange={(e) =>
                                        handleDocumentChange(e, i)
                                      }
                                      label={`Document Name`}
                                    />
                                  </MDBox>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <MDBox className="mdsAutocompleteGrid">
                                    <Autocomplete
                                      size="small"
                                      name="issue_country_id"
                                      value={val.issue_country_id}
                                      onChange={(e, newValue) => {
                                        const issueCountryId = [...documents];
                                        if (newValue) {
                                          issueCountryId[i][
                                            "issue_country_id"
                                          ] = newValue;
                                          setDocuments(issueCountryId);
                                        } else {
                                          issueCountryId[i][
                                            "issue_country_id"
                                          ] = null;
                                          setDocuments(issueCountryId);
                                        }
                                      }}
                                      options={countryList}
                                      getOptionLabel={(countryList) =>
                                        countryList.name || ""
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Issue Country"
                                          InputLabelProps={{ shrink: true }}
                                        />
                                      )}
                                    />
                                    {commaonError("issue_country_id")}
                                  </MDBox>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <MDBox mb={2}>
                                    <MDInput
                                      type="date"
                                      name="issue_date"
                                      label="Issue Date"
                                      fullWidth
                                      value={val.issue_date}
                                      onChange={(e) =>
                                        handleDocumentChange(e, i)
                                      }
                                    />
                                  </MDBox>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                  <MDBox mb={2}>
                                    <MDInput
                                      type="date"
                                      name="expiry_date"
                                      label="Expiry Date"
                                      fullWidth
                                      value={val.expiry_date}
                                      onChange={(e) =>
                                        handleDocumentChange(e, i)
                                      }
                                    />
                                  </MDBox>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                  <MDBox
                                    mb={2}
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      // margin: "0px 2px",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      component="label"
                                      fullWidth
                                      color="success"
                                    >
                                      Upload Document
                                      <input
                                        type="file"
                                        name="document"
                                        accept="image/png, image/jpeg, application/pdf"
                                        hidden
                                        onChange={(e) =>
                                          handleDocumentChange(e, i)
                                        }
                                      />
                                    </Button>
                                    {val.document && (
                                      <>
                                        <Link
                                          to={`${process.env.REACT_APP_URI_PATH}${val.document}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="mdsViewFile"
                                        >
                                          <MDButton
                                            variant="gradient"
                                            color="primary"
                                            size="small"
                                            type="button"
                                          >
                                            View File
                                          </MDButton>
                                        </Link>
                                      </>
                                    )}
                                  </MDBox>
                                </Grid>

                                <Grid item xs={12} md={2}>
                                  <IconButton className="mdsRemoveBtn">
                                    <DeleteForeverIcon
                                      variant="gradient"
                                      color="error"
                                      type="button"
                                      fontSize="large"
                                      onClick={() => handleDocumentDelete(i)}
                                    />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            ))}
                          </Grid>
                        </MDBox>
                      </Box>
                    </DialogContent>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
            <MDBox mt={2} display="flex" alignItems="center">
              <MDButton
                variant="gradient"
                color="primary"
                size="small"
                type="submit"
                className="saveButton"
              >
                Save
              </MDButton>
            </MDBox>
          </Form>
        </Formik>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default SaveBooking;
