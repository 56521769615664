// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
//import useSWR from "swr";
import { useEffect, useState } from "react";
//import { Token } from "utils/clientFunctions";
import MDButton from "components/MDButton";
import { DialogContent } from "@mui/material";
//import { settingDefault } from "utils/clientFunctions";
import { Field, Form, Formik } from "formik";
import MDInput from "components/MDInput";
import { fetchData } from "utils/clientFunctions";
import useSWR from "swr";
import MDTypography from "components/MDTypography";

import { postData, doUpload } from "utils/clientFunctions";
import { useToasts } from "react-toast-notifications";

import { useNavigate, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "../../../../src/assets/css/Custom.css";
import { useFormik } from "formik";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { createDealSchema } from "../components/schemas";
import { CardTopBoxBack } from "layouts/commonComponents/cardTopBoxBack";
import { updateData } from "utils/clientFunctions";
import { removeDuplicates } from "utils/clientFunctions";
import { liveSearchData } from "layouts/commonComponents/liveSearchData";
import { useSelector, useDispatch } from "react-redux";
import { getPermissions, selectPermissions } from "reducers/permissionSlice";

import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";

function DealData() {
  let { id } = useParams();

  const [countryList, setCountryList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [companyList, setCompanyList] = useState([]);

  const [statusList, setStatusList] = useState([]);
  const [applicantList, setApplicantList] = useState([]);
  const [raList, setRaList] = useState([]);

  const [jobs, setJobs] = useState([]);
  const [companyPositionList, setCompanyPositionList] = useState([]);

  const { addToast } = useToasts();
  const navigate = useNavigate();

  //Start : For permission
  const dispatch = useDispatch();
  const permissions = useSelector(selectPermissions);
  if (id) {
    if (
      !(
        permissions?.user_id === 1 ||
        permissions?.permission?.includes("delEdit")
      )
    ) {
      navigate("/deal");
    }
  } else {
    if (
      !(
        permissions?.user_id === 1 ||
        permissions?.permission?.includes("delCreate")
      )
    ) {
      navigate("/deal");
    }
  }
  useEffect(() => {
    dispatch(getPermissions());
  }, []);
  //End : For permission
  let lockCreate =
    permissions?.user_id === 1 ||
    permissions?.permission?.includes("lockCreate");

  const [formData, setFormData] = useState({
    receiving_country_id: "",
    company_id: "",
    supply_country_id: "",
    ra_id: "",
    status_id: "",
    remarks: "",
  });

  const searchSpot = (value, key) => {
    let countryId =
      value === "ra"
        ? values?.supply_country_id?.country_id
        : value === "company"
        ? values?.receiving_country_id?.country_id
        : null;
    if (value && key && countryId) {
      liveSearchData({
        countryId,
        value,
        key,
        setRaList,
        setCompanyList,
        setApplicantList,
      });
    }
  };

  const getFetchData = async (settingUrl) => {
    return await fetchData(settingUrl);
  };

  //Get data
  useEffect(() => {
    if (id) {
      const updateUrl = `deal/${id}`;
      getFetchData(updateUrl).then((res) => {
        let {
          remarks,
          receiving_country_info,
          supply_country_info,
          recruiting_agency_info,
          status_info,
          company_info,
          job_list,
        } = res.data;

        setFormData({
          receiving_country_id: receiving_country_info,
          company_id: company_info,
          supply_country_id: supply_country_info,
          ra_id: recruiting_agency_info,
          status_id: status_info,
          remarks,
        });

        let jobsList = [];
        job_list?.map((job) => {
          const {
            position_info,
            currency_info,
            nationality_info,
            local_currency_info,
            overseas_currency_info,
            offered_salary,
            local_service_fee,
            overseas_service_fee,
            created_by_user_id,
            is_lock,
          } = job;

          jobsList.push({
            position_id: position_info,
            currency_id: currency_info,
            offered_salary,
            nationality_id: nationality_info,
            local_currency_id: local_currency_info,
            local_service_fee,
            overseas_currency_id: overseas_currency_info,
            overseas_service_fee,
            created_by_user_id,
            is_lock: is_lock == 1 ? "On" : "Off",
          });
        });
        setJobs(jobsList);

        //Get Company by receiving country_id
        getDataByCountry("company", receiving_country_info?.country_id);
        //For recruiting agency by supply country_id
        getDataByCountry("ra", supply_country_info?.country_id);
      });
    }

    const countryUrl = `country/list`;
    getFetchData(countryUrl).then((res) => {
      let countryList = res.data;
      setCountryList(countryList);
      let CurrencyList = removeDuplicates(countryList, "currency_code");
      setCurrencyList(CurrencyList);
    });

    const statusUrl = `setting/type/8`;
    getFetchData(statusUrl).then((res) => {
      let statusList = res.data;
      setStatusList(statusList);
    });

    // const companyUrl = `company/list?page=1`;
    // getFetchData(companyUrl).then((res) => {
    //   let companyList = res.data;
    //   //setCompanyList(companyList);
    // });

    // const raUrl = `recruiting_agency/list?page=1`;
    // getFetchData(raUrl).then((res) => {
    //   let raList = res.data;
    //   //setRaList(raList);
    // });

    //Get position list
    const positisonUrl = `setting/type/4`;
    getFetchData(positisonUrl).then((res) => {
      let positionList = res.data;
      setCompanyPositionList(positionList);
    });
  }, []);

  const handleFormSubmit = async (data, resetForm) => {
    let submitData = {};
    submitData = data;

    //Update below fields to send only ids

    submitData.receiving_country_id = data.receiving_country_id?.country_id;
    submitData.company_id = data.company_id?.company_id;
    submitData.supply_country_id = data.supply_country_id?.country_id;
    submitData.ra_id = data.ra_id?.ra_id;
    submitData.status_id = data.status_id?.setting_id;

    //Convert OwnerPartners into array
    let allJobs = [];
    if (jobs.length) {
      jobs?.map((job) => {
        const {
          position_id,
          currency_id,
          offered_salary,
          nationality_id,
          local_currency_id,
          local_service_fee,
          overseas_currency_id,
          overseas_service_fee,
          created_by_user_id,
          is_lock,
        } = job;
        if (position_id && currency_id && nationality_id) {
          allJobs.push({
            position_id: position_id?.setting_id,
            currency_id: currency_id?.country_id,
            offered_salary,
            nationality_id: nationality_id?.country_id,
            local_currency_id: local_currency_id?.country_id,
            local_service_fee,
            overseas_currency_id: overseas_currency_id?.country_id,
            overseas_service_fee,
            created_by_user_id: created_by_user_id || 0,
            is_lock: is_lock == "On" ? 1 : 0,
          });
        }
      });
    }
    submitData.jobs = allJobs;

    //console.log('submitData',submitData,data.vendor_id);
    const url = `deal`;
    const response = id
      ? await updateData(`deal/${id}`, submitData)
      : await postData(url, submitData);
    if (!response.status) {
      if (response.message) {
        addToast(response.message, { appearance: "error", autoDismiss: true });
      }
      if (response.errors) {
        let apiErrors = response.errors;
        for (let i = 0; i < apiErrors.length; i++) {
          let errorMessage = apiErrors[i].message;
          errorMessage = errorMessage.replace(/['"]+/g, "").replace(/_/g, " ");
          errorMessage =
            errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
          addToast(errorMessage, { appearance: "error", autoDismiss: true });
        }
      }
    } else {
      let successMessage = id
        ? "Deal has been updated successfully"
        : "New deal has been created successfully";
      addToast(successMessage, {
        appearance: "success",
        autoDismiss: true,
      });
      navigate(-1);
    }
  };

  const ErrorDisp = (name) => {
    return (
      errors[name] &&
      touched[name] && ( // Use `errors[name]` instead of `errors.name`
        <p className="formError">{errors[name]}</p>
      )
    );
  };

  // START Jobs section
  const addMoreJob = () => {
    setJobs([...jobs, {}]);
  };
  const handleJobChange = (e, i) => {
    let { name, value } = e.target;
    if (name === "is_lock") {
      value = value == "On" ? "Off" : "On";
    }
    const onchangeVal = [...jobs];
    onchangeVal[i][name] = value;
    setJobs(onchangeVal);
  };
  const handleJobDelete = (i) => {
    const deleteVal = [...jobs];
    deleteVal.splice(i, 1);
    setJobs(deleteVal);
  };
  // END Jobs section
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: formData,
    enableReinitialize: true,
    validationSchema: createDealSchema,
    onSubmit: ({ resetForm }) => {
      handleFormSubmit(values, resetForm);
    },
  });

  const getDataByCountry = (dataType = "ra", countryId) => {
    if (dataType === "company") {
      const companyUrl = `company/country/${countryId}`;
      getFetchData(companyUrl).then((res) => {
        let companyList = res.data;
        setCompanyList(companyList);
      });
    } else if (dataType === "ra") {
      const raUrl = `recruiting_agency/country/${countryId}`;
      getFetchData(raUrl).then((res) => {
        let raList = res.data;
        setRaList(raList);
      });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Formik
          initialValues={formData}
          enableReinitialze={true}
          onSubmit={handleSubmit}
        >
          <Form>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <CardTopBoxBack title={id ? "Update Deal" : "New Deal"} />
                  <MDBox
                    variant="contained"
                    bgColor="white"
                    color="black"
                    borderRadius="md"
                    shadow="md"
                  >
                    <DialogContent>
                      <Box sx={{ flexGrow: 1 }}>
                        <MDBox pt={1} pb={1}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.receiving_country_id}
                                  size="small"
                                  name="receiving_country_id"
                                  onChange={(event, newValue) => {
                                    getDataByCountry(
                                      "company",
                                      newValue?.country_id
                                    );
                                    if (newValue) {
                                      setFieldValue(
                                        "receiving_country_id",
                                        newValue
                                      );
                                    } else {
                                      setFieldValue("receiving_country_id", "");
                                    }
                                    setFieldValue("company_id", "");
                                  }}
                                  options={countryList}
                                  getOptionLabel={(countryList) =>
                                    countryList.name || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Receiving Country"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {ErrorDisp("receiving_country_id")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.company_id}
                                  size="small"
                                  name="company_id"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue("company_id", newValue)
                                      : setFieldValue("company_id", "")
                                  }
                                  options={companyList}
                                  getOptionLabel={(companyList) =>
                                    companyList.name || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Company"
                                      InputLabelProps={{ shrink: true }}
                                      onChange={(e) =>
                                        searchSpot("company", e.target.value)
                                      }
                                    />
                                  )}
                                />
                                {ErrorDisp("company_id")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.supply_country_id}
                                  size="small"
                                  name="supply_country_id"
                                  onChange={(event, newValue) => {
                                    getDataByCountry(
                                      "ra",
                                      newValue?.country_id
                                    );
                                    if (newValue) {
                                      setFieldValue(
                                        "supply_country_id",
                                        newValue
                                      );
                                    } else {
                                      setFieldValue("supply_country_id", "");
                                    }
                                    setFieldValue("ra_id", "");
                                  }}
                                  options={countryList}
                                  getOptionLabel={(countryList) =>
                                    countryList.name || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Supply Country"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {ErrorDisp("supply_country_id")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.ra_id}
                                  size="small"
                                  name="ra_id"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue("ra_id", newValue)
                                      : setFieldValue("ra_id", "")
                                  }
                                  options={raList}
                                  getOptionLabel={(raList) =>
                                    raList.ra_name || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Recruiting Agency"
                                      InputLabelProps={{ shrink: true }}
                                      onChange={(e) =>
                                        searchSpot("ra", e.target.value)
                                      }
                                    />
                                  )}
                                />
                                {ErrorDisp("ra_id")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.status_id}
                                  size="small"
                                  name="status_id"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue("status_id", newValue)
                                      : setFieldValue("status_id", "")
                                  }
                                  options={statusList}
                                  getOptionLabel={(statusList) =>
                                    statusList.setting_value || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Status"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {ErrorDisp("status_id")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={9}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="remarks"
                                  type="text"
                                  label="Remarks"
                                  fullWidth
                                  value={values.remarks}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {ErrorDisp("remarks")}
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Box>
                    </DialogContent>
                  </MDBox>
                </Card>

                {/* Start Jobs Management  */}
                <Card className="mt4rem">
                  <MDBox
                    mx={2}
                    mt={-5}
                    py={1}
                    borderRadius="lg"
                    px={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    variant="gradient"
                    bgColor="info"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Jobs
                    </MDTypography>
                    <MDButton
                      variant="outlined"
                      onClick={() => addMoreJob()}
                      color="white"
                      size="small"
                    >
                      Add Job
                    </MDButton>
                  </MDBox>

                  <MDBox
                    variant="contained"
                    bgColor="white"
                    color="black"
                    borderRadius="md"
                    shadow="md"
                  >
                    <DialogContent pt={1} pb={1}>
                      <Box sx={{ flexGrow: 1 }}>
                        <MDBox pt={1} pb={1}>
                          <Grid container spacing={2}>
                            {jobs.map(
                              (val, i) =>
                                (permissions?.user_id === 1 ||
                                  permissions?.user_id ===
                                    val.created_by_user_id ||
                                  val.is_lock !== "On") && (
                                  <Grid
                                    item
                                    className="borderBottom"
                                    style={{
                                      borderBottom: "1px solid #33333338",
                                    }}
                                    container
                                    xs={12}
                                    md={12}
                                    key={i}
                                    spacing={2}
                                  >
                                    <Grid item xs={12} md={3}>
                                      <MDBox className="mdsAutocompleteGrid">
                                        <Autocomplete
                                          size="small"
                                          name="position_id"
                                          value={val.position_id}
                                          onChange={(e, newValue) => {
                                            const positionId = [...jobs];
                                            if (newValue) {
                                              positionId[i]["position_id"] =
                                                newValue;
                                              setJobs(positionId);
                                            } else {
                                              positionId[i]["position_id"] =
                                                null;
                                              setJobs(positionId);
                                            }
                                          }}
                                          options={companyPositionList}
                                          getOptionLabel={(
                                            companyPositionList
                                          ) =>
                                            companyPositionList.setting_value ||
                                            ""
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label="Position"
                                              InputLabelProps={{ shrink: true }}
                                            />
                                          )}
                                        />
                                      </MDBox>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                      <MDBox className="mdsAutocompleteGrid">
                                        <Autocomplete
                                          size="small"
                                          name="currency_id"
                                          value={val.currency_id}
                                          onChange={(e, newValue) => {
                                            const currencyId = [...jobs];
                                            if (newValue) {
                                              currencyId[i]["currency_id"] =
                                                newValue;
                                              setJobs(currencyId);
                                            } else {
                                              currencyId[i]["currency_id"] =
                                                null;
                                              setJobs(currencyId);
                                            }
                                          }}
                                          options={currencyList}
                                          getOptionLabel={(currencyList) => {
                                            if (currencyList.currency_code) {
                                              return (
                                                currencyList.currency_code || ""
                                              );
                                            }
                                            return "";
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label="Currency"
                                              InputLabelProps={{ shrink: true }}
                                            />
                                          )}
                                        />
                                      </MDBox>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                      <MDBox mb={2}>
                                        <Field name="offered_salary">
                                          {({ field, form }) => (
                                            <MDInput
                                              {...field}
                                              type="number"
                                              required
                                              label="Offered Salary"
                                              fullWidth
                                              value={val.offered_salary}
                                              onChange={(e) =>
                                                handleJobChange(e, i)
                                              }
                                              onBlur={handleBlur}
                                            />
                                          )}
                                        </Field>
                                      </MDBox>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                      <MDBox className="mdsAutocompleteGrid">
                                        <Autocomplete
                                          size="small"
                                          name="nationality_id"
                                          required
                                          value={val.nationality_id}
                                          onChange={(e, newValue) => {
                                            const nationalityId = [...jobs];
                                            if (newValue) {
                                              nationalityId[i][
                                                "nationality_id"
                                              ] = newValue;
                                              setJobs(nationalityId);
                                            } else {
                                              nationalityId[i][
                                                "nationality_id"
                                              ] = null;
                                              setJobs(nationalityId);
                                            }
                                          }}
                                          options={countryList}
                                          getOptionLabel={(countryList) => {
                                            if (countryList.name) {
                                              return countryList.name || "";
                                            }
                                            return "";
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label="Nationality"
                                              InputLabelProps={{ shrink: true }}
                                            />
                                          )}
                                        />
                                      </MDBox>
                                    </Grid>

                                    <Grid
                                      item
                                      container
                                      xs={12}
                                      md={3}
                                      spacing={0}
                                    >
                                      <Grid item xs={6} md={6}>
                                        <MDBox className="mdsAutocompleteGrid">
                                          <Autocomplete
                                            size="small"
                                            name="local_currency_id"
                                            value={val.local_currency_id}
                                            onChange={(e, newValue) => {
                                              const localCurrencyId = [...jobs];
                                              if (newValue) {
                                                localCurrencyId[i][
                                                  "local_currency_id"
                                                ] = newValue;
                                                setJobs(localCurrencyId);
                                              } else {
                                                localCurrencyId[i][
                                                  "local_currency_id"
                                                ] = null;
                                                setJobs(localCurrencyId);
                                              }
                                            }}
                                            options={currencyList}
                                            getOptionLabel={(currencyList) => {
                                              if (currencyList.currency_code) {
                                                return (
                                                  currencyList.currency_code ||
                                                  ""
                                                );
                                              }
                                              return "";
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label="Local Currency"
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                              />
                                            )}
                                          />
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={6} md={6}>
                                        <MDBox mb={2}>
                                          <Field name="local_service_fee">
                                            {({ field, form }) => (
                                              <MDInput
                                                {...field}
                                                type="text"
                                                label="Local Service Fee"
                                                fullWidth
                                                value={val.local_service_fee}
                                                onChange={(e) =>
                                                  handleJobChange(e, i)
                                                }
                                                onBlur={handleBlur}
                                              />
                                            )}
                                          </Field>
                                        </MDBox>
                                      </Grid>
                                    </Grid>

                                    <Grid
                                      item
                                      container
                                      xs={12}
                                      md={3}
                                      spacing={0}
                                    >
                                      <Grid item xs={6} md={6}>
                                        <MDBox className="mdsAutocompleteGrid">
                                          <Autocomplete
                                            size="small"
                                            name="overseas_currency_id"
                                            value={val.overseas_currency_id}
                                            onChange={(e, newValue) => {
                                              const overseasCurrencyId = [
                                                ...jobs,
                                              ];
                                              if (newValue) {
                                                overseasCurrencyId[i][
                                                  "overseas_currency_id"
                                                ] = newValue;
                                                setJobs(overseasCurrencyId);
                                              } else {
                                                overseasCurrencyId[i][
                                                  "overseas_currency_id"
                                                ] = null;
                                                setJobs(overseasCurrencyId);
                                              }
                                            }}
                                            options={currencyList}
                                            getOptionLabel={(currencyList) => {
                                              if (currencyList.currency_code) {
                                                return (
                                                  currencyList.currency_code ||
                                                  ""
                                                );
                                              }
                                              return "";
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label="Overseas Currency"
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                              />
                                            )}
                                          />
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={6} md={6}>
                                        <MDBox mb={2}>
                                          <Field name="overseas_service_fee">
                                            {({ field, form }) => (
                                              <MDInput
                                                {...field}
                                                type="text"
                                                label="Overseas Service Fee"
                                                fullWidth
                                                value={val.overseas_service_fee}
                                                onChange={(e) =>
                                                  handleJobChange(e, i)
                                                }
                                                onBlur={handleBlur}
                                              />
                                            )}
                                          </Field>
                                        </MDBox>
                                      </Grid>
                                    </Grid>
                                    {lockCreate && (
                                      <Grid item xs={8} md={3}>
                                        <label>Lock : No</label>
                                        <Switch
                                          checked={
                                            val.is_lock == "On" ? true : false
                                          }
                                          name="is_lock"
                                          value={val.is_lock}
                                          onChange={(e) =>
                                            handleJobChange(e, i)
                                          }
                                          inputProps={{
                                            "aria-label": "controlled",
                                          }}
                                        />
                                        <label>Yes</label>
                                      </Grid>
                                    )}

                                    <Grid item xs={4} md={2}>
                                      <IconButton className="mdsRemoveBtn">
                                        <DeleteForeverIcon
                                          variant="gradient"
                                          color="error"
                                          type="button"
                                          fontSize="large"
                                          onClick={() => handleJobDelete(i)}
                                        />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                )
                            )}
                          </Grid>
                        </MDBox>
                      </Box>
                    </DialogContent>
                  </MDBox>
                </Card>
                {/* End Jobs Management  */}
              </Grid>
            </Grid>

            <MDBox mt={2} display="flex" alignItems="center">
              <MDButton
                variant="gradient"
                color="primary"
                size="small"
                type="submit"
                className="saveButton"
              >
                Save
              </MDButton>
            </MDBox>
          </Form>
        </Formik>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DealData;
