import * as Yup from "yup";

export const createAccountSchema = Yup.object({
  debit_credit_type: Yup.mixed().required("Please select debit/credit"),
  remarks: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter remarks")
    .max(250),
  acc_date: Yup.date().required("Please provide date"),
  // end_date:Yup.date().required("Please provide end date"),
  currency_id: Yup.mixed().required("Please select currency"),
  acc_amount: Yup.number()
    .typeError("Please enter amount")
    .required("Please enter amount"),
  vendor_type: Yup.mixed().required("Please select vendor type"),
  vendor_id: Yup.mixed().required("Please select vendor"),
  payment_type_id: Yup.mixed().required("Please select payment type"),
  party_type: Yup.mixed().required("Please select party type"),
  party_id: Yup.mixed().required("Please select party"),
  payment_status: Yup.mixed().required("Please select payment status"),
});
