import { fetchData } from "utils/clientFunctions";

const getFetchData = async (settingUrl) => {
  return await fetchData(settingUrl);
};

export const liveSearchData = ({
  countryId = null,
  value,
  key,
  setRaList,
  setCompanyList,
  setApplicantList,
  setUserList,
}) => {
  let countryQuery = "";
  if (countryId != null && countryId > 0) {
    countryQuery = `&country_id=${countryId}`;
  }
  let url;
  if (value) {
    url = `${
      value === "ra" ? "recruiting_agency" : value
    }/search?q=${key}${countryQuery}`;
  } else {
    url = `company/search?q=${key}`; //Default search query
  }
  getFetchData(url)
    .then((response) => {
      const filteredData = [...response.data];
      if (value === "ra") {
        setRaList([...filteredData]);
      } else if (value === "company") {
        setCompanyList([...filteredData]);
      } else if (value === "applicant") {
        setApplicantList([...filteredData]);
      } else if (value === "user") {
        setUserList([...filteredData]);
      }
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};
