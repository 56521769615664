import * as Yup from "yup";
 
export const updateRaSchema = Yup.object({  
//   supply_country_id: Yup.object({
//     country_id: Yup.number().default(30).required(),
//     label: Yup.string().required(),
//     dial_code: Yup.string().required(),
//     code: Yup.string().required(),
//     currency_name: Yup.string().required(),
//   }), 
    ra_name: Yup.string().min(2, "Please enter minimum two characters").required("Please enter RA name").max(120),
    ra_code: Yup.string().min(2, "Please enter minimum two characters").required("Please enter RA code").max(120),
    // supply_country_id: Yup.mixed().required('Please select valid country'),
    // receiving_country_id: Yup.mixed().required('Please select valid country'),
    logo: Yup.mixed().required('Please upload a png/jpeg image'),
    licence_number: Yup.string().min(2, "Please enter minimum two characters").required("Please enter licence number").max(120),
    owner_partner_details: Yup.string().min(2, "Please enter minimum two characters").required("Please enter owner partner details").max(120),
    contact_number: Yup.number().typeError('Only numbers allowed').min(2, "Please enter minimum two characters").required("Please enter contact number"),
    landline_number: Yup.number().typeError('Only numbers allowed').min(2, "Please enter minimum two characters").required("Please enter landline number"),
    email_id: Yup.string().email("Please enter valid email id").required("Please enter email id"),
    number_of_working_staff: Yup.number().typeError('Only numbers allowed').integer().required("Please enter number of working staff"),
    address: Yup.string().min(2, "Please enter minimum two characters").required("Please enter address").max(250),
    landmark: Yup.string().min(2, "Please enter minimum two characters").required("Please enter landmark").max(250),
    postal_code: Yup.number().typeError('Postal code must be a number').integer("Please enter postal code").required("Please enter postal code"),
    city: Yup.string().min(2, "Please enter minimum two characters").required("Please enter city").max(250),
    state: Yup.string().min(2, "Please enter minimum two characters").required("Please enter state").max(250),
    // country_id: Yup.mixed().required('Please select valid country'),
    // contact_country_code_id: Yup.mixed().required('Please select valid code'),
    // landline_country_code_id: Yup.mixed().required('Please select valid code'),
    // other_email_ids: Yup.array().items(Yup.string().email()), 
    //industry_id: Yup.mixed().required('Please select valid industry'),
});