// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import { useAuth } from "utils/auth";
import { useEffect, useState } from "react";
import { fetchData, postData } from "utils/clientFunctions";
import Meetings from "./components/Meetings";
import Tasks from "./components/Tasks";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";

import { useSelector, useDispatch } from "react-redux";
import { getPermissions, selectPermissions } from "reducers/permissionSlice";

//import { PermissionContext } from "utils/PermissionContext";
function Dashboard() {
  //const permissions = useContext(PermissionContext);

  const dispatch = useDispatch();
  var permissions = useSelector(selectPermissions);

  //console.log("permissions", permissions);

  const [dashboardData, setDashboardData] = useState({});

  const [settingList, setSettingList] = useState([]);

  const { logout } = useAuth();
  const initialLoadData = async () => {
    const url = `setting/list?page=1`;
    const data = await fetchData(url);
    if (
      data?.name === "JsonWebTokenError" ||
      data?.name === "TokenExpiredError" ||
      data?.message === "You are not authorized"
    ) {
      logout();
    }
    if (data && data.data) {
      //console.log('data.product',data.product);
      setSettingList(data.data || []);
    }
  };

  const getFetchData = async (apiUrl) => {
    return await fetchData(apiUrl);
  };

  useEffect(() => {
    initialLoadData();

    dispatch(getPermissions());

    //Get dashboard data
    getFetchData("dashboard").then((res) => {
      setDashboardData(res.data);
    });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* <MDBox py={3} display="flex" justifyContent="center" >
        <MDTypography variant="h3" color="error">
          Welcome To AWR CRM
        </MDTypography>
      </MDBox> */}

      <MDBox py={3}>
        <Grid container spacing={3}>
          {(permissions?.permission?.includes("dshAppView") ||
            permissions?.user_id === 1) && (
            <>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <Link to="/applicant">
                    <ComplexStatisticsCard
                      color="success"
                      icon="people"
                      title="Applicants"
                      count={dashboardData?.applicantCount}
                      percentage={{
                        color: "success",
                        amount: "",
                        label: "Number of Applicants",
                      }}
                    />
                  </Link>
                </MDBox>
              </Grid>
            </>
          )}

          {(permissions?.permission?.includes("dshCmpView") ||
            permissions?.user_id === 1) && (
            <>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <Link to="/company_list">
                    <ComplexStatisticsCard
                      icon="business"
                      title="Company"
                      count={dashboardData?.companiesCount}
                      percentage={{
                        color: "success",
                        amount: "",
                        label: "Number of Companies",
                      }}
                    />
                  </Link>
                </MDBox>
              </Grid>
            </>
          )}

          {(permissions?.permission?.includes("dshRaView") ||
            permissions?.user_id === 1) && (
            <>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <Link to="/ra_list">
                    <ComplexStatisticsCard
                      color="dark"
                      icon="reduce_capacity"
                      title="Recruiting Agency"
                      count={dashboardData?.raCount}
                      percentage={{
                        color: "success",
                        amount: "",
                        label: "Number of Recruiting Agency",
                      }}
                    />
                  </Link>
                </MDBox>
              </Grid>
            </>
          )}

          {(permissions?.permission?.includes("dshAccView") ||
            permissions?.user_id === 1) && (
            <>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <Link to="/account">
                    <ComplexStatisticsCard
                      color="primary"
                      icon="account_balance_wallet"
                      title="Accounts"
                      count={dashboardData?.accountCount}
                      percentage={{
                        color: "success",
                        amount: "",
                        label: "Number of Accounts",
                      }}
                    />
                  </Link>
                </MDBox>
              </Grid>
            </>
          )}
        </Grid>

        <MDBox>
          <Grid container spacing={3}>
            {(permissions?.permission?.includes("dshUpMetView") ||
              permissions?.user_id === 1) && (
              <>
                <Grid item xs={12} md={6} lg={6}>
                  <Meetings meetingList={dashboardData?.upcomingMeetings} />
                </Grid>
              </>
            )}

            {(permissions?.permission?.includes("dshUpTskView") ||
              permissions?.user_id === 1) && (
              <>
                <Grid item xs={12} md={6} lg={6}>
                  <Tasks taskList={dashboardData?.upcomingTasks} />
                </Grid>
              </>
            )}
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
