// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { fetchData } from "utils/clientFunctions";
import useSWR from "swr";
import { useEffect, useState } from "react";
import { Token } from "utils/clientFunctions";
import MDButton from "components/MDButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  Tooltip,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { settingDefault } from "utils/clientFunctions";
import { ErrorMessage, Field, Form, Formik } from "formik";
import MDInput from "components/MDInput";
import * as Yup from "yup";
import { updateData } from "utils/clientFunctions";
import { useToast } from "UI/snakToast/ToastProvider";
import { postData } from "utils/clientFunctions";
import { useAuth } from "utils/auth";
import pxToRem from "assets/theme/functions/pxToRem";
import { useSelector, useDispatch } from "react-redux";
import { getPermissions, selectPermissions } from "reducers/permissionSlice";
const validationSchema = Yup.object({
  setting_value: Yup.string().required("Field is required"),
});

function Tables() {
  const { logout } = useAuth();
  const { showToast, handleCloseToast } = useToast();
  const [settingList, setSettingList] = useState([]);
  const [open, setOpen] = useState(false);
  const [openTitle, setOpenTitle] = useState("");

  const navigate = useNavigate();
  //Start : For permission
  const dispatch = useDispatch();
  var permissions = useSelector(selectPermissions);
  if (
    !(
      permissions?.user_id === 1 || permissions?.permission?.includes("setView")
    )
  ) {
    navigate("/dashboard");
  }
  let showAddButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("setCreate")
      ? true
      : false;
  let showEditButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("setEdit")
      ? true
      : false;
  let showDeleteButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("setDelete")
      ? true
      : false;
  useEffect(() => {
    dispatch(getPermissions());
  }, []);
  //End : For permission

  const handleOpen = (val) => {
    handleCloseToast("", "");
    setOpenTitle(val);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const initialLoadData = async () => {
    const url = `setting/list/1`;
    const data = await fetchData(url);
    if (
      data?.name === "JsonWebTokenError" ||
      data?.name === "TokenExpiredError" ||
      data?.message === "You are not authorized"
    ) {
      logout();
    }
    if (data && data.data) {
      //console.log('data.product',data.product);
      setSettingList(data.data || []);
    }
  };

  useEffect(() => {
    initialLoadData();
  }, []);

  const handleSubmit = async (val) => {
    const sData = {
      setting_type: val.setting_type,
      setting_value: val.setting_value,
    };
    const url = val.setting_id ? `setting/${val.setting_id}` : `setting`;
    const response = val.setting_id
      ? await updateData(url, sData)
      : await postData(url, sData);
    //console.log("error", response);
    if (response.message) {
      showToast(`${response.message}`, "success");
      initialLoadData();
      handleClose();
    }
  };

  const cardComponent = (props) => {
    const { title, setting_type, data } = props;
    const cardFilter = data.filter(
      (value) => value.setting_type === setting_type
    );
    return (
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-5}
                py={1}
                px={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {title}
                </MDTypography>
                {showAddButton && (
                  <MDButton
                    variant="outlined"
                    onClick={() => handleOpen(props)}
                    color="white"
                    size="small"
                  >
                    Add
                  </MDButton>
                )}
              </MDBox>
              <MDBox>
                <Grid container p={2} spacing={2}>
                  {cardFilter.map((item) => {
                    return (
                      <Grid item key={item.setting_id} xs={12} md={3}>
                        <MDBox
                          borderRadius="lg"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          p={1}
                          sx={{
                            border: ({
                              borders: { borderWidth, borderColor },
                            }) => `${borderWidth[1]} solid ${borderColor}`,
                          }}
                        >
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="regular"
                            color="black"
                          >
                            {item.setting_value}
                          </MDTypography>
                          {showEditButton && (
                            <MDBox
                              ml="auto"
                              lineHeight={0}
                              color={"dark"}
                              onClick={() => handleOpen(item)}
                            >
                              <Tooltip title="Edit" placement="top">
                                <Icon
                                  sx={{ cursor: "pointer" }}
                                  fontSize="small"
                                >
                                  edit
                                </Icon>
                              </Tooltip>
                            </MDBox>
                          )}
                        </MDBox>
                      </Grid>
                    );
                  })}
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    );
  };

  const CustomDialog = ({ open, openTitle, handleClose }) => {
    const { setting_type, setting_id, setting_value } = openTitle;
    const label =
      setting_type && settingDefault?.filter((d) => d.value === setting_type);
    return (
      <Dialog open={open} onClose={handleClose}>
        <MDBox
          variant="contained"
          bgColor="white"
          color="black"
          borderRadius="md"
          shadow="md"
        >
          <DialogTitle align="center">
            Add {label && label[0]?.label}
          </DialogTitle>
          <DialogContent>
            <MDBox pt={1} pb={1} px={3}>
              <Formik
                initialValues={{
                  setting_id: setting_id,
                  setting_type: setting_type,
                  setting_value: setting_value,
                }}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={(val) => handleSubmit(val)}
              >
                <Form>
                  <MDBox mb={2}>
                    <Field name="setting_value">
                      {({ field, form }) => (
                        <MDInput
                          {...field}
                          type="setting_value"
                          label="Name"
                          fullWidth
                          onBlur={field.onBlur}
                          onChange={field.onChange}
                          value={field.value}
                          error={
                            form.touched.setting_value &&
                            Boolean(form.errors.setting_value)
                          }
                          helperText={<ErrorMessage name="setting_value" />}
                        />
                      )}
                    </Field>
                  </MDBox>
                  <MDBox
                    mt={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <MDButton
                      variant="gradient"
                      color="info"
                      size="small"
                      type="submit"
                    >
                      Save
                    </MDButton>
                    <MDButton
                      onClick={handleClose}
                      variant="gradient"
                      size="small"
                      color="primary"
                    >
                      Close
                    </MDButton>
                  </MDBox>
                </Form>
              </Formik>
            </MDBox>
          </DialogContent>
        </MDBox>
      </Dialog>
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* {cardComponent({ title: "Countries", setting_type: 1, data: settingList })} */}
      {cardComponent({
        title: "Industries",
        setting_type: 2,
        data: settingList,
      })}
      {/* {cardComponent({ title: "Tax rate", setting_type: 3, data: settingList })} */}
      {cardComponent({
        title: "Industry Positions",
        setting_type: 4,
        data: settingList,
      })}
      {cardComponent({
        title: "Size of the Company",
        setting_type: 5,
        data: settingList,
      })}
      {cardComponent({ title: "Religion", setting_type: 6, data: settingList })}
      {/* {cardComponent({ title: "Lead sources", setting_type: 7, data: settingList })} */}
      {cardComponent({ title: "Statuses", setting_type: 8, data: settingList })}
      {cardComponent({
        title: "Task Reasons",
        setting_type: 9,
        data: settingList,
      })}
      {cardComponent({
        title: "Payment Types",
        setting_type: 10,
        data: settingList,
      })}
      {/* {cardComponent({ title: "Currency types", setting_type: 11, data: settingList })} */}
      {cardComponent({
        title: "Meeting Reasons",
        setting_type: 12,
        data: settingList,
      })}
      {cardComponent({
        title: "Call Report Status",
        setting_type: 13,
        data: settingList,
      })}
      {cardComponent({
        title: "Booking Types",
        setting_type: 14,
        data: settingList,
      })}
      {cardComponent({
        title: "Attestation Types",
        setting_type: 15,
        data: settingList,
      })}
      {cardComponent({
        title: "Deployment Types",
        setting_type: 16,
        data: settingList,
      })}
      {/* {cardComponent({
        title: "Interview Positions",
        setting_type: 17,
        data: settingList,
      })} */}
      {cardComponent({
        title: "Invoice Status",
        setting_type: 18,
        data: settingList,
      })}
      <CustomDialog
        open={open}
        openTitle={openTitle}
        handleClose={handleClose}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
