import * as Yup from "yup";

export const bookingSchema = Yup.object({
  booking_type: Yup.mixed().required("Please select valid status"),
  booking_date: Yup.date().required("Please provide booking date"),
  reason: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter reason")
    .max(250, "Max 250 characters"),
  person_name: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter person name")
    .max(120),
  contact_code_id: Yup.mixed().required("Please select contact code"),
  contact_number: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter contact number")
    .max(20),
  booking_place_name: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter booking place name")
    .max(120),
  contact_code_id: Yup.mixed().required("Please select contact code"),
  currency_id: Yup.mixed().required("Please select currency"),
  amount: Yup.number()
    .typeError("Enter valid amount")
    .required("Please provide offered salary"),
  remarks: Yup.string()
    .min(2, "Please enter minimum 2 characters")
    .required("Please enter remarks")
    .max(120, "Max 250 characters allowed"),
  status_id: Yup.mixed().required("Please select valid status"),
});
