import * as Yup from "yup";

export const updatePasswordScheme = Yup.object({
  old_password: Yup.string()
    .min(6, "Please enter minimum six characters")
    .required("Please enter current password")
    .max(30, "Max 50 characters"),
  password: Yup.string()
    .min(6, "Please enter minimum six characters")
    .required("Please enter new password")
    .max(30, "Max 50 characters"),
  confirm_password: Yup.string()
    .required("Please enter confirm password")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});
