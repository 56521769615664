import * as Yup from "yup";
export const CreateCompanySchema = Yup.object({
  country_id: Yup.mixed().required("Please select valid country"),
  logo: Yup.mixed().required("Please upload a png/jpeg image"),
  name: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter company name")
    .max(120),
  size_of_company: Yup.mixed().required("Please select size of company"),
  industry_id: Yup.mixed().required("Please select valid industry"),
  address: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter address")
    .max(250),
  // recruiter_details: Yup.string().min(2, "Please enter minimum two characters").required("Please enter recruiter details").max(250),
  // interview_details: Yup.string().min(2, "Please enter minimum two characters").required("Please enter interview details").max(250),
  // point_of_contact: Yup.string().min(2, "Please enter minimum two characters").required("Please enter point of contact").max(250),
  website: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter website url")
    .max(250, "Maximum of 250 characters allowed"),
  licence_number: Yup.string()
    .min(2, "Please enter minimum two characters")
    .max(120),
  landmark: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter landmark")
    .max(250),
  postal_code: Yup.number()
    .typeError("Postal code must be a number")
    .integer("Please enter postal code")
    .required("Please enter postal code"),
  city: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter city")
    .max(250),
  state: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter state")
    .max(250),
  country_id: Yup.mixed().required("Please select valid country"),
  landline_country_code_id: Yup.mixed().required("Please select valid code"),
  landline_number: Yup.number()
    .typeError("Only numbers allowed")
    .min(2, "Please enter minimum two characters")
    .required("Please enter landline number"),
  firm_type: Yup.mixed().required("Please select valid firm type"),
  guarantee_period: Yup.number()
    .typeError("Enter valid guarantee period")
    .required("Please provide guarantee period"),
});
