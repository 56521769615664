// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
//import useSWR from "swr";
import { useEffect, useState } from "react";
//import { Token } from "utils/clientFunctions";
import MDButton from "components/MDButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  Tooltip,
} from "@mui/material";
//import { settingDefault } from "utils/clientFunctions";
import { ErrorMessage, Field, Form, Formik } from "formik";
import MDInput from "components/MDInput";
import * as Yup from "yup";
import { updateData } from "utils/clientFunctions";

import { fetchData } from "utils/clientFunctions";
import useSWR from "swr";

import { postData, doUpload } from "utils/clientFunctions";
import { ToastProvider, useToasts } from "react-toast-notifications";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import MDAvatar from "components/MDAvatar";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "../../../../src/assets/css/Custom.css";
import { useFormik } from "formik";

import { createRaSchema } from "../components/schemas";
import { CardTopBoxBack } from "layouts/commonComponents/cardTopBoxBack";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getPermissions, selectPermissions } from "reducers/permissionSlice";
function CreateRa() {
  const { raId } = useParams();

  const url = `setting/list/1`;
  const { data, error, isLoading } = useSWR(url, fetchData);
  const [industries, setIndustries] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [companyPositionList, setCompanyPositionList] = useState([]);

  const [ownerPartners, setOwnerPartners] = useState([]);
  const [teams, setTeams] = useState([]);
  const [documents, setDocuments] = useState([]);

  const { addToast } = useToasts();
  const navigate = useNavigate();

  //Start : For permission
  const dispatch = useDispatch();
  const permissions = useSelector(selectPermissions);
  if (raId) {
    if (
      !(
        permissions?.user_id === 1 ||
        permissions?.permission?.includes("raEdit")
      )
    ) {
      navigate("/ra_list");
    }
  } else {
    if (
      !(
        permissions?.user_id === 1 ||
        permissions?.permission?.includes("raCreate")
      )
    ) {
      navigate("/ra_list");
    }
  }
  useEffect(() => {
    dispatch(getPermissions());
  }, []);
  //End : For permission
  let lockCreate =
    permissions?.user_id === 1 ||
    permissions?.permission?.includes("lockCreate");
  const [formData, setFormData] = useState({
    ra_name: "",
    ra_code: "",
    supply_country_id: "",
    //receiving_country_id: "",
    logo: "",
    licence_number: "",
    //owner_partner_details: "",
    //contact_number: "",
    landline_number: "",
    //email_id: "",
    number_of_working_staff: "",
    industry_id: "",
    address: "",
    landmark: "",
    postal_code: "",
    city: "",
    state: "",
    country_id: "",
    //contact_country_code_id: "",
    landline_country_code_id: "",
    //other_email_ids : "",
    firm_type: "",
  });

  const getFetchData = async (settingUrl) => {
    return await fetchData(settingUrl);
  };

  //Get data
  useEffect(() => {
    if (raId) {
      const getRaUrl = `recruiting_agency/${raId}`;
      getFetchData(getRaUrl).then((res) => {
        if (res.data) {
          let {
            ra_code,
            ra_name,
            logo,
            licence_number,
            number_of_working_staff,
            firm_type,
            address,
            landmark,
            postal_code,
            city,
            state,
            landline_number,
            industry_info,
            supply_country,
            country_info,
            landline_number_code_info,
            owner_partner_list,
            ra_team_list,
            document_list,
          } = res.data;

          let updateFirmType = { label: firm_type, value: firm_type };
          setFormData({
            ra_name,
            ra_code,
            supply_country_id: supply_country,
            //receiving_country_id: "",
            logo,
            licence_number,
            //owner_partner_details: "",
            //contact_number: "",
            landline_number,
            //email_id: "",
            number_of_working_staff,
            industry_id: industry_info,
            address,
            landmark,
            postal_code,
            city,
            state,
            country_id: country_info,
            //contact_country_code_id: "",
            landline_country_code_id: landline_number_code_info,
            //other_email_ids : "",
            firm_type: updateFirmType,
          });

          let ownerPartnerList = [];
          owner_partner_list?.map((partner) => {
            const {
              name,
              mobile_number,
              email_id,
              is_lock,
              position_info,
              mobile_code_info,
              profile_pic,
              created_by_user_id,
            } = partner;
            ownerPartnerList.push({
              position_id: position_info,
              name,
              mobile_code_id: mobile_code_info,
              mobile_number,
              email_id,
              created_by_user_id,
              profile_pic,
              is_lock: is_lock == 1 ? "On" : "Off",
            });
          });
          setOwnerPartners(ownerPartnerList);

          let ownerTeamList = [];
          ra_team_list?.map((team) => {
            const {
              name,
              mobile_number,
              email_id,
              is_lock,
              position_info,
              mobile_code_info,
              nationality_info,
              profile_pic,
              created_by_user_id,
            } = team;
            ownerTeamList.push({
              position_id: position_info,
              name,
              mobile_code_id: mobile_code_info,
              mobile_number,
              nationality_id: nationality_info,
              email_id,
              created_by_user_id,
              profile_pic,
              is_lock: is_lock == 1 ? "On" : "Off",
            });
          });
          setTeams(ownerTeamList);

          let documentList = [];
          document_list?.map((document) => {
            const {
              document_name,
              issue_date,
              expiry_date,
              upload_document,
              issue_country_info,
            } = document;
            documentList.push({
              name: document_name,
              issue_country_id: issue_country_info,
              issue_date,
              expiry_date,
              document: upload_document,
            });
          });
          setDocuments(documentList);
        }
      });
    }

    const countryUrl = `country/list`;
    getFetchData(countryUrl).then((res) => {
      let countryList = res.data;
      setCountryList(countryList);
    });

    //Get position list
    const positionUrl = `setting/type/4`;
    getFetchData(positionUrl).then((res) => {
      let positionList = res.data;
      setCompanyPositionList(positionList);
    });

    //Get industry list
    const industryUrl = `setting/type/2`;
    getFetchData(industryUrl).then((res) => {
      setIndustries(res.data);
    });
  }, []);

  const handleFormSubmit = async (data, resetForm) => {
    //Collect final Data
    let submitData = {};
    submitData = data;

    //Update below fields to send only ids
    submitData.supply_country_id = data.supply_country_id?.country_id;
    //submitData.receiving_country_id = data.receiving_country_id?.country_id;
    //submitData.contact_country_code_id = data.contact_country_code_id?.country_id;
    submitData.country_id = data.country_id?.country_id;
    submitData.landline_country_code_id =
      data.landline_country_code_id?.country_id;
    submitData.industry_id = data.industry_id?.setting_id;
    submitData.firm_type = data.firm_type?.value;

    //Convert teams into array
    let allDocuments = [];
    if (documents.length) {
      documents?.map((doc) => {
        const { name, issue_country_id, issue_date, expiry_date, document } =
          doc;
        if (document) {
          allDocuments.push({
            document_name: name,
            issue_country_id: issue_country_id?.country_id,
            issue_date: issue_date ? issue_date : null,
            expiry_date: expiry_date ? expiry_date : null,
            upload_document: document,
          });
        }
      });
    }
    submitData.documents = allDocuments;

    //Convert teams into array
    let allTeams = [];
    if (teams.length) {
      teams?.map((team) => {
        const {
          name,
          position_id,
          mobile_code_id,
          mobile_number,
          email_id,
          nationality_id,
          is_lock,
          profile_pic,
          created_by_user_id,
        } = team;
        if (position_id && mobile_code_id && nationality_id) {
          allTeams.push({
            position_id: position_id?.setting_id,
            email_id,
            name,
            mobile_code_id: mobile_code_id?.country_id,
            mobile_number,
            nationality_id: nationality_id?.country_id,
            created_by_user_id: created_by_user_id || 0,
            profile_pic,
            is_lock: is_lock == "On" ? 1 : 0,
          });
        }
      });
    }
    submitData.teams = allTeams;

    //Convert OwnerPartners into array
    let allOwnerPartners = [];
    if (ownerPartners.length) {
      ownerPartners?.map((ownerPartner) => {
        const {
          name,
          position_id,
          mobile_code_id,
          mobile_number,
          email_id,
          is_lock,
          profile_pic,
          created_by_user_id,
        } = ownerPartner;
        if (position_id && mobile_code_id) {
          allOwnerPartners.push({
            position_id: position_id?.setting_id,
            email_id,
            name,
            mobile_code_id: mobile_code_id?.country_id,
            mobile_number,
            created_by_user_id: created_by_user_id || 0,
            profile_pic,
            is_lock: is_lock == "On" ? 1 : 0,
          });
        }
      });
    }
    submitData.owner_partners = allOwnerPartners;

    const raUrl = `recruiting_agency`;
    const response = raId
      ? await updateData(`${raUrl}/${raId}`, submitData)
      : await postData(raUrl, submitData);

    if (!response.status) {
      if (response.message) {
        addToast(response.message, { appearance: "error", autoDismiss: true });
      }
      if (response.errors) {
        let apiErrors = response.errors;
        for (let i = 0; i < apiErrors.length; i++) {
          let errorMessage = apiErrors[i].message;
          errorMessage = errorMessage.replace(/['"]+/g, "").replace(/_/g, " ");
          errorMessage =
            errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
          addToast(errorMessage, { appearance: "error", autoDismiss: true });
        }
      }
    } else {
      addToast("Saved Successfully", {
        appearance: "success",
        autoDismiss: true,
      });
      navigate(-1);
    }
  };

  const handleLogoChange = async (event) => {
    let name = event.target.name;
    let val = event.target.files[0];
    let uploadFile = { logo: val };
    const url = `recruiting_agency/upload_file`;
    const response = await doUpload(url, uploadFile);
    if (response.status && response.uploadedFile) {
      let logoPath = response.uploadedFile.path;
      //setFormData({ ...formData, [name]: logoPath });
      formData.logo = logoPath;
      setFieldValue("logo", logoPath);
    } else {
      formData.logo = "";
      setFieldValue("logo", "");
      setFieldError("logo", response.message);
    }
  };

  const onChange = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    setFormData({ ...formData, [name]: val });
  };
  // START Manage Team
  const addMoreTeam = () => {
    setTeams([...teams, {}]);
  };
  const handleTeamChange = async (e, i) => {
    let { name, value } = e.target;
    if (name === "is_lock") {
      value = value == "On" ? "Off" : "On";
    }
    if (name == "profile_pic") {
      let val = e.target.files[0];
      let uploadFile = { file: val };
      const url = `upload_any/do_upload`;
      const response = await doUpload(url, uploadFile);
      if (response.status && response.uploadedFile) {
        let filePath = response.uploadedFile.path;
        value = filePath;
      } else {
        value = "";
      }
    }
    const onchangeVal = [...teams];
    onchangeVal[i][name] = value;
    setTeams(onchangeVal);
  };
  const handleTeamDelete = (i) => {
    const deleteVal = [...teams];
    deleteVal.splice(i, 1);
    setTeams(deleteVal);
  };
  // END Manage Team

  // START Owner Partner
  const addMoreOwnerPartner = () => {
    setOwnerPartners([...ownerPartners, {}]);
  };
  const handleOwnerPartnerChange = async (e, i) => {
    let { name, value } = e.target;
    if (name === "is_lock") {
      value = value == "On" ? "Off" : "On";
    }
    if (name == "profile_pic") {
      let val = e.target.files[0];
      let uploadFile = { file: val };
      const url = `upload_any/do_upload`;
      const response = await doUpload(url, uploadFile);
      if (response.status && response.uploadedFile) {
        let filePath = response.uploadedFile.path;
        value = filePath;
      } else {
        value = "";
      }
    }
    const onchangeVal = [...ownerPartners];
    onchangeVal[i][name] = value;
    setOwnerPartners(onchangeVal);
  };
  const handleOwnerPartnerDelete = (i) => {
    const deleteVal = [...ownerPartners];
    deleteVal.splice(i, 1);
    setOwnerPartners(deleteVal);
  };
  // END Owner Partner

  // START Manage Document
  const addMoreDocument = () => {
    setDocuments([...documents, {}]);
  };
  const handleDocumentChange = async (e, i) => {
    const { name, value } = e.target;
    const onchangeVal = [...documents];
    if (name == "document") {
      let val = e.target.files[0];
      let uploadFile = { file: val };
      const url = `upload_any/do_upload`;
      const response = await doUpload(url, uploadFile);
      if (response.status && response.uploadedFile) {
        let filePath = response.uploadedFile.path;
        onchangeVal[i][name] = filePath;
      } else {
        onchangeVal[i][name] = "";
      }
    } else {
      onchangeVal[i][name] = value;
    }
    setDocuments(onchangeVal);
  };
  const handleDocumentDelete = (i) => {
    const deleteVal = [...documents];
    deleteVal.splice(i, 1);
    setDocuments(deleteVal);
  };
  // END Manage Document

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: formData,
    enableReinitialize: true,
    validationSchema: createRaSchema,
    onSubmit: (values, action) => {
      handleFormSubmit(values, action);
    },
  });

  const commaonError = (fieldName) => {
    return errors?.[fieldName] && touched?.[fieldName] ? (
      <p className="formError">{errors[fieldName]}</p>
    ) : null;
  };

  const firmTypeData = [
    { label: "Propreitorship", value: "Propreitorship" },
    { label: "Partnership", value: "Partnership" },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Formik
          initialValues={formData}
          enableReinitialze={true}
          //validationSchema={RaSchema}
          onSubmit={handleSubmit}
          // onSubmit={(val, { resetForm }) =>
          //   handleFormSubmit(val, resetForm)
          // }
        >
          <Form>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-5}
                    py={1}
                    borderRadius="lg"
                    px={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    variant="gradient"
                    bgColor="info"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      {`${raId ? "Update" : "New "}`} Recruiting Agency
                    </MDTypography>
                    <MDButton
                      variant="outlined"
                      onClick={() => navigate(-1)}
                      color="white"
                      size="small"
                    >
                      Back
                    </MDButton>
                  </MDBox>

                  <MDBox
                    variant="contained"
                    bgColor="white"
                    color="black"
                    borderRadius="md"
                    shadow="md"
                  >
                    <DialogContent>
                      <Box sx={{ flexGrow: 1 }}>
                        <MDBox pt={1} pb={1}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.supply_country_id}
                                  size="small"
                                  name="supply_country_id"
                                  onChange={(event, newValue) => {
                                    if (newValue) {
                                      setFieldValue(
                                        "supply_country_id",
                                        newValue
                                      );
                                    } else {
                                      formData.supply_country_id = "";
                                      setFieldValue("supply_country_id", "");
                                    }
                                  }}
                                  options={countryList}
                                  getOptionLabel={(countryList) =>
                                    countryList.name || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      name="supply_country_id"
                                      {...params}
                                      label="Supply Country"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {errors.supply_country_id &&
                                touched.supply_country_id ? (
                                  <p className="formError">
                                    {errors.supply_country_id}
                                  </p>
                                ) : null}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  type="text"
                                  name="ra_code"
                                  label="RA Code"
                                  fullWidth
                                  value={values.ra_code}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("ra_code")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  type="text"
                                  name="ra_name"
                                  label="RA Name"
                                  fullWidth
                                  value={values.ra_name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("ra_name")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.industry_id}
                                  size="small"
                                  name="industry_id"
                                  onChange={(event, newValue) => {
                                    if (newValue) {
                                      setFieldValue("industry_id", newValue);
                                    } else {
                                      formData.industry_id = "";
                                      setFieldValue("industry_id", "");
                                      setFieldError(
                                        "industry_id",
                                        "Please select valid industry"
                                      );
                                    }
                                  }}
                                  options={industries}
                                  getOptionLabel={(industries) =>
                                    industries.setting_value || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Industry Specialised"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {errors.industry_id && touched.industry_id ? (
                                  <p className="formError">
                                    {errors.industry_id}
                                  </p>
                                ) : null}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  margin: "0px 2px",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  component="label"
                                  fullWidth
                                  color="success"
                                >
                                  Upload Logo
                                  <input
                                    type="file"
                                    name="logo"
                                    accept="image/png, image/jpeg"
                                    hidden
                                    onChange={(e) => handleLogoChange(e)}
                                  />
                                </Button>
                                {formData.logo && (
                                  <MDAvatar
                                    src={
                                      process.env.REACT_APP_URI_PATH +
                                      formData.logo
                                    }
                                    name={formData.ra_name}
                                    size="md"
                                    sx={{
                                      width: 56,
                                      height: 56,
                                      marginLeft: "10px",
                                    }}
                                    variant="rounded"
                                  />
                                )}
                              </MDBox>
                              {errors.logo && touched.logo ? (
                                <p className="formError">{errors.logo}</p>
                              ) : null}
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <Field name="licence_number">
                                  {({ field, form }) => (
                                    <MDInput
                                      {...field}
                                      type="text"
                                      label="Licence Number"
                                      fullWidth
                                      value={values.licence_number}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  )}
                                </Field>
                                {errors.licence_number &&
                                touched.licence_number ? (
                                  <p className="formError">
                                    {errors.licence_number}
                                  </p>
                                ) : null}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <Field name="number_of_working_staff">
                                  {({ field, form }) => (
                                    <MDInput
                                      {...field}
                                      type="text"
                                      label="Number of Working Staff"
                                      fullWidth
                                      value={values.number_of_working_staff}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  )}
                                </Field>
                                {errors.number_of_working_staff &&
                                touched.number_of_working_staff ? (
                                  <p className="formError">
                                    {errors.number_of_working_staff}
                                  </p>
                                ) : null}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.firm_type}
                                  size="small"
                                  name="firm_type"
                                  onChange={(event, newValue) => {
                                    if (newValue) {
                                      setFieldValue("firm_type", newValue);
                                    } else {
                                      setFieldValue("firm_type", "");
                                      setFieldError(
                                        "firm_type",
                                        "Please select valid firm type"
                                      );
                                    }
                                  }}
                                  options={firmTypeData}
                                  getOptionLabel={(firmTypeData) =>
                                    firmTypeData.label || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Firm type"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {commaonError("firm_type")}
                              </MDBox>
                            </Grid>

                            {/* Address */}
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <Field name="address">
                                  {({ field, form }) => (
                                    <MDInput
                                      {...field}
                                      type="text"
                                      label="Address"
                                      fullWidth
                                      value={values.address}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  )}
                                </Field>
                                {errors.address && touched.address ? (
                                  <p className="formError">{errors.address}</p>
                                ) : null}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <Field name="landmark">
                                  {({ field, form }) => (
                                    <MDInput
                                      {...field}
                                      type="text"
                                      label="Landmark"
                                      fullWidth
                                      value={values.landmark}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  )}
                                </Field>
                                {errors.landmark && touched.landmark ? (
                                  <p className="formError">{errors.landmark}</p>
                                ) : null}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <Field name="postal_code">
                                  {({ field, form }) => (
                                    <MDInput
                                      {...field}
                                      type="text"
                                      label="Postal Code"
                                      fullWidth
                                      value={values.postal_code}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  )}
                                </Field>
                                {errors.postal_code && touched.postal_code ? (
                                  <p className="formError">
                                    {errors.postal_code}
                                  </p>
                                ) : null}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <Field name="city">
                                  {({ field, form }) => (
                                    <MDInput
                                      {...field}
                                      type="text"
                                      label="City"
                                      fullWidth
                                      value={values.city}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  )}
                                </Field>
                                {errors.city && touched.city ? (
                                  <p className="formError">{errors.city}</p>
                                ) : null}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <Field name="state">
                                  {({ field, form }) => (
                                    <MDInput
                                      {...field}
                                      type="text"
                                      label="State"
                                      fullWidth
                                      value={values.state}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  )}
                                </Field>
                                {errors.state && touched.state ? (
                                  <p className="formError">{errors.state}</p>
                                ) : null}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.country_id}
                                  size="small"
                                  name="country_id"
                                  onChange={(event, newValue) => {
                                    if (newValue) {
                                      setFieldValue("country_id", newValue);
                                    } else {
                                      formData.country_id = "";
                                      setFieldValue("country_id", "");
                                      setFieldError(
                                        "country_id",
                                        "Please select valid country"
                                      );
                                    }
                                  }}
                                  options={countryList}
                                  getOptionLabel={(countryList) =>
                                    countryList.name || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Country"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {errors.country_id && touched.country_id ? (
                                  <p className="formError">
                                    {errors.country_id}
                                  </p>
                                ) : null}
                              </MDBox>
                            </Grid>
                            <Grid item container xs={12} md={3} spacing={0}>
                              <Grid item xs={6} md={6}>
                                <MDBox className="mdsAutocompleteGrid">
                                  <Autocomplete
                                    value={values.landline_country_code_id}
                                    size="small"
                                    name="landline_country_code_id"
                                    onChange={(event, newValue) => {
                                      if (newValue) {
                                        setFieldValue(
                                          "landline_country_code_id",
                                          newValue
                                        );
                                      } else {
                                        formData.landline_country_code_id = "";
                                        setFieldValue(
                                          "landline_country_code_id",
                                          ""
                                        );
                                        setFieldError(
                                          "landline_country_code_id",
                                          "Please select valid code"
                                        );
                                      }
                                    }}
                                    options={countryList}
                                    getOptionLabel={(countryList) => {
                                      if (
                                        countryList.dial_code &&
                                        countryList.name
                                      ) {
                                        return (
                                          countryList.dial_code +
                                          " " +
                                          countryList.name
                                        );
                                      }
                                      return "";
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Country Code"
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    )}
                                  />
                                  {errors.landline_country_code_id &&
                                  touched.landline_country_code_id ? (
                                    <p className="formError">
                                      {errors.landline_country_code_id}
                                    </p>
                                  ) : null}
                                </MDBox>
                              </Grid>
                              <Grid item xs={6} md={6}>
                                <MDBox mb={2}>
                                  <Field name="landline_number">
                                    {({ field, form }) => (
                                      <MDInput
                                        {...field}
                                        type="text"
                                        label="Landline Number"
                                        fullWidth
                                        value={values.landline_number}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    )}
                                  </Field>
                                  {errors.landline_number &&
                                  touched.landline_number ? (
                                    <p className="formError">
                                      {errors.landline_number}
                                    </p>
                                  ) : null}
                                </MDBox>
                              </Grid>
                            </Grid>

                            {/* <Grid item xs={12} md={4}>
                        <MDBox mb={2}>
                          <Field name="owner_partner_details">
                            {({ field, form }) => (
                              <MDInput
                                {...field}
                                type="text"
                                label="Owner Partner Details"
                                fullWidth
                                value={values.owner_partner_details}
                                onChange={handleChange}
                                onBlur={handleBlur} 
                              />
                            )}
                          </Field>
                          {errors.owner_partner_details && touched.owner_partner_details ? (<p className="formError">{errors.owner_partner_details}</p>) : null}
                        </MDBox>
                      </Grid>
 
                      <Grid item container xs={12} md={4} spacing={0}> 
                        <Grid item xs={6} md={6}> 
                          <MDBox className="mdsAutocompleteGrid">       
                          <Autocomplete 
                            value={values.contact_country_code_id}
                            size="small"
                            name="contact_country_code_id"
                            onChange={(event, newValue) => {   
                              if(newValue){
                                setFieldValue('contact_country_code_id', newValue);
                              }else{
                                formData.contact_country_code_id = "";
                                setFieldValue('contact_country_code_id', '');
                                setFieldError('contact_country_code_id', "Please select valid code") 
                              }
                            }}   
                            options={countryList} 
                            getOptionLabel={(countryList) => {  
                                if(countryList.dial_code && countryList.label){
                                    return countryList.dial_code+' '+countryList.label
                                }
                                return "";
                              }
                            }
                            renderInput={(params) => <TextField   {...params} label="Country Code" InputLabelProps={{ shrink: true }} />}
                          />
                          {errors.contact_country_code_id && touched.contact_country_code_id ? (<p className="formError">{errors.contact_country_code_id}</p>) : null}
                          </MDBox>
                        </Grid> 
                        <Grid item xs={6} md={6}>
                          <MDBox mb={2}>
                            <Field name="contact_number">
                              {({ field, form }) => (
                                <MDInput
                                  {...field}
                                  type="text"
                                  label="Contact Number"
                                  fullWidth
                                  value={values.contact_number}
                                  onChange={handleChange}
                                  onBlur={handleBlur} 
                                />
                              )}
                            </Field>
                            {errors.contact_number && touched.contact_number ? (<p className="formError">{errors.contact_number}</p>) : null}
                          </MDBox>
                        </Grid> 
                      </Grid> 

                      <Grid item xs={12} md={4}>
                        <MDBox mb={2}>
                          <Field name="email_id">
                            {({ field, form }) => (
                              <MDInput
                                {...field}
                                type="text"
                                label="Email Id"
                                fullWidth
                                value={values.email_id}
                                onChange={handleChange}
                                onBlur={handleBlur} 
                              />
                            )}
                          </Field>
                          {errors.email_id && touched.email_id ? (<p className="formError">{errors.email_id}</p>) : null}
                        </MDBox>
                      </Grid> */}
                          </Grid>
                        </MDBox>
                      </Box>
                    </DialogContent>
                  </MDBox>
                </Card>

                {/* Start Owner/Partner Management  */}
                <Card className="mt4rem">
                  <MDBox
                    mx={2}
                    mt={-5}
                    py={1}
                    borderRadius="lg"
                    px={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    variant="gradient"
                    bgColor="info"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Owner/Partner
                    </MDTypography>
                    <MDButton
                      variant="outlined"
                      onClick={() => addMoreOwnerPartner()}
                      color="white"
                      size="small"
                    >
                      Add New
                    </MDButton>
                  </MDBox>

                  <MDBox
                    variant="contained"
                    bgColor="white"
                    color="black"
                    borderRadius="md"
                    shadow="md"
                  >
                    <DialogContent pt={1} pb={1}>
                      <Box sx={{ flexGrow: 1 }}>
                        <MDBox pt={1} pb={1}>
                          <Grid container spacing={2}>
                            {/* Start owner Partners Management */}
                            {ownerPartners.map(
                              (val, i) =>
                                (permissions?.user_id === 1 ||
                                  permissions?.user_id ===
                                    val.created_by_user_id ||
                                  val.is_lock !== "On") && (
                                  <Grid
                                    item
                                    className="borderBottom"
                                    style={{
                                      borderBottom: "1px solid #33333338",
                                    }}
                                    container
                                    xs={12}
                                    md={12}
                                    key={i}
                                    spacing={2}
                                  >
                                    <Grid item xs={12} md={3}>
                                      <MDBox mb={2}>
                                        <Field>
                                          {({ field, form }) => (
                                            <MDInput
                                              {...field}
                                              name="name"
                                              type="text"
                                              required
                                              fullWidth
                                              value={val.name}
                                              onChange={(e) =>
                                                handleOwnerPartnerChange(e, i)
                                              }
                                              label={`Name`}
                                              onBlur={field.onBlur}
                                            />
                                          )}
                                        </Field>
                                      </MDBox>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                      <MDBox className="mdsAutocompleteGrid">
                                        <Autocomplete
                                          size="small"
                                          name="position_id"
                                          value={val.position_id}
                                          onChange={(e, newValue) => {
                                            const ownerPartnerId = [
                                              ...ownerPartners,
                                            ];
                                            if (newValue) {
                                              ownerPartnerId[i]["position_id"] =
                                                newValue;
                                              setOwnerPartners(ownerPartnerId);
                                            } else {
                                              ownerPartnerId[i]["position_id"] =
                                                null;
                                              setOwnerPartners(ownerPartnerId);
                                            }
                                          }}
                                          options={companyPositionList}
                                          getOptionLabel={(
                                            companyPositionList
                                          ) =>
                                            companyPositionList.setting_value ||
                                            ""
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label="Position"
                                              InputLabelProps={{ shrink: true }}
                                            />
                                          )}
                                        />
                                        {commaonError("position_id")}
                                      </MDBox>
                                    </Grid>

                                    <Grid
                                      item
                                      container
                                      xs={12}
                                      md={3}
                                      spacing={0}
                                    >
                                      <Grid item xs={6} md={5}>
                                        <MDBox className="mdsAutocompleteGrid">
                                          <Autocomplete
                                            size="small"
                                            name="mobile_code_id"
                                            value={val.mobile_code_id}
                                            onChange={(e, newValue) => {
                                              const mobileCodeId = [
                                                ...ownerPartners,
                                              ];
                                              if (newValue) {
                                                mobileCodeId[i][
                                                  "mobile_code_id"
                                                ] = newValue;
                                                setOwnerPartners(mobileCodeId);
                                              } else {
                                                mobileCodeId[i][
                                                  "mobile_code_id"
                                                ] = null;
                                                setOwnerPartners(mobileCodeId);
                                              }
                                            }}
                                            options={countryList}
                                            getOptionLabel={(countryList) => {
                                              if (
                                                countryList.dial_code &&
                                                countryList.name
                                              ) {
                                                return (
                                                  countryList.dial_code +
                                                  " " +
                                                  countryList.name
                                                );
                                              }
                                              return "";
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label="Country Code"
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                              />
                                            )}
                                          />
                                          {commaonError("mobile_code_id")}
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={6} md={7}>
                                        <MDBox mb={2}>
                                          <Field name="mobile_number">
                                            {({ field, form }) => (
                                              <MDInput
                                                {...field}
                                                type="text"
                                                required
                                                label="Mobile Number"
                                                fullWidth
                                                value={val.mobile_number}
                                                onChange={(e) =>
                                                  handleOwnerPartnerChange(e, i)
                                                }
                                                onBlur={handleBlur}
                                              />
                                            )}
                                          </Field>
                                          {commaonError("mobile_number")}
                                        </MDBox>
                                      </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                      <MDBox mb={2}>
                                        <Field>
                                          {({ field, form }) => (
                                            <MDInput
                                              {...field}
                                              name="email_id"
                                              type="email"
                                              required
                                              fullWidth
                                              value={val.email_id}
                                              onChange={(e) =>
                                                handleOwnerPartnerChange(e, i)
                                              }
                                              label={`E-mail Id`}
                                              onBlur={field.onBlur}
                                            />
                                          )}
                                        </Field>
                                      </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                      <MDBox
                                        mb={2}
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          component="label"
                                          fullWidth
                                          color="success"
                                        >
                                          Profile Pic
                                          <input
                                            type="file"
                                            name="profile_pic"
                                            accept="image/png, image/jpeg"
                                            hidden
                                            onChange={(e) =>
                                              handleOwnerPartnerChange(e, i)
                                            }
                                          />
                                        </Button>
                                        {val.profile_pic && (
                                          <>
                                            <Link
                                              to={`${process.env.REACT_APP_URI_PATH}${val.profile_pic}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="mdsViewFile"
                                            >
                                              <MDButton
                                                variant="gradient"
                                                color="primary"
                                                size="small"
                                                type="button"
                                              >
                                                View Pic
                                              </MDButton>
                                            </Link>
                                          </>
                                        )}
                                      </MDBox>
                                    </Grid>
                                    {lockCreate && (
                                      <Grid item xs={8} md={3}>
                                        <label>Lock : No</label>
                                        <Switch
                                          checked={
                                            val.is_lock == "On" ? true : false
                                          }
                                          name="is_lock"
                                          value={val.is_lock}
                                          onChange={(e) =>
                                            handleOwnerPartnerChange(e, i)
                                          }
                                          inputProps={{
                                            "aria-label": "controlled",
                                          }}
                                        />
                                        <label>Yes</label>
                                      </Grid>
                                    )}

                                    <Grid item xs={4} md={2}>
                                      <IconButton className="mdsRemoveBtn">
                                        <DeleteForeverIcon
                                          variant="gradient"
                                          color="error"
                                          type="button"
                                          fontSize="large"
                                          onClick={() =>
                                            handleOwnerPartnerDelete(i)
                                          }
                                        />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                )
                            )}
                            {/* End owner Partners Management */}
                          </Grid>
                        </MDBox>
                      </Box>
                    </DialogContent>
                  </MDBox>
                </Card>
                {/* End Owner/Partner Management  */}

                {/* Start Team Management */}
                <Card className="mt4rem">
                  <MDBox
                    mx={2}
                    mt={-5}
                    py={1}
                    borderRadius="lg"
                    px={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    variant="gradient"
                    bgColor="info"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Team Management
                    </MDTypography>
                    <MDButton
                      variant="outlined"
                      onClick={() => addMoreTeam()}
                      color="white"
                      size="small"
                    >
                      Add Team
                    </MDButton>
                  </MDBox>

                  <MDBox
                    variant="contained"
                    bgColor="white"
                    color="black"
                    borderRadius="md"
                    shadow="md"
                  >
                    <DialogContent>
                      <Box sx={{ flexGrow: 1 }}>
                        <MDBox pt={1} pb={1}>
                          <Grid container spacing={2}>
                            {/* Start Team Management */}
                            {teams.map(
                              (val, i) =>
                                (permissions?.user_id === 1 ||
                                  permissions?.user_id ===
                                    val.created_by_user_id ||
                                  val.is_lock !== "On") && (
                                  <Grid
                                    item
                                    className="borderBottom"
                                    style={{
                                      borderBottom: "1px solid #33333338",
                                    }}
                                    container
                                    xs={12}
                                    md={12}
                                    key={i}
                                    spacing={2}
                                  >
                                    <Grid item xs={12} md={3}>
                                      <MDBox className="mdsAutocompleteGrid">
                                        <Autocomplete
                                          size="small"
                                          name="position_id"
                                          value={val.position_id}
                                          onChange={(e, newValue) => {
                                            const teamId = [...teams];
                                            if (newValue) {
                                              teamId[i]["position_id"] =
                                                newValue;
                                              setTeams(teamId);
                                            } else {
                                              teamId[i]["position_id"] = null;
                                              setTeams(teamId);
                                            }
                                          }}
                                          options={companyPositionList}
                                          getOptionLabel={(
                                            companyPositionList
                                          ) =>
                                            companyPositionList.setting_value ||
                                            ""
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label="Position"
                                              InputLabelProps={{ shrink: true }}
                                            />
                                          )}
                                        />
                                        {commaonError("position_id")}
                                      </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                      <MDBox mb={2}>
                                        <Field>
                                          {({ field, form }) => (
                                            <MDInput
                                              {...field}
                                              name="name"
                                              type="text"
                                              required
                                              fullWidth
                                              value={val.name}
                                              onChange={(e) =>
                                                handleTeamChange(e, i)
                                              }
                                              label={`Name`}
                                              onBlur={field.onBlur}
                                            />
                                          )}
                                        </Field>
                                      </MDBox>
                                    </Grid>
                                    <Grid
                                      item
                                      container
                                      xs={12}
                                      md={3}
                                      spacing={0}
                                    >
                                      <Grid item xs={6} md={5}>
                                        <MDBox className="mdsAutocompleteGrid">
                                          <Autocomplete
                                            size="small"
                                            name="mobile_code_id"
                                            value={val.mobile_code_id}
                                            onChange={(e, newValue) => {
                                              const mobileCodeId = [...teams];
                                              if (newValue) {
                                                mobileCodeId[i][
                                                  "mobile_code_id"
                                                ] = newValue;
                                                setTeams(mobileCodeId);
                                              } else {
                                                mobileCodeId[i][
                                                  "mobile_code_id"
                                                ] = null;
                                                setTeams(mobileCodeId);
                                              }
                                            }}
                                            options={countryList}
                                            getOptionLabel={(countryList) => {
                                              if (
                                                countryList.dial_code &&
                                                countryList.name
                                              ) {
                                                return (
                                                  countryList.dial_code +
                                                  " " +
                                                  countryList.name
                                                );
                                              }
                                              return "";
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label="Country Code"
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                              />
                                            )}
                                          />
                                          {commaonError("mobile_code_id")}
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={6} md={7}>
                                        <MDBox mb={2}>
                                          <Field name="mobile_number">
                                            {({ field, form }) => (
                                              <MDInput
                                                {...field}
                                                type="text"
                                                required
                                                label="Mobile Number"
                                                fullWidth
                                                value={val.mobile_number}
                                                onChange={(e) =>
                                                  handleTeamChange(e, i)
                                                }
                                                onBlur={handleBlur}
                                              />
                                            )}
                                          </Field>
                                          {commaonError("mobile_number")}
                                        </MDBox>
                                      </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                      <MDBox className="mdsAutocompleteGrid">
                                        <Autocomplete
                                          size="small"
                                          name="nationality_id"
                                          value={val.nationality_id}
                                          onChange={(e, newValue) => {
                                            const nationalityId = [...teams];
                                            if (newValue) {
                                              nationalityId[i][
                                                "nationality_id"
                                              ] = newValue;
                                              setTeams(nationalityId);
                                            } else {
                                              nationalityId[i][
                                                "nationality_id"
                                              ] = null;
                                              setTeams(nationalityId);
                                            }
                                          }}
                                          options={countryList}
                                          getOptionLabel={(countryList) =>
                                            countryList.name || ""
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label="Nationality"
                                              InputLabelProps={{ shrink: true }}
                                            />
                                          )}
                                        />
                                        {commaonError("nationality_id")}
                                      </MDBox>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                      <MDBox mb={2}>
                                        <Field>
                                          {({ field, form }) => (
                                            <MDInput
                                              {...field}
                                              name="email_id"
                                              type="email"
                                              required
                                              fullWidth
                                              value={val.email_id}
                                              onChange={(e) =>
                                                handleTeamChange(e, i)
                                              }
                                              label={`E-mail Id`}
                                              onBlur={field.onBlur}
                                            />
                                          )}
                                        </Field>
                                      </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                      <MDBox
                                        mb={2}
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          component="label"
                                          fullWidth
                                          color="success"
                                        >
                                          Profile Pic
                                          <input
                                            type="file"
                                            name="profile_pic"
                                            accept="image/png, image/jpeg"
                                            hidden
                                            onChange={(e) =>
                                              handleTeamChange(e, i)
                                            }
                                          />
                                        </Button>
                                        {val.profile_pic && (
                                          <>
                                            <Link
                                              to={`${process.env.REACT_APP_URI_PATH}${val.profile_pic}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="mdsViewFile"
                                            >
                                              <MDButton
                                                variant="gradient"
                                                color="primary"
                                                size="small"
                                                type="button"
                                              >
                                                View Pic
                                              </MDButton>
                                            </Link>
                                          </>
                                        )}
                                      </MDBox>
                                    </Grid>
                                    {lockCreate && (
                                      <Grid item xs={8} md={3}>
                                        <label>Lock : No</label>
                                        <Switch
                                          checked={
                                            val.is_lock == "On" ? true : false
                                          }
                                          name="is_lock"
                                          value={val.is_lock}
                                          onChange={(e) =>
                                            handleTeamChange(e, i)
                                          }
                                          inputProps={{
                                            "aria-label": "controlled",
                                          }}
                                        />
                                        <label>Yes</label>
                                      </Grid>
                                    )}

                                    <Grid item xs={4} md={2}>
                                      <IconButton className="mdsRemoveBtn">
                                        <DeleteForeverIcon
                                          variant="gradient"
                                          color="error"
                                          type="button"
                                          fontSize="large"
                                          onClick={() => handleTeamDelete(i)}
                                        />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                )
                            )}
                            {/* End Team Management */}
                          </Grid>
                        </MDBox>
                      </Box>
                    </DialogContent>
                  </MDBox>
                </Card>
                {/* End Team Management */}

                {/* Start Document Management */}
                <Card className="mt4rem">
                  <MDBox
                    mx={2}
                    mt={-5}
                    py={1}
                    borderRadius="lg"
                    px={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    variant="gradient"
                    bgColor="info"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Document Management
                    </MDTypography>
                    <MDButton
                      variant="outlined"
                      onClick={() => addMoreDocument()}
                      color="white"
                      size="small"
                    >
                      Add Document
                    </MDButton>
                  </MDBox>

                  <MDBox
                    variant="contained"
                    bgColor="white"
                    color="black"
                    borderRadius="md"
                    shadow="md"
                  >
                    <DialogContent>
                      <Box sx={{ flexGrow: 1 }}>
                        <MDBox pt={1} pb={1}>
                          <Grid container spacing={2}>
                            {documents.map((val, i) => (
                              <Grid
                                item
                                className="borderBottom"
                                style={{
                                  borderBottom: "1px solid #33333338",
                                  marginBottom: "10px",
                                }}
                                container
                                xs={12}
                                md={12}
                                key={i}
                                spacing={2}
                              >
                                <Grid item xs={12} md={3}>
                                  <MDBox mb={2}>
                                    <MDInput
                                      name="name"
                                      type="text"
                                      required
                                      fullWidth
                                      value={val.name}
                                      onChange={(e) =>
                                        handleDocumentChange(e, i)
                                      }
                                      label={`Document Name`}
                                    />
                                  </MDBox>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <MDBox className="mdsAutocompleteGrid">
                                    <Autocomplete
                                      size="small"
                                      name="issue_country_id"
                                      value={val.issue_country_id}
                                      onChange={(e, newValue) => {
                                        const issueCountryId = [...documents];
                                        if (newValue) {
                                          issueCountryId[i][
                                            "issue_country_id"
                                          ] = newValue;
                                          setDocuments(issueCountryId);
                                        } else {
                                          issueCountryId[i][
                                            "issue_country_id"
                                          ] = null;
                                          setDocuments(issueCountryId);
                                        }
                                      }}
                                      options={countryList}
                                      getOptionLabel={(countryList) =>
                                        countryList.name || ""
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Issue Country"
                                          InputLabelProps={{ shrink: true }}
                                        />
                                      )}
                                    />
                                    {commaonError("issue_country_id")}
                                  </MDBox>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <MDBox mb={2}>
                                    <MDInput
                                      type="date"
                                      name="issue_date"
                                      label="Issue Date"
                                      fullWidth
                                      value={val.issue_date}
                                      onChange={(e) =>
                                        handleDocumentChange(e, i)
                                      }
                                    />
                                  </MDBox>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                  <MDBox mb={2}>
                                    <MDInput
                                      type="date"
                                      name="expiry_date"
                                      label="Expiry Date"
                                      fullWidth
                                      value={val.expiry_date}
                                      onChange={(e) =>
                                        handleDocumentChange(e, i)
                                      }
                                    />
                                  </MDBox>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                  <MDBox
                                    mb={2}
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      //margin: "0px 2px",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      component="label"
                                      fullWidth
                                      color="success"
                                    >
                                      Upload Document
                                      <input
                                        type="file"
                                        name="document"
                                        accept="image/png, image/jpeg, application/pdf"
                                        hidden
                                        onChange={(e) =>
                                          handleDocumentChange(e, i)
                                        }
                                      />
                                    </Button>
                                    {val.document && (
                                      <>
                                        <Link
                                          to={`${process.env.REACT_APP_URI_PATH}${val.document}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="mdsViewFile"
                                        >
                                          <MDButton
                                            variant="gradient"
                                            color="primary"
                                            size="small"
                                            type="button"
                                          >
                                            View File
                                          </MDButton>
                                        </Link>
                                      </>
                                    )}
                                  </MDBox>
                                </Grid>

                                <Grid item xs={12} md={2}>
                                  <IconButton className="mdsRemoveBtn">
                                    <DeleteForeverIcon
                                      variant="gradient"
                                      color="error"
                                      type="button"
                                      fontSize="large"
                                      onClick={() => handleDocumentDelete(i)}
                                    />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            ))}
                          </Grid>
                        </MDBox>
                      </Box>
                    </DialogContent>
                  </MDBox>
                </Card>
                {/* End Document Management */}
              </Grid>
            </Grid>

            <MDBox mt={2} display="flex" alignItems="center">
              <MDButton
                variant="gradient"
                color="primary"
                size="small"
                type="submit"
                className="saveButton"
              >
                Save
              </MDButton>
            </MDBox>
          </Form>
        </Formik>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CreateRa;
