// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
//import useSWR from "swr";
import { useEffect, useState } from "react";
//import { Token } from "utils/clientFunctions";
import MDButton from "components/MDButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  Tooltip,
} from "@mui/material";
//import { settingDefault } from "utils/clientFunctions";
import { ErrorMessage, Field, Form, Formik } from "formik";
import MDInput from "components/MDInput";
import * as Yup from "yup";
import { updateData } from "utils/clientFunctions";

import { fetchData } from "utils/clientFunctions";
import useSWR from "swr";

import { postData, doUpload } from "utils/clientFunctions";
import { ToastProvider, useToasts } from "react-toast-notifications";
import { liveSearchData } from "layouts/commonComponents/liveSearchData";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button } from "@mui/material";
import { useNavigate, useParams, Navigate, Link } from "react-router-dom";
import MDAvatar from "components/MDAvatar";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "../../../../src/assets/css/Custom.css";
import { useFormik } from "formik";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";

import { callReportSchema } from "../components/schemas";

import { useSelector, useDispatch } from "react-redux";
import { getPermissions, selectPermissions } from "reducers/permissionSlice";

function CreateApplicant() {
  const { id: callReportId } = useParams();

  const [countryList, setCountryList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [applicantList, setApplicantList] = useState([]);
  const [raList, setRaList] = useState([]);
  const [usersList, setUserList] = useState([]);

  const { addToast } = useToasts();
  const navigate = useNavigate();

  //Start : For permission
  const dispatch = useDispatch();
  const permissions = useSelector(selectPermissions);
  if (callReportId) {
    if (
      !(
        permissions?.user_id === 1 ||
        permissions?.permission?.includes("clrEdit")
      )
    ) {
      navigate("/call_report_list");
    }
  } else {
    if (
      !(
        permissions?.user_id === 1 ||
        permissions?.permission?.includes("clrCreate")
      )
    ) {
      navigate("/call_report_list");
    }
  }
  useEffect(() => {
    dispatch(getPermissions());
  }, []);
  //End : For permission

  const [formData, setFormData] = useState({
    party_type: { label: "Company", value: "company" },
    party_id: "",
    party_entered_name: "",
    person_name: "",
    person_position: "",
    mobile_code_id: "",
    mobile_number: "",
    landline_code_id: "",
    landline_number: "",
    email_id: "",
    call_date_time: "",
    status_id: "",
    remarks: "",
  });

  const searchSpot = (value, key) => {
    liveSearchData({
      countryId: null,
      value,
      key,
      setRaList,
      setCompanyList,
      setApplicantList,
      setUserList,
    });
  };

  const getFetchData = async (url) => {
    return await fetchData(url);
  };

  //Get applicant, docs, religions, countries, position list and size of the company list
  useEffect(() => {
    if (callReportId) {
      const getcallReportUrl = `call_report/${callReportId}`;
      getFetchData(getcallReportUrl).then((res) => {
        if (res.data) {
          let {
            party_type,
            party_entered_name,
            person_name,
            person_position,
            mobile_number,
            landline_number,
            email_id,
            call_date_time,
            remarks,
            mobile_code_info,
            landline_code_info,
            status_info,
          } = res.data;

          let newPartyType = leadOptions?.filter(
            (tp) => tp.value === party_type
          );

          setFormData({
            party_type: newPartyType && newPartyType[0],
            party_id: res.party,
            person_name,
            party_entered_name,
            person_position,
            mobile_number,
            landline_number,
            email_id,
            call_date_time:
              call_date_time &&
              new Date(call_date_time).toLocaleString("sv").slice(0, 16),
            remarks,
            mobile_code_id: mobile_code_info,
            landline_code_id: landline_code_info,
            status_id: status_info,
          });
        }
      });
    }

    //Get countries list
    const countryUrl = `country/list`;
    getFetchData(countryUrl).then((res) => {
      let countryList = res.data;
      setCountryList(countryList);
    });

    //Get call report status list
    const statusUrl = `setting/type/13`;
    getFetchData(statusUrl).then((res) => {
      let statusListResult = res.data;
      setStatusList(statusListResult);
    });

    const companyUrl = `company/list?page=1`;
    getFetchData(companyUrl).then((res) => {
      let companyList = res.data;
      setCompanyList(companyList);
    });

    const applicantUrl = `applicant/list?page=1`;
    getFetchData(applicantUrl).then((res) => {
      let applicantList = res.data;
      setApplicantList(applicantList);
    });

    const raUrl = `recruiting_agency/list?page=1`;
    getFetchData(raUrl).then((res) => {
      let raList = res.data;
      setRaList(raList);
    });

    const userUrl = `user/list?page=1`;
    getFetchData(userUrl).then((res) => {
      let userList = res.data;
      setUserList(userList);
    });
  }, []);

  const handleFormSubmit = async (data, resetForm) => {
    //Collect final Data
    let submitData = {};
    submitData = data;

    //Update below fields to send only ids
    submitData.party_type = data.party_type?.value;
    submitData.mobile_code_id = data.mobile_code_id?.country_id;
    submitData.landline_code_id = data.landline_code_id?.country_id;
    submitData.status_id = data.status_id?.setting_id;

    const callReportUrl = `call_report`;
    const response = callReportId
      ? await updateData(`${callReportUrl}/${callReportId}`, submitData)
      : await postData(callReportUrl, submitData);

    if (!response.status) {
      if (response.message) {
        addToast(response.message, { appearance: "error", autoDismiss: true });
      }
      if (response.errors) {
        let apiErrors = response.errors;
        for (let i = 0; i < apiErrors.length; i++) {
          let errorMessage = apiErrors[i].message;
          errorMessage = errorMessage.replace(/['"]+/g, "").replace(/_/g, " ");
          errorMessage =
            errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
          addToast(errorMessage, { appearance: "error", autoDismiss: true });
        }
      }
    } else {
      let successMessage = callReportId
        ? "Call report has been updated successfully"
        : "New call report has been created successfully";
      addToast(successMessage, {
        appearance: "success",
        autoDismiss: true,
      });
      navigate(-1);
    }
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: formData,
    enableReinitialize: true,
    validationSchema: callReportSchema,
    onSubmit: (values, action) => {
      values.party_id =
        values.party_type?.value === "ra"
          ? values.party_id?.ra_id
          : values.party_type?.value === "company"
          ? values.party_id?.company_id
          : values.party_type?.value === "applicant"
          ? values.party_id?.applicant_id
          : null;
      handleFormSubmit(values, action);
    },
  });

  const commaonError = (fieldName) => {
    return errors?.[fieldName] && touched?.[fieldName] ? (
      <p className="formError">{errors[fieldName]}</p>
    ) : null;
  };

  const leadOptions = [
    { label: "Recruiting agency", value: "ra" },
    { label: "Company", value: "company" },
    { label: "Applicant", value: "applicant" },
    { label: "Other", value: "other" },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Formik
          initialValues={formData}
          enableReinitialze={true}
          onSubmit={handleSubmit}
        >
          <Form>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-5}
                    py={1}
                    borderRadius="lg"
                    px={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    variant="gradient"
                    bgColor="info"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      {`${callReportId ? "Update" : "New"} `} Call Report
                    </MDTypography>
                    <MDButton
                      variant="outlined"
                      onClick={() => navigate(-1)}
                      color="white"
                      size="small"
                    >
                      Back
                    </MDButton>
                  </MDBox>
                  <MDBox
                    variant="contained"
                    bgColor="white"
                    color="black"
                    borderRadius="md"
                    shadow="md"
                  >
                    <DialogContent>
                      <Box sx={{ flexGrow: 1 }}>
                        <MDBox pt={1} pb={1}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.party_type}
                                  size="small"
                                  name="party_type"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue("party_type", newValue)
                                      : setFieldValue("party_type", "")
                                  }
                                  options={leadOptions}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Party Type *"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {commaonError("party_type")}
                              </MDBox>
                            </Grid>
                            {values.party_type?.value !== "other" && (
                              <Grid item xs={12} md={3}>
                                <MDBox mb={2} className="mdsAutocompleteGrid">
                                  <Autocomplete
                                    value={values.party_id}
                                    size="small"
                                    name="party_id"
                                    onChange={(event, newValue) =>
                                      newValue
                                        ? setFieldValue("party_id", newValue)
                                        : setFieldValue("party_id", "")
                                    }
                                    options={
                                      values.party_type?.value === "ra"
                                        ? raList
                                        : values.party_type?.value ===
                                          "applicant"
                                        ? applicantList
                                        : companyList
                                    }
                                    getOptionLabel={(paymentOptions) => {
                                      console.log(
                                        "values.party_type?.value",
                                        values.party_type?.value
                                      );
                                      if (values.party_type?.value === "ra") {
                                        return paymentOptions?.ra_name || "";
                                      } else if (
                                        values.party_type?.value === "applicant"
                                      ) {
                                        return paymentOptions?.first_name
                                          ? paymentOptions?.first_name +
                                              " " +
                                              paymentOptions?.last_name
                                          : "";
                                      } else {
                                        return paymentOptions?.name || "" || "";
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Party Name *"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) =>
                                          searchSpot(
                                            values.party_type?.value,
                                            e.target.value
                                          )
                                        }
                                      />
                                    )}
                                  />
                                  {commaonError("party_id")}
                                </MDBox>
                              </Grid>
                            )}

                            {values.party_type?.value === "other" && (
                              <Grid item xs={12} md={3}>
                                <MDBox mb={2}>
                                  <MDInput
                                    type="text"
                                    name="party_entered_name"
                                    label="Party Name *"
                                    fullWidth
                                    value={values.party_entered_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {commaonError("party_entered_name")}
                                </MDBox>
                              </Grid>
                            )}

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  type="text"
                                  name="person_name"
                                  label="Person Name"
                                  fullWidth
                                  value={values.person_name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("person_name")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  type="text"
                                  name="person_position"
                                  label="Person Position"
                                  fullWidth
                                  value={values.person_position}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("person_position")}
                              </MDBox>
                            </Grid>

                            <Grid item container xs={12} md={3} spacing={0}>
                              <Grid item xs={6} md={6}>
                                <MDBox mb={2} className="mdsAutocompleteGrid">
                                  <Autocomplete
                                    value={values.mobile_code_id}
                                    size="small"
                                    name="mobile_code_id"
                                    onChange={(event, newValue) =>
                                      newValue
                                        ? setFieldValue(
                                            "mobile_code_id",
                                            newValue
                                          )
                                        : setFieldValue("mobile_code_id", "")
                                    }
                                    options={countryList}
                                    getOptionLabel={(countryList) => {
                                      if (
                                        countryList.dial_code &&
                                        countryList.name
                                      ) {
                                        return (
                                          countryList.dial_code +
                                          " " +
                                          countryList.name
                                        );
                                      }
                                      return "";
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Country Code"
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    )}
                                  />
                                  {commaonError("mobile_code_id")}
                                </MDBox>
                              </Grid>
                              <Grid item xs={6} md={6}>
                                <MDBox mb={2}>
                                  <MDInput
                                    name="mobile_number"
                                    type="text"
                                    label="Mobile Number"
                                    fullWidth
                                    value={values.mobile_number}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {commaonError("mobile_number")}
                                </MDBox>
                              </Grid>
                            </Grid>

                            <Grid item container xs={12} md={3} spacing={0}>
                              <Grid item xs={6} md={6}>
                                <MDBox mb={2} className="mdsAutocompleteGrid">
                                  <Autocomplete
                                    value={values.landline_code_id}
                                    size="small"
                                    name="landline_code_id"
                                    onChange={(event, newValue) =>
                                      newValue
                                        ? setFieldValue(
                                            "landline_code_id",
                                            newValue
                                          )
                                        : setFieldValue("landline_code_id", "")
                                    }
                                    options={countryList}
                                    getOptionLabel={(countryList) => {
                                      if (
                                        countryList.dial_code &&
                                        countryList.name
                                      ) {
                                        return (
                                          countryList.dial_code +
                                          " " +
                                          countryList.name
                                        );
                                      }
                                      return "";
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Country Code"
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    )}
                                  />
                                  {commaonError("landline_code_id")}
                                </MDBox>
                              </Grid>
                              <Grid item xs={6} md={6}>
                                <MDBox mb={2}>
                                  <MDInput
                                    name="landline_number"
                                    type="text"
                                    label="Landline Number"
                                    fullWidth
                                    value={values.landline_number}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {commaonError("landline_number")}
                                </MDBox>
                              </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="email_id"
                                  type="email"
                                  label="E-mail Id"
                                  fullWidth
                                  value={values.email_id}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("email_id")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="call_date_time"
                                  type="datetime-local"
                                  label="Call Date Time"
                                  fullWidth
                                  value={values.call_date_time}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("call_date_time")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.status_id}
                                  size="small"
                                  name="status_id"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue("status_id", newValue)
                                      : setFieldValue("status_id", "")
                                  }
                                  options={statusList}
                                  getOptionLabel={(statusList) =>
                                    statusList.setting_value || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Status"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {commaonError("status_id")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={12}>
                              <MDBox mb={2}>
                                <TextField
                                  id="outlined-multiline-static"
                                  label="Remarks *"
                                  name="remarks"
                                  InputLabelProps={{ shrink: true }}
                                  multiline
                                  fullWidth
                                  rows={3}
                                  value={values.remarks}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("remarks")}
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Box>
                    </DialogContent>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
            <MDBox mt={2} display="flex" alignItems="center">
              <MDButton
                variant="gradient"
                color="primary"
                size="small"
                type="submit"
                className="saveButton"
              >
                Save
              </MDButton>
            </MDBox>
          </Form>
        </Formik>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CreateApplicant;
