import * as Yup from "yup";

export const createDealSchema = Yup.object({
  receiving_country_id: Yup.mixed().required("Please select receiving country"),
  company_id: Yup.mixed().required("Please select company"),
  supply_country_id: Yup.mixed().required("Please select supply country"),
  ra_id: Yup.mixed().required("Please select recruiting agency"),
  status_id: Yup.mixed().required("Please select status"),
  remarks: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter remarks")
    .max(250),
});
