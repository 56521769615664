import * as Yup from "yup";

export const invoiceSchema = Yup.object({
  // invoice_number: Yup.string()
  //   .min(2, "Please enter minimum two characters")
  //   .required("Please enter invoice number")
  //   .max(250, "Max 250 characters allowed"),

  invoice_date: Yup.date().required("Please provide invoice date"),
  due_date: Yup.date().required("Please provide due date"),

  bill_to_vendor_type: Yup.mixed().required(
    "Please select bill to vendor type"
  ),
  bill_to_vendor_type_id: Yup.mixed().required("Please select vendor name"),
  br_user_company_id: Yup.mixed().required("Please select branch"),

  status_id: Yup.mixed().required("Please select status"),
});
