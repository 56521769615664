// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
//import useSWR from "swr";
import { useEffect, useState, useRef } from "react";

import { fetchData } from "utils/clientFunctions";

import { useNavigate, useParams, Navigate, Link } from "react-router-dom";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useFormik, Field, Form, Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { getPermissions, selectPermissions } from "reducers/permissionSlice";
import { CardTopBoxBack } from "layouts/commonComponents/cardTopBoxBack";
import DataTable from "react-data-table-component";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { firstLetterCapital } from "utils/clientFunctions";
import { dateFormat } from "utils/clientFunctions";
import MDButton from "components/MDButton";
import { CSVDownload, CSVLink } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";
import { dateTimeFormat } from "utils/clientFunctions";
import { dateTimeStamp } from "utils/clientFunctions";
function RevenueReport() {
  const csvLink = useRef();

  const getFetchData = async (url) => {
    return await fetchData(url);
  };
  const [searchFormData, setSearchFormData] = useState({
    from_date: "",
    to_date: "",
  });
  const [report, setReport] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  useEffect(() => {
    getReport();
  }, [pageIndex, searchFormData]);
  const getReport = async () => {
    let queryUrl = getQueryParams();
    getFetchData(`report/revenue${queryUrl}`).then((res) => {
      if (res && res.data) {
        setPageCount(Math.ceil(res.count / 10));
        setReport(res.data || []);
      }
    });
  };

  const getQueryParams = () => {
    let queryUrl = `?page=${pageIndex}`;
    queryUrl = searchFormData.from_date
      ? queryUrl + "&from_date=" + searchFormData.from_date
      : queryUrl;
    queryUrl = searchFormData.to_date
      ? queryUrl + "&to_date=" + searchFormData.to_date
      : queryUrl;
    return queryUrl;
  };

  const columns = [
    {
      name: "Vendor name",
      selector: (row) => row.vendor_name,
    },
    {
      name: "Payment Type",
      selector: (row) => row.payment_value,
    },
    {
      name: "Date of Payment",
      selector: (row) => (row.acc_date ? dateFormat(row.acc_date) : "-"),
    },
    {
      name: "Amount",
      selector: (row) => row.country_currency_code + " " + row.acc_amount,
    },
    {
      name: "Party",
      selector: (row) => row.party_name,
    },
    {
      name: "Status",
      selector: (row) =>
        row.payment_status ? firstLetterCapital(row.payment_status) : "-",
    },
  ];
  const data = report;
  const doPaginationChange = (event, value) => {
    setPageIndex(value);
  };

  const onChangeSearch = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    setSearchFormData({ ...searchFormData, [name]: val });
  };

  const [csvData, setCsvData] = useState([]);
  const [downloadCsv, setDownloadCsv] = useState(false);
  let csvHeaders = [
    { label: "Vendor Name", key: "vendor_name" },
    { label: "Payment Type", key: "payment_value" },
    { label: "Date of Payment", key: "acc_date" },
    { label: "Amount", key: "acc_amount" },
    { label: "Party", key: "party_name" },
    { label: "Status", key: "payment_status" },
  ];
  const exportCsv = async () => {
    let queryUrl = getQueryParams();
    getFetchData(`report/revenue_download${queryUrl}`).then(async (res) => {
      if (res?.data) {
        let exportData = [];
        await Promise.all(
          res?.data.map((report) => {
            report.acc_date = report.acc_date
              ? dateFormat(report.acc_date)
              : "-";
            report.acc_amount = report.acc_amount
              ? report.country_currency_code + " " + report.acc_amount
              : "-";
            exportData.push(report);
          })
        );
        setCsvData(exportData); //Set the csv data
        setDownloadCsv(true); // Show the CSVDwonload link
        setTimeout(() => {
          csvLink?.current?.link?.click();
          setDownloadCsv(false); // Hide the CSVDwonload link
        }, 100);
      }
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <CardTopBoxBack title={"Revenue Report"} showBackButton={false} />
              <MDBox
                variant="contained"
                bgColor="white"
                color="black"
                borderRadius="md"
                shadow="md"
              >
                <Grid container p={2} spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Formik>
                      <Form>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={3}></Grid>
                          <Grid item xs={12} md={2}>
                            <MDBox mb={2}>
                              <MDInput
                                name="from_date"
                                type="date"
                                label="From Date"
                                fullWidth
                                value={searchFormData.from_date}
                                onChange={onChangeSearch}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <MDBox mb={2}>
                              <MDInput
                                name="to_date"
                                type="date"
                                label="To Date"
                                fullWidth
                                value={searchFormData.to_date}
                                onChange={onChangeSearch}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <CSVLink
                              className=" d-none "
                              data={csvData}
                              filename={`Revenue Report ${dateTimeStamp()}`}
                              headers={csvHeaders}
                              ref={csvLink}
                              target="_self"
                            />

                            <MDBox display="flex" alignItems="center">
                              <MDButton
                                variant="gradient"
                                color="primary"
                                size="small"
                                type="button"
                                className="saveButton"
                                onClick={exportCsv}
                              >
                                <DownloadIcon /> Export CSV
                              </MDButton>
                            </MDBox>
                          </Grid>
                        </Grid>
                      </Form>
                    </Formik>
                    <DataTable columns={columns} data={data} />
                    <Grid item xs={12} md={12}>
                      <Stack spacing={2} mb={2} ml={2}>
                        <Pagination
                          count={pageCount}
                          page={pageIndex}
                          onChange={doPaginationChange}
                          variant="outlined"
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default RevenueReport;
