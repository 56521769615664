// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { fetchData } from "utils/clientFunctions";
import { useEffect, useRef, useState } from "react";
import MDButton from "components/MDButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import MDAvatar from "components/MDAvatar";
import { Delete, Edit, RemoveRedEye } from "@mui/icons-material";
import { useAuth } from "utils/auth";
import pxToRem from "assets/theme/functions/pxToRem";
import { dateFormat } from "utils/clientFunctions";
import { CardTopBoxAdd } from "layouts/commonComponents/cardTopBoxAdd";
import DealRes from "../components/dealRes";
import { useSelector, useDispatch } from "react-redux";
import { getPermissions, selectPermissions } from "reducers/permissionSlice";
import { liveSearchData } from "layouts/commonComponents/liveSearchData";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { CSVDownload, CSVLink } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";
import { firstLetterCapital } from "utils/clientFunctions";
import { dateTimeStamp } from "utils/clientFunctions";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function DealsList() {
  const csvLink = useRef();
  const [pageIndex, setPageIndex] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [pageResize, setPageResize] = useState(
    window.innerWidth < 992 ? true : false
  );
  const navigate = useNavigate();

  //Start : For permission
  const dispatch = useDispatch();
  var permissions = useSelector(selectPermissions);
  if (
    !(
      permissions?.user_id === 1 || permissions?.permission?.includes("delView")
    )
  ) {
    navigate("/dashboard");
  }
  let showAddButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("delCreate")
      ? true
      : false;
  let showEditButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("delEdit")
      ? true
      : false;
  let showDeleteButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("delDelete")
      ? true
      : false;
  useEffect(() => {
    dispatch(getPermissions());
  }, []);
  //End : For permission

  const [List, setList] = useState([]);
  const [raData, setRaData] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [applicantList, setApplicantList] = useState([]);
  const [raList, setRaList] = useState([]);

  const { logout } = useAuth();

  const initialLoadData = async () => {
    let queryUrl = getQueryParams();
    const url = `deal/list${queryUrl}`;
    const data = await fetchData(url);
    if (
      data?.name === "JsonWebTokenError" ||
      data?.name === "TokenExpiredError" ||
      data?.message === "You are not authorized"
    ) {
      logout();
    }
    if (data && data.data) {
      let dealsCount = Math.ceil(data.count / 10);
      setPageCount(dealsCount);
      setList(data.data || []);
    }
  };

  const searchSpot = (value, key) => {
    let countryId =
      value === "ra"
        ? searchFormData?.supply_country_id?.country_id
        : value === "company"
        ? searchFormData?.receiving_country_id?.country_id
        : null;
    if (key) {
      liveSearchData({
        countryId,
        value,
        key,
        setRaList,
        setCompanyList,
        setApplicantList,
      });
    }
  };

  useEffect(() => {
    const countryUrl = `country/list`;
    getFetchData(countryUrl).then((res) => {
      setCountryList(res.data);
    });

    const statusUrl = `setting/type/8`;
    getFetchData(statusUrl).then((res) => {
      setStatusList(res.data);
    });

    const raUrl = `recruiting_agency/list?page=1`;
    getFetchData(raUrl).then((res) => {
      setRaList(res.data);
    });
  }, []);

  const [searchFormData, setSearchFormData] = useState({
    receiving_country_id: "",
    company_id: "",
    ra_id: "",
    status_id: "",
  });
  const getFetchData = async (settingUrl) => {
    return await fetchData(settingUrl);
  };
  useEffect(() => {
    initialLoadData();
  }, [
    pageIndex,
    searchFormData.receiving_country_id,
    searchFormData.company_id,
    searchFormData.ra_id,
    searchFormData.status_id,
  ]);

  useEffect(() => {
    initialLoadData();
  }, [pageIndex]);

  const doPaginationChange = (event, value) => {
    setPageIndex(value);
  };

  //Dialog popup
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = (raInfo) => {
    setRaData(raInfo);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setRaData({});
    setOpenDialog(false);
  };

  window.addEventListener("resize", () => {
    if (window.innerWidth < 992) {
      setPageResize(true);
    } else {
      setPageResize(false);
    }
  });

  const ViewMoreRa = ({ openDialog, raData, handleCloseDialog }) => {
    const {
      deal_id,
      remarks,
      receiving_country_info,
      company_info,
      supply_country_info,
      recruiting_agency_info,
      status_info,
      payment_info,
      job_list,
    } = raData;

    return (
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        fullScreen={window.innerWidth < 768 ? true : false}
      >
        <DialogTitle sx={{ m: 0 }} id="customized-dialog-title">
          {"Deal Info"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container px={2} spacing={1}>
            <ViewTypo
              title={"Receiving Country"}
              res={getCountryFlag(receiving_country_info)}
            />
            <ViewTypo
              title={"Company Name"}
              res={company_info?.name + "-" + company_info?.company_code}
            />
            <ViewTypo
              title={"Supply Country"}
              res={getCountryFlag(supply_country_info)}
            />
            <ViewTypo
              title={"Recruiting Agency"}
              res={
                recruiting_agency_info?.ra_name +
                "-" +
                recruiting_agency_info?.ra_code
              }
            />
            <ViewTypo title={"Status"} res={status_info?.setting_value} />
            <ViewTypo title={"Remarks  "} res={remarks} />

            <Grid item xs={12} md={12} lg={12}>
              <MDTypography variant="h6" fontWeight="medium">
                {job_list?.length ? (
                  <MDBox
                    py={1}
                    px={1}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      List of Jobs
                    </MDTypography>
                  </MDBox>
                ) : (
                  ""
                )}
              </MDTypography>
            </Grid>
            {job_list?.map((job, i) => {
              const {
                currency_info,
                offered_salary,
                local_currency_info,
                local_service_fee,
                overseas_currency_info,
                overseas_service_fee,
                is_lock,
                position_info,
                nationality_info,
                created_by_user_id,
              } = job;
              if (
                permissions?.user_id === 1 ||
                permissions?.user_id === created_by_user_id ||
                !is_lock
              ) {
                return (
                  <>
                    <Grid item xs={12} md={12} lg={12}>
                      <MDTypography variant="h6" fontWeight="medium">
                        {i + 1}.
                      </MDTypography>
                    </Grid>
                    <ViewTypo
                      title={"Job Title"}
                      res={position_info?.setting_value}
                    />
                    <ViewTypo
                      title={"Offered Salary"}
                      res={
                        currency_info?.currency_code + " " + offered_salary * 1
                      }
                    />
                    <ViewTypo
                      title={"Nationality"}
                      res={getCountryFlag(nationality_info)}
                    />

                    {local_currency_info && local_service_fee && (
                      <ViewTypo
                        title={"Local Area Service Fee"}
                        res={
                          local_currency_info?.currency_code +
                          " " +
                          local_service_fee * 1
                        }
                      />
                    )}

                    {overseas_currency_info && overseas_service_fee && (
                      <ViewTypo
                        title={"Overseas Area Service Fee"}
                        res={
                          overseas_currency_info?.currency_code +
                          " " +
                          overseas_service_fee * 1
                        }
                      />
                    )}
                    {/* <ViewTypo
                      title={"Lock"}
                      res={is_lock === 1 ? "Yes" : "No"}
                    /> */}
                  </>
                );
              }
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialog}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    );
  };

  const HeadTypo = ({ title, lg }) => {
    return (
      <Grid item xs={4} md={4} lg={lg}>
        <MDTypography fontSize={pxToRem(14)} fontWeight="medium">
          {title}
        </MDTypography>
      </Grid>
    );
  };

  const BodyTypo = ({ title, lg }) => {
    return (
      <Grid item xs={4} md={4} lg={lg}>
        <MDTypography fontSize={pxToRem(14)} fontWeight="regular">
          {title}
        </MDTypography>
      </Grid>
    );
  };

  const ViewTypo = ({ title, res }) => {
    return (
      <>
        <Grid item xs={6} md={6} lg={6}>
          <MDTypography variant="h6" fontWeight="medium">
            {title}
          </MDTypography>
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <MDTypography variant="h6" fontWeight="regular">
            {res}
          </MDTypography>
        </Grid>
      </>
    );
  };

  const getCountryFlag = (countryInfo) => {
    return (
      <>
        <MDTypography variant="h6" fontWeight="regular">
          <MDAvatar
            src={`https://flagcdn.com/w40/${countryInfo?.code.toLowerCase()}.png`}
            name={countryInfo?.name}
            size="md"
            style={{ display: "inline-block", overflow: "visible" }}
            sx={{ width: 20, height: 20 }}
          />{" "}
          {countryInfo?.name}
        </MDTypography>
      </>
    );
  };

  const getDataByCountry = (dataType = "ra", countryId) => {
    if (dataType === "company") {
      const companyUrl = `company/country/${countryId}`;
      getFetchData(companyUrl).then((res) => {
        let companyList = res.data;
        setCompanyList(companyList);
      });
    }
    // } else if (dataType === "ra") {
    //   const raUrl = `recruiting_agency/country/${countryId}`;
    //   getFetchData(raUrl).then((res) => {
    //     let raList = res.data;
    //     setRaList(raList);
    //   });
    // }
  };
  //START : Search and Filter form data
  //Handle onchange search and filter
  const onChangeSearch = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    setSearchFormData({ ...searchFormData, [name]: val });
  };
  const [csvData, setCsvData] = useState([]);
  const [downloadCsv, setDownloadCsv] = useState(false);

  let csvHeaders = [
    { label: "Receiving Country", key: "receiving_country" },
    { label: "Company Name", key: "company_name" },
    { label: "Supply Country", key: "supply_country" },
    { label: "Recruiting Agency", key: "recruiting_agency" },
    { label: "Status", key: "status" },
  ];
  //Prepare the csv data for export
  const exportCsv = async () => {
    let queryUrl = getQueryParams();
    queryUrl = queryUrl + `&download=1`;
    getFetchData(`deal/list${queryUrl}`).then(async (res) => {
      if (res?.data) {
        let exportData = [];
        await Promise.all(
          res?.data.map((report) => {
            report.receiving_country = report?.receiving_country_info?.name;
            report.company_name =
              report?.company_info?.name +
              "-" +
              report?.company_info?.company_code;
            report.supply_country = report?.supply_country_info?.name;
            report.recruiting_agency =
              report?.recruiting_agency_info?.ra_name +
              "-" +
              report?.recruiting_agency_info?.ra_code;
            report.status = report?.status_info?.setting_value;
            exportData.push(report);
          })
        );
        setCsvData(exportData); //Set the csv data
        setDownloadCsv(true); // Show the CSVDwonload link
        setTimeout(() => {
          csvLink?.current?.link?.click();
          setDownloadCsv(false); // Hide the CSVDwonload link
        }, 100);
      }
    });
  };
  //Prepare the query string for search and filter
  const getQueryParams = () => {
    let queryUrl = `?page=${pageIndex}`;
    queryUrl = searchFormData?.receiving_country_id?.country_id
      ? queryUrl +
        "&receiving_country_id=" +
        searchFormData?.receiving_country_id?.country_id
      : queryUrl;

    queryUrl = searchFormData?.company_id?.company_id
      ? queryUrl + "&company_id=" + searchFormData?.company_id?.company_id
      : queryUrl;

    queryUrl = searchFormData?.ra_id?.ra_id
      ? queryUrl + "&ra_id=" + searchFormData?.ra_id?.ra_id
      : queryUrl;

    queryUrl = searchFormData.status_id?.setting_id
      ? queryUrl + "&status_id=" + searchFormData.status_id?.setting_id
      : queryUrl;

    return queryUrl;
  };
  //END : Search and Filter form data
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <CardTopBoxAdd
                title={"Deals"}
                link={"/dealdata"}
                showAddButton={showAddButton}
              />
              <MDBox>
                <Grid container p={2} spacing={2}>
                  {/* START : Search and filter block */}
                  <Grid
                    container
                    className="searchFilterBlock"
                    p={2}
                    spacing={2}
                    mt={1}
                  >
                    <Grid item xs={6} md={2}>
                      <MDBox mb={2} className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.receiving_country_id}
                          size="small"
                          name="receiving_country_id"
                          options={countryList}
                          getOptionLabel={(countryList) =>
                            countryList.name || ""
                          }
                          onChange={(event, newValue) => {
                            if (newValue) {
                              getDataByCountry("company", newValue?.country_id);

                              setSearchFormData({
                                ...searchFormData,
                                ["receiving_country_id"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["receiving_country_id"]: "",
                              });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Receiving Country"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDBox mb={2} className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.company_id}
                          size="small"
                          name="company_id"
                          options={companyList}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearchFormData({
                                ...searchFormData,
                                ["company_id"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["company_id"]: "",
                              });
                            }
                          }}
                          getOptionLabel={(companyList) =>
                            companyList.name || ""
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Company"
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) =>
                                searchSpot("company", e.target.value)
                              }
                            />
                          )}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDBox mb={2} className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.ra_id}
                          size="small"
                          name="ra_id"
                          options={raList}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearchFormData({
                                ...searchFormData,
                                ["ra_id"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["ra_id"]: "",
                              });
                            }
                          }}
                          getOptionLabel={(raList) => raList.ra_name || ""}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Recruiting Agency"
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) => searchSpot("ra", e.target.value)}
                            />
                          )}
                        />
                      </MDBox>
                    </Grid>

                    <Grid item xs={6} md={2}>
                      <MDBox mb={2} className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.status_id}
                          size="small"
                          name="status_id"
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearchFormData({
                                ...searchFormData,
                                ["status_id"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["status_id"]: "",
                              });
                            }
                          }}
                          options={statusList}
                          getOptionLabel={(statusList) =>
                            statusList.setting_value || ""
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Status"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDBox display="flex" alignItems="center">
                        {/* {downloadCsv && (
                          <>
                            <CSVDownload
                              data={csvData}
                              filename={"my-3534564-file.csv"}
                              headers={csvHeaders}
                              target="_self"
                            />
                          </>
                        )} */}
                        <CSVLink
                          className=" d-none "
                          data={csvData}
                          filename={`Deals ${dateTimeStamp()}`}
                          headers={csvHeaders}
                          ref={csvLink}
                          target="_self"
                        />
                        <MDButton
                          variant="gradient"
                          color="primary"
                          size="small"
                          type="button"
                          className="searchButton"
                          onClick={exportCsv}
                        >
                          <DownloadIcon /> Export CSV
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                  {/* END : Search and filter block */}

                  {!pageResize && (
                    <Grid item xs={12} md={12} lg={12}>
                      <MDBox
                        borderRadius="lg"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p={1}
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <HeadTypo title="Receiving Country" lg={2} />
                        <HeadTypo title="Company name" lg={2} />
                        <HeadTypo title="Supply Country" lg={2} />
                        <HeadTypo title="RA name" lg={2} />
                        <HeadTypo title="Status" lg={1} />
                        <HeadTypo title="Action" lg={1} />
                      </MDBox>
                    </Grid>
                  )}
                  {List.map((dealItem, index) => {
                    let {
                      deal_id,
                      receiving_country_info,
                      company_info,
                      supply_country_info,
                      recruiting_agency_info,
                      status_info,
                    } = dealItem;
                    return (
                      <>
                        {!pageResize ? (
                          <Grid item key={deal_id} xs={12} md={12} lg={12}>
                            <MDBox
                              borderRadius="lg"
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              p={1}
                              sx={{
                                border: ({
                                  borders: { borderWidth, borderColor },
                                }) => `${borderWidth[1]} solid ${borderColor}`,
                              }}
                            >
                              <BodyTypo
                                title={getCountryFlag(receiving_country_info)}
                                lg={2}
                              />
                              <BodyTypo
                                title={
                                  company_info?.name +
                                  "-" +
                                  company_info?.company_code
                                }
                                lg={2}
                              />

                              <BodyTypo
                                title={getCountryFlag(supply_country_info)}
                                lg={2}
                              />
                              <BodyTypo
                                title={
                                  recruiting_agency_info?.ra_name +
                                  "-" +
                                  recruiting_agency_info?.ra_code
                                }
                                lg={2}
                              />
                              <BodyTypo
                                title={status_info?.setting_value}
                                lg={1}
                              />
                              <Grid item xs={4} md={4} lg={1}>
                                <IconButton
                                  size="small"
                                  disableRipple
                                  color="inherit"
                                  onClick={() => handleOpenDialog(dealItem)}
                                >
                                  <RemoveRedEye />
                                </IconButton>
                                {showEditButton && (
                                  <IconButton
                                    size="small"
                                    disableRipple
                                    color="inherit"
                                    onClick={() =>
                                      navigate(`/dealdata/${deal_id}`)
                                    }
                                  >
                                    <Edit />
                                  </IconButton>
                                )}
                                {showDeleteButton && (
                                  <IconButton
                                    size="small"
                                    disableRipple
                                    color="inherit"
                                  >
                                    <Delete />
                                  </IconButton>
                                )}
                              </Grid>
                            </MDBox>
                          </Grid>
                        ) : (
                          <DealRes
                            key={deal_id}
                            dealItem={dealItem}
                            index={index + 1}
                            handleOpenDialog={handleOpenDialog}
                            showEditButton={showEditButton}
                            showDeleteButton={showDeleteButton}
                          />
                        )}
                      </>
                    );
                  })}
                </Grid>
                <Grid item xs={12} md={3}>
                  <Stack spacing={2} mb={2} ml={2}>
                    <Pagination
                      count={pageCount}
                      page={pageIndex}
                      onChange={doPaginationChange}
                      variant="outlined"
                    />
                  </Stack>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <ViewMoreRa
        openDialog={openDialog}
        raData={raData}
        handleCloseDialog={handleCloseDialog}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default DealsList;
