// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
//import useSWR from "swr";
import { useEffect, useState, useRef } from "react";

import { fetchData } from "utils/clientFunctions";

import { useNavigate, useParams, Navigate, Link } from "react-router-dom";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useFormik, Field, Form, Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { getPermissions, selectPermissions } from "reducers/permissionSlice";
import { CardTopBoxBack } from "layouts/commonComponents/cardTopBoxBack";
import DataTable from "react-data-table-component";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { firstLetterCapital } from "utils/clientFunctions";
import { dateFormat } from "utils/clientFunctions";
import MDButton from "components/MDButton";
import { CSVDownload, CSVLink } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";
import { liveSearchData } from "layouts/commonComponents/liveSearchData";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { dateTimeStamp } from "utils/clientFunctions";
function ClientGrowthReport() {
  const csvLink = useRef();
  const getFetchData = async (url) => {
    return await fetchData(url);
  };
  const [searchFormData, setSearchFormData] = useState({
    from_date: "",
    to_date: "",
    vendor_type: "",
    vendor_id: "",
  });
  const [report, setReport] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    getReport();
  }, [pageIndex, searchFormData]);

  const getReport = async () => {
    let queryUrl = getQueryParams();
    getFetchData(`report/client_growth${queryUrl}`).then((res) => {
      if (res && res.data) {
        setPageCount(Math.ceil(res.count / 10));
        setReport(res.data || []);
      }
    });
  };
  const columns = [
    {
      name: "Client Name",
      selector: (row) => row.vendor_name,
    },
    {
      name: "Country",
      selector: (row) => (row.country_name ? row.country_name : "-"),
    },
    {
      name: "Debit/Credit",
      selector: (row) => firstLetterCapital(row.debit_credit_type),
    },
    {
      name: "Date",
      selector: (row) => (row.acc_date ? dateFormat(row.acc_date) : "-"),
    },
    {
      name: "Amount",
      selector: (row) =>
        row.acc_amount ? row.country_currency_code + " " + row.acc_amount : "-",
    },
    {
      name: "Status",
      selector: (row) =>
        row.payment_status ? firstLetterCapital(row.payment_status) : "-",
    },
  ];
  const data = report;
  const doPaginationChange = (event, value) => {
    setPageIndex(value);
  };

  const onChangeSearch = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    setSearchFormData({ ...searchFormData, [name]: val });
  };

  const [csvData, setCsvData] = useState([]);
  const [downloadCsv, setDownloadCsv] = useState(false);
  let csvHeaders = [
    { label: "Client Name", key: "vendor_name" },
    { label: "Country", key: "country_name" },
    { label: "Debit/Credit", key: "debit_credit_type" },
    { label: "Date", key: "acc_date" },
    { label: "Amount", key: "acc_amount" },
    { label: "Status", key: "payment_status" },
  ];

  const getQueryParams = () => {
    let queryUrl = `?page=${pageIndex}`;
    queryUrl = searchFormData.from_date
      ? queryUrl + "&from_date=" + searchFormData.from_date
      : queryUrl;
    queryUrl = searchFormData.to_date
      ? queryUrl + "&to_date=" + searchFormData.to_date
      : queryUrl;

    queryUrl = searchFormData?.vendor_type?.value
      ? queryUrl + "&vendor_type=" + searchFormData?.vendor_type?.value
      : queryUrl;

    if (searchFormData?.vendor_type?.value && searchFormData?.vendor_id) {
      let vendorType = searchFormData?.vendor_type?.value;
      let vendorId =
        vendorType === "company"
          ? searchFormData.vendor_id?.company_id
          : vendorType === "ra"
          ? searchFormData.vendor_id?.ra_id
          : vendorType === "applicant"
          ? searchFormData.vendor_id?.applicant_id
          : "";
      queryUrl = vendorId ? queryUrl + "&vendor_id=" + vendorId : queryUrl;
    }
    return queryUrl;
  };

  const exportCsv = async () => {
    let queryUrl = getQueryParams();
    getFetchData(`report/client_growth_download${queryUrl}`).then(
      async (res) => {
        if (res?.data) {
          let exportData = [];
          await Promise.all(
            res?.data.map((report) => {
              report.acc_date = report.acc_date
                ? dateFormat(report.acc_date)
                : "-";
              report.debit_credit_type = report.debit_credit_type
                ? firstLetterCapital(report.debit_credit_type)
                : "-";
              report.acc_amount = report.acc_amount
                ? report.country_currency_code + " " + report.acc_amount
                : "-";
              exportData.push(report);
            })
          );
          setCsvData(exportData); //Set the csv data
          setDownloadCsv(true); // Show the CSVDwonload link
          setTimeout(() => {
            csvLink?.current?.link?.click();
            setDownloadCsv(false); // Hide the CSVDwonload link
          }, 100);
        }
      }
    );
  };

  //------New
  const [usersList, setUserList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [applicantList, setApplicantList] = useState([]);
  const [raList, setRaList] = useState([]);
  const vendorOptions = [
    { label: "Recruiting agency", value: "ra" },
    { label: "Company", value: "company" },
    { label: "Applicant", value: "applicant" },
  ];

  const searchSpot = (value, key) => {
    liveSearchData({
      countryId: null,
      value,
      key,
      setRaList,
      setCompanyList,
      setApplicantList,
      setUserList,
    });
  };
  useEffect(() => {
    //Get company list
    const companyApiUrl = `company/list?page=1`;
    getFetchData(companyApiUrl).then((res) => {
      setCompanyList(res.data);
    });

    const applicantUrl = `applicant/list?page=1`;
    getFetchData(applicantUrl).then((res) => {
      setApplicantList(res.data);
    });

    const raUrl = `recruiting_agency/list?page=1`;
    getFetchData(raUrl).then((res) => {
      setRaList(res.data);
    });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <CardTopBoxBack
                title={"Client Growth Report"}
                showBackButton={false}
              />
              <MDBox
                variant="contained"
                bgColor="white"
                color="black"
                borderRadius="md"
                shadow="md"
              >
                <Grid container p={2} spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Formik>
                      <Form>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={3}>
                            <MDBox mb={2} className="mdsAutocompleteGrid">
                              <Autocomplete
                                value={searchFormData.vendor_type}
                                size="small"
                                //defaultValue={vendorOptions[0]}
                                name="vendor_type"
                                onChange={(event, newValue) => {
                                  if (newValue) {
                                    setSearchFormData({
                                      ...searchFormData,
                                      ["vendor_type"]: newValue,
                                    });
                                  } else {
                                    setSearchFormData({
                                      ...searchFormData,
                                      ["vendor_type"]: "",
                                    });
                                  }
                                }}
                                options={vendorOptions}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Client Type"
                                    InputLabelProps={{ shrink: true }}
                                  />
                                )}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <MDBox mb={2} className="mdsAutocompleteGrid">
                              <Autocomplete
                                value={searchFormData.vendor_id}
                                size="small"
                                name="vendor_id"
                                onChange={(event, newValue) => {
                                  if (newValue) {
                                    setSearchFormData({
                                      ...searchFormData,
                                      ["vendor_id"]: newValue,
                                    });
                                  } else {
                                    setSearchFormData({
                                      ...searchFormData,
                                      ["vendor_id"]: "",
                                    });
                                  }
                                }}
                                options={
                                  searchFormData.vendor_type?.value === "ra"
                                    ? raList
                                    : searchFormData.vendor_type?.value ===
                                      "applicant"
                                    ? applicantList
                                    : searchFormData.vendor_type?.value ===
                                      "company"
                                    ? companyList
                                    : []
                                }
                                getOptionLabel={(vendorTypeOptions) => {
                                  if (
                                    searchFormData.vendor_type?.value === "ra"
                                  ) {
                                    return vendorTypeOptions?.ra_name || "";
                                  } else if (
                                    searchFormData.vendor_type?.value ===
                                    "applicant"
                                  ) {
                                    return vendorTypeOptions?.first_name
                                      ? vendorTypeOptions?.first_name +
                                          " " +
                                          vendorTypeOptions?.last_name
                                      : "";
                                  } else if (
                                    searchFormData.vendor_type?.value ===
                                    "company"
                                  ) {
                                    return vendorTypeOptions?.name
                                      ? vendorTypeOptions?.name
                                      : "";
                                  } else {
                                    return (
                                      vendorTypeOptions?.ra_name || "" || ""
                                    );
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Client Name"
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) =>
                                      searchSpot(
                                        searchFormData.vendor_type?.value,
                                        e.target.value
                                      )
                                    }
                                  />
                                )}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <MDBox mb={2}>
                              <MDInput
                                name="from_date"
                                type="date"
                                label="From Date"
                                fullWidth
                                value={searchFormData.from_date}
                                onChange={onChangeSearch}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <MDBox mb={2}>
                              <MDInput
                                name="to_date"
                                type="date"
                                label="To Date"
                                fullWidth
                                value={searchFormData.to_date}
                                onChange={onChangeSearch}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            {/* {downloadCsv && (
                              <>
                                <CSVDownload
                                  data={csvData}
                                  filename={"my-3534564-file.csv"}
                                  headers={csvHeaders}
                                  target="_self"
                                />
                              </>
                            )} */}
                            <CSVLink
                              className=" d-none "
                              data={csvData}
                              filename={`Client Growth Report ${dateTimeStamp()}`}
                              headers={csvHeaders}
                              ref={csvLink}
                              target="_self"
                            />
                            <MDBox display="flex" alignItems="center">
                              <MDButton
                                variant="gradient"
                                color="primary"
                                size="small"
                                type="button"
                                className="saveButton"
                                onClick={exportCsv}
                              >
                                <DownloadIcon /> Export CSV
                              </MDButton>
                            </MDBox>
                          </Grid>
                        </Grid>
                      </Form>
                    </Formik>
                    <DataTable columns={columns} data={data} />
                    <Grid item xs={12} md={12}>
                      <Stack spacing={2} mb={2} ml={2}>
                        <Pagination
                          count={pageCount}
                          page={pageIndex}
                          onChange={doPaginationChange}
                          variant="outlined"
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ClientGrowthReport;
