// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
//import useSWR from "swr";
import { useEffect, useState, useRef } from "react";
//import { Token } from "utils/clientFunctions";
import MDButton from "components/MDButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  Tooltip,
} from "@mui/material";
//import { settingDefault } from "utils/clientFunctions";
import { ErrorMessage, Field, Form, Formik } from "formik";
import MDInput from "components/MDInput";
import * as Yup from "yup";
import { updateData } from "utils/clientFunctions";

import { fetchData } from "utils/clientFunctions";
import useSWR from "swr";

import { postData, doUpload } from "utils/clientFunctions";
import { ToastProvider, useToasts } from "react-toast-notifications";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button } from "@mui/material";
import { useNavigate, useParams, Navigate, Link } from "react-router-dom";
import MDAvatar from "components/MDAvatar";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "../../../../src/assets/css/Custom.css";
import { useFormik } from "formik";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IconButton from "@mui/material/IconButton";
import { removeDuplicates } from "utils/clientFunctions";
import { invoiceSchema } from "../components/schemas";
import { useSelector, useDispatch } from "react-redux";
import { getPermissions, selectPermissions } from "reducers/permissionSlice";
import { liveSearchData } from "layouts/commonComponents/liveSearchData";
import { isNumeric, toFixed } from "utils/clientFunctions";
import { dateFormat } from "utils/clientFunctions";
import { ToWords } from "to-words";
import { jsPDF } from "jspdf";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { printDocument } from "utils/clientFunctions";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
function PrintInvoice({ data: invoice, print = false, download = false }) {
  const { addToast } = useToasts();
  const navigate = useNavigate();

  //Start : For permission
  const dispatch = useDispatch();
  const permissions = useSelector(selectPermissions);
  //End : For permission

  useEffect(() => {
    if (print) {
      handlePrint();
    }
    if (download) {
      downloadInvoice();
    }
  }, []);

  const currencyCode = invoice?.br_currency_info?.currency_code;

  const html = invoice?.br_terms_condition;

  const toWords = new ToWords();

  let totalInvoiceAmount = parseFloat(invoice?.total_invoice_amount * 1) || 0;
  let invoiceNumber = invoice?.invoice_number;

  const tableHeader = {
    background: "linear-gradient(195deg, #078149, #078149)",
    color: "#fff",
    padding: "3px",
  };

  //START : Print Invoice
  const printPdfRef = useRef(null);
  const pageStyle = `
  @page {
    size: auto;
    margin: 8mm;
    font-size:50%;
  }
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  } 
`;
  const handlePrint = useReactToPrint({
    content: () => printPdfRef.current,
    pageStyle,
  });
  //END : Print Invoice

  //START : Download Invoice
  const downloadInvoice = async () => {
    await printDocument(printPdfRef.current, `${invoiceNumber}`);
  };
  //END : Download Invoice

  return (
    <DialogContent>
      <Box sx={{ flexGrow: 1 }}>
        <MDBox pt={1} pb={1} ref={printPdfRef} style={{ background: "#fff" }}>
          {/* Logo Header */}
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} md={12}>
              <table style={{ width: "100%" }}>
                <tr>
                  <td style={{ width: "70%" }}>
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td style={{ width: "15%" }}>
                          <MDAvatar
                            src={
                              process.env.REACT_APP_URI_PATH + invoice?.br_logo
                            }
                            name={invoice?.br_logo}
                            size="md"
                            sx={{ width: "250px", height: 100 }}
                            variant="rounded"
                          />
                        </td>
                        <td style={{ width: "85%" }}>
                          <h4
                            style={{
                              marginLeft: "15px",
                              fontSize: "1.5rem",
                            }}
                          >
                            {invoice?.br_name}
                          </h4>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td style={{ width: "30%" }}>
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td>
                          <h4
                            style={{
                              color: "rgb(7, 129, 73)",
                              fontSize: "2.5rem",
                              display: "inline-block",
                              width: "100%",
                            }}
                          >
                            INVOICE
                          </h4>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </Grid>
          </Grid>
          {/* Branch Address */}
          <Grid container spacing={2} mb={1}>
            <Grid item xs={12} md={6}>
              <p>{invoice?.br_address}</p>

              <table style={{ minWidth: "280px" }}>
                {invoice?.br_mobile_number && (
                  <>
                    <tr>
                      <td>Mobile Phone :</td>
                      <td>
                        {invoice?.br_mobile_code_info?.dial_code +
                          " " +
                          invoice?.br_mobile_number}
                      </td>
                    </tr>
                  </>
                )}
                {invoice?.br_landline_number && (
                  <>
                    <tr>
                      <td>Landline Phone :</td>
                      <td>
                        {invoice?.br_landline_code_info?.dial_code +
                          " " +
                          invoice?.br_landline_number}
                      </td>
                    </tr>
                  </>
                )}
                {invoice?.br_website && (
                  <>
                    <tr>
                      <td>Website :</td>
                      <td>{invoice?.br_website}</td>
                    </tr>
                  </>
                )}
              </table>
            </Grid>
            <Grid item xs={12} md={6}></Grid>
          </Grid>
          {/* Bill and Invoice Details */}
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} md={12}>
              <table style={{ width: "100%" }}>
                <tr>
                  <td style={{ width: "50%" }}>
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td>
                          <h4 style={tableHeader}>
                            <b>BILL TO</b>
                          </h4>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td style={{ width: "50%" }}>
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td>
                          <h4 style={tableHeader}>
                            <b>INVOICE DETAILS</b>
                          </h4>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td>{invoice?.bill_name}</td>
                      </tr>

                      <tr>
                        <td>
                          {invoice?.bill_mobile_number_info?.dial_code +
                            " " +
                            invoice?.bill_mobile_number}
                        </td>
                      </tr>
                      <tr>
                        <td>{invoice?.bill_address}</td>
                      </tr>
                      <tr>
                        <td>{invoice?.bill_country_info?.name}</td>
                      </tr>
                    </table>
                  </td>
                  <td
                    style={{
                      width: "50%",
                      verticalAlign: "baseline",
                    }}
                  >
                    <table>
                      <tr>
                        <td> Invoice Date :</td>
                        <td>
                          {invoice?.invoice_date
                            ? dateFormat(invoice?.invoice_date)
                            : "-"}
                        </td>
                      </tr>

                      <tr>
                        <td>Invoice Number :</td>
                        <td>{invoice?.invoice_number}</td>
                      </tr>
                      <tr>
                        <td>Due Date :</td>
                        <td>
                          {invoice?.due_date
                            ? dateFormat(invoice?.due_date)
                            : "-"}
                        </td>
                      </tr>
                      <tr></tr>
                    </table>
                  </td>
                </tr>
              </table>
            </Grid>
          </Grid>

          {/* DESCRIPTION and Products list */}
          <Grid container spacing={2} mb={2}>
            <Grid
              className="borderBottom"
              container
              style={{
                paddingLeft: "16px",
              }}
              xs={12}
              md={12}
            >
              <Grid item xs={12} md={12}>
                <TableContainer sx={{ borderRadius: 0, overflow: "hidden" }}>
                  <Table className="border" style={{ overflow: "hidden" }}>
                    <TableBody style={{ overflow: "hidden" }}>
                      {invoice?.description_list?.map((val, i) => (
                        <>
                          {i == 0 && (
                            <>
                              <TableRow
                                style={{
                                  background:
                                    "linear-gradient(195deg, #078149, #078149)",
                                  color: "#fff !important",
                                  padding: "2px",
                                  marginBottom: "15px",
                                }}
                              >
                                <TableCell
                                  style={{
                                    color: "#fff",
                                    padding: "0.4rem 0.4rem",
                                  }}
                                >
                                  <b>DESCRIPTION</b>
                                </TableCell>
                                <TableCell
                                  style={{
                                    color: "#fff",
                                    padding: "0.4rem 0.4rem",
                                  }}
                                >
                                  <b>UNIT AMOUNT</b>
                                </TableCell>
                                <TableCell
                                  style={{
                                    color: "#fff",
                                    padding: "0.4rem 0.4rem",
                                  }}
                                >
                                  <b>QUANTITY</b>
                                </TableCell>
                                <TableCell
                                  style={{
                                    color: "#fff",
                                    padding: "0.4rem 0.4rem",
                                  }}
                                >
                                  <b>TOTAL AMOUNT</b>
                                </TableCell>
                              </TableRow>
                            </>
                          )}
                          <TableRow>
                            <TableCell colSpan={4}>
                              <b>{val.description}</b>
                            </TableCell>
                          </TableRow>

                          {val?.products_list?.map((product, index) => (
                            <TableRow>
                              <TableCell style={{ width: "40%" }}>
                                {product?.applicant_info
                                  ? product?.applicant_info?.salutation +
                                    " " +
                                    product?.applicant_info?.first_name +
                                    " " +
                                    product?.applicant_info?.last_name +
                                    " (" +
                                    product?.applicant_info?.passport_number +
                                    ")"
                                  : product.other_description
                                  ? product.other_description
                                  : product?.applicant_info
                                  ? product?.applicant_info?.salutation +
                                    " " +
                                    product?.applicant_info?.first_name +
                                    " " +
                                    product?.applicant_info?.last_name
                                  : ""}
                                {product && product.payment_type_name && (
                                  <div>
                                    {"\n"}
                                    {product.payment_type_name}
                                  </div>
                                )}
                              </TableCell>

                              <TableCell style={{ width: "20%" }}>
                                {product.unit_price * 1}
                              </TableCell>
                              <TableCell style={{ width: "20%" }}>
                                {product.quantity * 1}
                              </TableCell>
                              <TableCell style={{ width: "20%" }}>
                                {product.quantity && product.unit_price
                                  ? toFixed(
                                      product.quantity * product.unit_price * 1
                                    )
                                  : ""}
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      ))}
                      {/* <TableRow>
                        <TableCell colSpan={3} align="right">
                          <b>Total :</b>
                        </TableCell>
                        <TableCell>
                          {currencyCode +
                            " " +
                            toFixed(invoice?.sub_total_amount * 1)}
                        </TableCell>
                      </TableRow> */}
                      {/* <TableRow>
                        <TableCell colSpan={3} align="right">
                          <b>Discount Amount :</b>
                        </TableCell>
                        <TableCell>
                          {currencyCode + " "}
                          {isNumeric(invoice?.discount_amount)
                            ? toFixed(invoice?.discount_amount * 1)
                            : 0}
                        </TableCell>
                      </TableRow> */}
                      <TableRow>
                        <TableCell colSpan={3} align="right">
                          <b>Sub Total :</b>
                        </TableCell>
                        <TableCell>
                          {currencyCode + " "}
                          {toFixed(
                            (invoice?.sub_total_amount -
                              invoice?.discount_amount) *
                              1
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={3} align="right">
                          <b>Tax Total :</b>
                        </TableCell>
                        <TableCell>
                          {currencyCode +
                            " " +
                            toFixed(invoice?.tax_amount * 1)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={3}>
                          <p>
                            <b>
                              <i>
                                In words (In {currencyCode}) :{" "}
                                {toWords.convert(totalInvoiceAmount)} Only
                              </i>
                            </b>
                            <b style={{ float: "right" }}>Invoice Total :</b>
                          </p>
                        </TableCell>
                        <TableCell>
                          {currencyCode + " " + toFixed(totalInvoiceAmount)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>

          {/* Comapny A/C and Individual Details */}
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} md={12}>
              <table style={{ width: "100%" }}>
                <tr>
                  <td style={{ width: "33.33%" }}>
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td>
                          <h4 style={tableHeader}>
                            <b>Comapny A/C Details for Bank Transfer</b>
                          </h4>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td style={{ width: "33.33%" }}>
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td>
                          <h4 style={tableHeader}>
                            <b>Individual Details for Transfer via exchange</b>
                          </h4>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td style={{ width: "33.33%" }}>{/* Seal Section */}</td>
                </tr>
                <tr>
                  <td style={{ width: "33.33%" }}>
                    <table style={{ width: "100%" }}>
                      {invoice?.br_bank_name && (
                        <tr>
                          <td>{invoice?.br_bank_name}</td>
                        </tr>
                      )}
                      {invoice?.br_bank_account_number && (
                        <tr>
                          <td>A/C :{invoice?.br_bank_account_number}</td>
                        </tr>
                      )}
                      {invoice?.br_iban_number && (
                        <tr>
                          <td>IBAN : {invoice?.br_iban_number}</td>
                        </tr>
                      )}
                      {invoice?.br_branch_name && (
                        <tr>
                          <td>Branch : {invoice?.br_branch_name}</td>
                        </tr>
                      )}
                      {invoice?.br_swift_code && (
                        <tr>
                          <td>SWIFT : {invoice?.br_swift_code}</td>
                        </tr>
                      )}
                      {invoice?.br_bank_ifsc_code && (
                        <tr>
                          <td>IFSC : {invoice?.br_bank_ifsc_code}</td>
                        </tr>
                      )}
                      {invoice?.br_vat_gst_number && (
                        <tr>
                          <td>
                            {invoice?.br_user_company_info?.tax_type} :{" "}
                            {invoice?.br_vat_gst_number}
                          </td>
                        </tr>
                      )}
                    </table>
                  </td>
                  <td
                    style={{
                      width: "33.33%",
                      verticalAlign: "baseline",
                    }}
                  >
                    <table style={{ width: "100%" }}>
                      {permissions?.first_name && (
                        <tr>
                          <td>
                            {permissions?.first_name +
                              " " +
                              permissions?.last_name}{" "}
                          </td>
                        </tr>
                      )}
                      {permissions?.mobile_number && (
                        <tr>
                          <td>Mobile : {permissions?.mobile_number}</td>
                        </tr>
                      )}
                    </table>
                  </td>
                  <td style={{ width: "33.33%" }}></td>
                </tr>
              </table>
            </Grid>
          </Grid>

          {/* Terms and Conditions and Thanks message */}
          <Grid container spacing={2} mb={4}>
            <Grid item xs={12} md={12}>
              <div
                dangerouslySetInnerHTML={{ __html: html }}
                className="termsData"
              ></div>

              <h2 style={{ textAlign: "center" }}>
                <i>Thank You For Your Business</i>
              </h2>

              <p
                style={{
                  marginTop: "15px",
                  fontSize: "12px",
                  textAlign: "center",
                  fontWeight: 600,
                }}
              >
                This is system generated Invoice and signature is not required
              </p>
            </Grid>
          </Grid>
        </MDBox>
      </Box>
    </DialogContent>
  );
}

export default PrintInvoice;
