// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import { Grid, IconButton } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import { useNavigate } from "react-router-dom";
import { Delete, Edit, RemoveRedEye } from "@mui/icons-material";

function Bill({
  raItem,
  handleOpenDialog,
  index,
  showEditButton,
  showDeleteButton,
}) {
  let {
    ra_id,
    ra_code,
    ra_name,
    email_id,
    logo,
    contact_number,
    receiving_country,
    licence_number,
    landline_number,
    landline_number_code_info,
    supply_country,
  } = raItem;
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <Grid container p={2} spacing={2}>
      <Grid item xs={12} md={12} lg={12}>
        <MDBox
          borderRadius="lg"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={1}
          sx={{
            border: ({ borders: { borderWidth, borderColor } }) =>
              `${borderWidth[1]} solid ${borderColor}`,
          }}
        >
          <MDBox width="100%" display="flex" flexDirection="column">
            <MDBox
              mb={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDTypography variant="caption" fontWeight="bold">
                {index}
              </MDTypography>
              <MDTypography variant="caption" fontWeight="bold">
                {ra_code}
              </MDTypography>
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems={{ xs: "center", sm: "center" }}
              flexDirection={{ xs: "row", sm: "row" }}
              // mb={2}
            >
              <MDBox>
                <MDBox mb={1} lineHeight={0}>
                  <MDTypography variant="caption" fontWeight="bold">
                    {ra_name}
                  </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0}>
                  <MDTypography variant="caption" fontWeight="bold">
                    {licence_number}
                  </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0}>
                  <MDTypography variant="caption" fontWeight="bold">
                    {landline_number_code_info?.dial_code +
                      " " +
                      landline_number}
                  </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0}>
                  <MDTypography variant="caption" fontWeight="bold">
                    <MDAvatar
                      src={`https://flagcdn.com/w40/${supply_country?.code.toLowerCase()}.png`}
                      name={supply_country?.name}
                      size="md"
                      style={{
                        display: "inline-block",
                        overflow: "visible",
                      }}
                      sx={{ width: 20, height: 20 }}
                    />{" "}
                    {supply_country?.name + " "}
                    {/* <Icon variant="gradient" color="info">forwardRef</Icon>
              {" " + receiving_country?.setting_value} */}
                  </MDTypography>
                </MDBox>
              </MDBox>

              <MDAvatar
                src={process.env.REACT_APP_URI_PATH + logo}
                name={ra_name}
                size="md"
                sx={{ width: 56, height: 56, marginLeft: "auto" }}
                variant="rounded"
              />

              <MDBox></MDBox>
            </MDBox>
            <MDBox
              mt={2}
              display="flex"
              justifyContent="space-evenly"
              alignItems="center"
            >
              <MDButton
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => handleOpenDialog(raItem)}
              >
                View
              </MDButton>
              {showEditButton && (
                <MDButton
                  variant="outlined"
                  size="small"
                  color="info"
                  onClick={() => navigate(`/create_ra/${ra_id}`)}
                >
                  Edit
                </MDButton>
              )}
              {showDeleteButton && (
                <MDButton variant="outlined" size="small" color="error">
                  Delete
                </MDButton>
              )}
            </MDBox>
          </MDBox>
        </MDBox>
      </Grid>
    </Grid>
  );
}

// Setting default values for the props of Bill
Bill.defaultProps = {
  noGutter: false,
};

// Typechecking props for the Bill
Bill.propTypes = {
  name: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  vat: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
};

export default Bill;
