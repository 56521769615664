import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { AuthProvider } from "utils/auth";
import { GlobalStyles, createTheme, ThemeProvider } from "@mui/material";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";
import { ToastProvider } from "UI/snakToast/ToastProvider";

import { Provider } from "react-redux";
import { store } from "./store";

const container = document.getElementById("app");
const root = createRoot(container);

const theme = createTheme({
  components: {
    MuiInputBase: {
      defaultProps: {
        disableInjectingGlobalStyles: true,
      },
    },
  },
});

root.render(
  <ThemeProvider theme={theme}>
    <GlobalStyles
      styles={{
        "@keyframes mui-auto-fill": { from: { display: "block" } },
        "@keyframes mui-auto-fill-cancel": { from: { display: "block" } },
      }}
    />
    <BrowserRouter>
      <AuthProvider>
        <MaterialUIControllerProvider>
          <ToastProvider>
            <Provider store={store}>
              <App />
            </Provider>
          </ToastProvider>
        </MaterialUIControllerProvider>
      </AuthProvider>
    </BrowserRouter>
  </ThemeProvider>
);
