import { useEffect } from "react";
export const UpdateState = (permissions, setPermissions) => {
  //Settings
  useEffect(() => {
    if (permissions.setAll === "On") {
      setPermissions({
        ...permissions,
        ["setView"]: "On",
        ["setCreate"]: "On",
        ["setEdit"]: "On",
        ["setDelete"]: "On",
      });
    }
    let newPer = permissions.setAll === "On" ? "On" : "Off";
    if (
      permissions.setView === "On" &&
      permissions.setCreate === "On" &&
      permissions.setEdit === "On" &&
      permissions.setDelete === "On"
    ) {
      setPermissions({
        ...permissions,
        ["setView"]: newPer,
        ["setCreate"]: newPer,
        ["setEdit"]: newPer,
        ["setDelete"]: newPer,
      });
    }
  }, [permissions.setAll]);
  useEffect(() => {
    let allPer;
    if (
      permissions.setView === "On" &&
      permissions.setCreate === "On" &&
      permissions.setEdit === "On" &&
      permissions.setDelete === "On"
    ) {
      allPer = "On";
    } else if (
      permissions.setView === "Off" ||
      permissions.setCreate === "Off" ||
      permissions.setEdit === "Off" ||
      permissions.setDelete === "Off"
    ) {
      allPer = "Off";
    }
    if (allPer) {
      setPermissions({
        ...permissions,
        ["setAll"]: allPer,
      });
    }
  }, [
    permissions.setView,
    permissions.setCreate,
    permissions.setEdit,
    permissions.setDelete,
  ]);

  //Users
  useEffect(() => {
    if (permissions.usrAll === "On") {
      setPermissions({
        ...permissions,
        ["usrView"]: "On",
        ["usrCreate"]: "On",
        ["usrEdit"]: "On",
        ["usrDelete"]: "On",
      });
    }
    let newPer = permissions.usrAll === "On" ? "On" : "Off";
    if (
      permissions.usrView === "On" &&
      permissions.usrCreate === "On" &&
      permissions.usrEdit === "On" &&
      permissions.usrDelete === "On"
    ) {
      setPermissions({
        ...permissions,
        ["usrView"]: newPer,
        ["usrCreate"]: newPer,
        ["usrEdit"]: newPer,
        ["usrDelete"]: newPer,
      });
    }
  }, [permissions.usrAll]);
  useEffect(() => {
    let allPer;
    if (
      permissions.usrView === "On" &&
      permissions.usrCreate === "On" &&
      permissions.usrEdit === "On" &&
      permissions.usrDelete === "On"
    ) {
      allPer = "On";
    } else if (
      permissions.usrView === "Off" ||
      permissions.usrCreate === "Off" ||
      permissions.usrEdit === "Off" ||
      permissions.usrDelete === "Off"
    ) {
      allPer = "Off";
    }
    if (allPer) {
      setPermissions({
        ...permissions,
        ["usrAll"]: allPer,
      });
    }
  }, [
    permissions.usrView,
    permissions.usrCreate,
    permissions.usrEdit,
    permissions.usrDelete,
  ]);

  //Tasks
  useEffect(() => {
    if (permissions.tskAll === "On") {
      setPermissions({
        ...permissions,
        ["tskView"]: "On",
        ["tskCreate"]: "On",
        ["tskEdit"]: "On",
        ["tskDelete"]: "On",
      });
    }
    let newPer = permissions.tskAll === "On" ? "On" : "Off";
    if (
      permissions.tskView === "On" &&
      permissions.tskCreate === "On" &&
      permissions.tskEdit === "On" &&
      permissions.tskDelete === "On"
    ) {
      setPermissions({
        ...permissions,
        ["tskView"]: newPer,
        ["tskCreate"]: newPer,
        ["tskEdit"]: newPer,
        ["tskDelete"]: newPer,
      });
    }
  }, [permissions.tskAll]);
  useEffect(() => {
    let allPer;
    if (
      permissions.tskView === "On" &&
      permissions.tskCreate === "On" &&
      permissions.tskEdit === "On" &&
      permissions.tskDelete === "On"
    ) {
      allPer = "On";
    } else if (
      permissions.tskView === "Off" ||
      permissions.tskCreate === "Off" ||
      permissions.tskEdit === "Off" ||
      permissions.tskDelete === "Off"
    ) {
      allPer = "Off";
    }
    if (allPer) {
      setPermissions({
        ...permissions,
        ["tskAll"]: allPer,
      });
    }
  }, [
    permissions.tskView,
    permissions.tskCreate,
    permissions.tskEdit,
    permissions.tskDelete,
  ]);

  //Tax
  useEffect(() => {
    if (permissions.taxAll === "On") {
      setPermissions({
        ...permissions,
        ["taxView"]: "On",
        ["taxCreate"]: "On",
        ["taxEdit"]: "On",
        ["taxDelete"]: "On",
      });
    }
    let newPer = permissions.taxAll === "On" ? "On" : "Off";
    if (
      permissions.taxView === "On" &&
      permissions.taxCreate === "On" &&
      permissions.taxEdit === "On" &&
      permissions.taxDelete === "On"
    ) {
      setPermissions({
        ...permissions,
        ["taxView"]: newPer,
        ["taxCreate"]: newPer,
        ["taxEdit"]: newPer,
        ["taxDelete"]: newPer,
      });
    }
  }, [permissions.taxAll]);
  useEffect(() => {
    let allPer;
    if (
      permissions.taxView === "On" &&
      permissions.taxCreate === "On" &&
      permissions.taxEdit === "On" &&
      permissions.taxDelete === "On"
    ) {
      allPer = "On";
    } else if (
      permissions.taxView === "Off" ||
      permissions.taxCreate === "Off" ||
      permissions.taxEdit === "Off" ||
      permissions.taxDelete === "Off"
    ) {
      allPer = "Off";
    }
    if (allPer) {
      setPermissions({
        ...permissions,
        ["taxAll"]: allPer,
      });
    }
  }, [
    permissions.taxView,
    permissions.taxCreate,
    permissions.taxEdit,
    permissions.taxDelete,
  ]);

  //Deployment
  useEffect(() => {
    if (permissions.depAll === "On") {
      setPermissions({
        ...permissions,
        ["depView"]: "On",
        ["depCreate"]: "On",
        ["depEdit"]: "On",
        ["depDelete"]: "On",
      });
    }
    let newPer = permissions.depAll === "On" ? "On" : "Off";
    if (
      permissions.depView === "On" &&
      permissions.depCreate === "On" &&
      permissions.depEdit === "On" &&
      permissions.depDelete === "On"
    ) {
      setPermissions({
        ...permissions,
        ["depView"]: newPer,
        ["depCreate"]: newPer,
        ["depEdit"]: newPer,
        ["depDelete"]: newPer,
      });
    }
  }, [permissions.depAll]);
  useEffect(() => {
    let allPer;
    if (
      permissions.depView === "On" &&
      permissions.depCreate === "On" &&
      permissions.depEdit === "On" &&
      permissions.depDelete === "On"
    ) {
      allPer = "On";
    } else if (
      permissions.depView === "Off" ||
      permissions.depCreate === "Off" ||
      permissions.depEdit === "Off" ||
      permissions.depDelete === "Off"
    ) {
      allPer = "Off";
    }
    if (allPer) {
      setPermissions({
        ...permissions,
        ["depAll"]: allPer,
      });
    }
  }, [
    permissions.depView,
    permissions.depCreate,
    permissions.depEdit,
    permissions.depDelete,
  ]);

  //Attestation
  useEffect(() => {
    if (permissions.attAll === "On") {
      setPermissions({
        ...permissions,
        ["attView"]: "On",
        ["attCreate"]: "On",
        ["attEdit"]: "On",
        ["attDelete"]: "On",
      });
    }
    let newPer = permissions.attAll === "On" ? "On" : "Off";
    if (
      permissions.attView === "On" &&
      permissions.attCreate === "On" &&
      permissions.attEdit === "On" &&
      permissions.attDelete === "On"
    ) {
      setPermissions({
        ...permissions,
        ["attView"]: newPer,
        ["attCreate"]: newPer,
        ["attEdit"]: newPer,
        ["attDelete"]: newPer,
      });
    }
  }, [permissions.attAll]);
  useEffect(() => {
    let allPer;
    if (
      permissions.attView === "On" &&
      permissions.attCreate === "On" &&
      permissions.attEdit === "On" &&
      permissions.attDelete === "On"
    ) {
      allPer = "On";
    } else if (
      permissions.attView === "Off" ||
      permissions.attCreate === "Off" ||
      permissions.attEdit === "Off" ||
      permissions.attDelete === "Off"
    ) {
      allPer = "Off";
    }
    if (allPer) {
      setPermissions({
        ...permissions,
        ["attAll"]: allPer,
      });
    }
  }, [
    permissions.attView,
    permissions.attCreate,
    permissions.attEdit,
    permissions.attDelete,
  ]);

  //Interview
  useEffect(() => {
    if (permissions.intAll === "On") {
      setPermissions({
        ...permissions,
        ["intView"]: "On",
        ["intCreate"]: "On",
        ["intEdit"]: "On",
        ["intDelete"]: "On",
      });
    }
    let newPer = permissions.intAll === "On" ? "On" : "Off";
    if (
      permissions.intView === "On" &&
      permissions.intCreate === "On" &&
      permissions.intEdit === "On" &&
      permissions.intDelete === "On"
    ) {
      setPermissions({
        ...permissions,
        ["intView"]: newPer,
        ["intCreate"]: newPer,
        ["intEdit"]: newPer,
        ["intDelete"]: newPer,
      });
    }
  }, [permissions.intAll]);
  useEffect(() => {
    let allPer;
    if (
      permissions.intView === "On" &&
      permissions.intCreate === "On" &&
      permissions.intEdit === "On" &&
      permissions.intDelete === "On"
    ) {
      allPer = "On";
    } else if (
      permissions.intView === "Off" ||
      permissions.intCreate === "Off" ||
      permissions.intEdit === "Off" ||
      permissions.intDelete === "Off"
    ) {
      allPer = "Off";
    }
    if (allPer) {
      setPermissions({
        ...permissions,
        ["intAll"]: allPer,
      });
    }
  }, [
    permissions.intView,
    permissions.intCreate,
    permissions.intEdit,
    permissions.intDelete,
  ]);

  //Booking
  useEffect(() => {
    if (permissions.bokAll === "On") {
      setPermissions({
        ...permissions,
        ["bokView"]: "On",
        ["bokCreate"]: "On",
        ["bokEdit"]: "On",
        ["bokDelete"]: "On",
      });
    }
    let newPer = permissions.bokAll === "On" ? "On" : "Off";
    if (
      permissions.bokView === "On" &&
      permissions.bokCreate === "On" &&
      permissions.bokEdit === "On" &&
      permissions.bokDelete === "On"
    ) {
      setPermissions({
        ...permissions,
        ["bokView"]: newPer,
        ["bokCreate"]: newPer,
        ["bokEdit"]: newPer,
        ["bokDelete"]: newPer,
      });
    }
  }, [permissions.bokAll]);
  useEffect(() => {
    let allPer;
    if (
      permissions.bokView === "On" &&
      permissions.bokCreate === "On" &&
      permissions.bokEdit === "On" &&
      permissions.bokDelete === "On"
    ) {
      allPer = "On";
    } else if (
      permissions.bokView === "Off" ||
      permissions.bokCreate === "Off" ||
      permissions.bokEdit === "Off" ||
      permissions.bokDelete === "Off"
    ) {
      allPer = "Off";
    }
    if (allPer) {
      setPermissions({
        ...permissions,
        ["bokAll"]: allPer,
      });
    }
  }, [
    permissions.bokView,
    permissions.bokCreate,
    permissions.bokEdit,
    permissions.bokDelete,
  ]);

  //Call Report
  useEffect(() => {
    if (permissions.clrAll === "On") {
      setPermissions({
        ...permissions,
        ["clrView"]: "On",
        ["clrCreate"]: "On",
        ["clrEdit"]: "On",
        ["clrDelete"]: "On",
      });
    }
    let newPer = permissions.clrAll === "On" ? "On" : "Off";
    if (
      permissions.clrView === "On" &&
      permissions.clrCreate === "On" &&
      permissions.clrEdit === "On" &&
      permissions.clrDelete === "On"
    ) {
      setPermissions({
        ...permissions,
        ["clrView"]: newPer,
        ["clrCreate"]: newPer,
        ["clrEdit"]: newPer,
        ["clrDelete"]: newPer,
      });
    }
  }, [permissions.clrAll]);
  useEffect(() => {
    let allPer;
    if (
      permissions.clrView === "On" &&
      permissions.clrCreate === "On" &&
      permissions.clrEdit === "On" &&
      permissions.clrDelete === "On"
    ) {
      allPer = "On";
    } else if (
      permissions.clrView === "Off" ||
      permissions.clrCreate === "Off" ||
      permissions.clrEdit === "Off" ||
      permissions.clrDelete === "Off"
    ) {
      allPer = "Off";
    }
    if (allPer) {
      setPermissions({
        ...permissions,
        ["clrAll"]: allPer,
      });
    }
  }, [
    permissions.clrView,
    permissions.clrCreate,
    permissions.clrEdit,
    permissions.clrDelete,
  ]);

  //Deals
  useEffect(() => {
    if (permissions.delAll === "On") {
      setPermissions({
        ...permissions,
        ["delView"]: "On",
        ["delCreate"]: "On",
        ["delEdit"]: "On",
        ["delDelete"]: "On",
      });
    }
    let newPer = permissions.delAll === "On" ? "On" : "Off";
    if (
      permissions.delView === "On" &&
      permissions.delCreate === "On" &&
      permissions.delEdit === "On" &&
      permissions.delDelete === "On"
    ) {
      setPermissions({
        ...permissions,
        ["delView"]: newPer,
        ["delCreate"]: newPer,
        ["delEdit"]: newPer,
        ["delDelete"]: newPer,
      });
    }
  }, [permissions.delAll]);
  useEffect(() => {
    let allPer;
    if (
      permissions.delView === "On" &&
      permissions.delCreate === "On" &&
      permissions.delEdit === "On" &&
      permissions.delDelete === "On"
    ) {
      allPer = "On";
    } else if (
      permissions.delView === "Off" ||
      permissions.delCreate === "Off" ||
      permissions.delEdit === "Off" ||
      permissions.delDelete === "Off"
    ) {
      allPer = "Off";
    }
    if (allPer) {
      setPermissions({
        ...permissions,
        ["delAll"]: allPer,
      });
    }
  }, [
    permissions.delView,
    permissions.delCreate,
    permissions.delEdit,
    permissions.delDelete,
  ]);

  //Meetings
  useEffect(() => {
    if (permissions.metAll === "On") {
      setPermissions({
        ...permissions,
        ["metView"]: "On",
        ["metCreate"]: "On",
        ["metEdit"]: "On",
        ["metDelete"]: "On",
      });
    }
    let newPer = permissions.metAll === "On" ? "On" : "Off";
    if (
      permissions.metView === "On" &&
      permissions.metCreate === "On" &&
      permissions.metEdit === "On" &&
      permissions.metDelete === "On"
    ) {
      setPermissions({
        ...permissions,
        ["metView"]: newPer,
        ["metCreate"]: newPer,
        ["metEdit"]: newPer,
        ["metDelete"]: newPer,
      });
    }
  }, [permissions.metAll]);
  useEffect(() => {
    let allPer;
    if (
      permissions.metView === "On" &&
      permissions.metCreate === "On" &&
      permissions.metEdit === "On" &&
      permissions.metDelete === "On"
    ) {
      allPer = "On";
    } else if (
      permissions.metView === "Off" ||
      permissions.metCreate === "Off" ||
      permissions.metEdit === "Off" ||
      permissions.metDelete === "Off"
    ) {
      allPer = "Off";
    }
    if (allPer) {
      setPermissions({
        ...permissions,
        ["metAll"]: allPer,
      });
    }
  }, [
    permissions.metView,
    permissions.metCreate,
    permissions.metEdit,
    permissions.metDelete,
  ]);

  //Accounts
  useEffect(() => {
    if (permissions.accAll === "On") {
      setPermissions({
        ...permissions,
        ["accView"]: "On",
        ["accCreate"]: "On",
        ["accEdit"]: "On",
        ["accDelete"]: "On",
      });
    }
    let newPer = permissions.accAll === "On" ? "On" : "Off";
    if (
      permissions.accView === "On" &&
      permissions.accCreate === "On" &&
      permissions.accEdit === "On" &&
      permissions.accDelete === "On"
    ) {
      setPermissions({
        ...permissions,
        ["accView"]: newPer,
        ["accCreate"]: newPer,
        ["accEdit"]: newPer,
        ["accDelete"]: newPer,
      });
    }
  }, [permissions.accAll]);
  useEffect(() => {
    let allPer;
    if (
      permissions.accView === "On" &&
      permissions.accCreate === "On" &&
      permissions.accEdit === "On" &&
      permissions.accDelete === "On"
    ) {
      allPer = "On";
    } else if (
      permissions.accView === "Off" ||
      permissions.accCreate === "Off" ||
      permissions.accEdit === "Off" ||
      permissions.accDelete === "Off"
    ) {
      allPer = "Off";
    }
    if (allPer) {
      setPermissions({
        ...permissions,
        ["accAll"]: allPer,
      });
    }
  }, [
    permissions.accView,
    permissions.accCreate,
    permissions.accEdit,
    permissions.accDelete,
  ]);

  //Leads
  useEffect(() => {
    if (permissions.ledAll === "On") {
      setPermissions({
        ...permissions,
        ["ledView"]: "On",
        ["ledCreate"]: "On",
        ["ledEdit"]: "On",
        ["ledDelete"]: "On",
      });
    }
    let newPer = permissions.ledAll === "On" ? "On" : "Off";
    if (
      permissions.ledView === "On" &&
      permissions.ledCreate === "On" &&
      permissions.ledEdit === "On" &&
      permissions.ledDelete === "On"
    ) {
      setPermissions({
        ...permissions,
        ["ledView"]: newPer,
        ["ledCreate"]: newPer,
        ["ledEdit"]: newPer,
        ["ledDelete"]: newPer,
      });
    }
  }, [permissions.ledAll]);
  useEffect(() => {
    let allPer;
    if (
      permissions.ledView === "On" &&
      permissions.ledCreate === "On" &&
      permissions.ledEdit === "On" &&
      permissions.ledDelete === "On"
    ) {
      allPer = "On";
    } else if (
      permissions.ledView === "Off" ||
      permissions.ledCreate === "Off" ||
      permissions.ledEdit === "Off" ||
      permissions.ledDelete === "Off"
    ) {
      allPer = "Off";
    }
    if (allPer) {
      setPermissions({
        ...permissions,
        ["ledAll"]: allPer,
      });
    }
  }, [
    permissions.ledView,
    permissions.ledCreate,
    permissions.ledEdit,
    permissions.ledDelete,
  ]);

  // Applicants
  useEffect(() => {
    if (permissions.appAll === "On") {
      setPermissions({
        ...permissions,
        ["appView"]: "On",
        ["appCreate"]: "On",
        ["appEdit"]: "On",
        ["appDelete"]: "On",
      });
    }
    let newPer = permissions.appAll === "On" ? "On" : "Off";
    if (
      permissions.appView === "On" &&
      permissions.appCreate === "On" &&
      permissions.appEdit === "On" &&
      permissions.appDelete === "On"
    ) {
      setPermissions({
        ...permissions,
        ["appView"]: newPer,
        ["appCreate"]: newPer,
        ["appEdit"]: newPer,
        ["appDelete"]: newPer,
      });
    }
  }, [permissions.appAll]);
  useEffect(() => {
    let allPer;
    if (
      permissions.appView === "On" &&
      permissions.appCreate === "On" &&
      permissions.appEdit === "On" &&
      permissions.appDelete === "On"
    ) {
      allPer = "On";
    } else if (
      permissions.appView === "Off" ||
      permissions.appCreate === "Off" ||
      permissions.appEdit === "Off" ||
      permissions.appDelete === "Off"
    ) {
      allPer = "Off";
    }
    if (allPer) {
      setPermissions({
        ...permissions,
        ["appAll"]: allPer,
      });
    }
  }, [
    permissions.appView,
    permissions.appCreate,
    permissions.appEdit,
    permissions.appDelete,
  ]);

  //Company
  useEffect(() => {
    if (permissions.cmpAll === "On") {
      setPermissions({
        ...permissions,
        ["cmpView"]: "On",
        ["cmpCreate"]: "On",
        ["cmpEdit"]: "On",
        ["cmpDelete"]: "On",
      });
    }
    let newPer = permissions.cmpAll === "On" ? "On" : "Off";
    if (
      permissions.cmpView === "On" &&
      permissions.cmpCreate === "On" &&
      permissions.cmpEdit === "On" &&
      permissions.cmpDelete === "On"
    ) {
      setPermissions({
        ...permissions,
        ["cmpView"]: newPer,
        ["cmpCreate"]: newPer,
        ["cmpEdit"]: newPer,
        ["cmpDelete"]: newPer,
      });
    }
  }, [permissions.cmpAll]);

  useEffect(() => {
    let allPer;
    if (
      permissions.cmpView === "On" &&
      permissions.cmpCreate === "On" &&
      permissions.cmpEdit === "On" &&
      permissions.cmpDelete === "On"
    ) {
      allPer = "On";
    } else if (
      permissions.cmpView === "Off" ||
      permissions.cmpCreate === "Off" ||
      permissions.cmpEdit === "Off" ||
      permissions.cmpDelete === "Off"
    ) {
      allPer = "Off";
    }
    if (allPer) {
      setPermissions({
        ...permissions,
        ["cmpAll"]: allPer,
      });
    }
  }, [
    permissions.cmpView,
    permissions.cmpCreate,
    permissions.cmpEdit,
    permissions.cmpDelete,
  ]);

  //Recruiting Agency
  useEffect(() => {
    if (permissions.raAll === "On") {
      setPermissions({
        ...permissions,
        ["raView"]: "On",
        ["raCreate"]: "On",
        ["raEdit"]: "On",
        ["raDelete"]: "On",
      });
    }
    let newPer = permissions.raAll === "On" ? "On" : "Off";
    if (
      permissions.raView === "On" &&
      permissions.raCreate === "On" &&
      permissions.raEdit === "On" &&
      permissions.raDelete === "On"
    ) {
      setPermissions({
        ...permissions,
        ["raView"]: newPer,
        ["raCreate"]: newPer,
        ["raEdit"]: newPer,
        ["raDelete"]: newPer,
      });
    }
  }, [permissions.raAll]);

  useEffect(() => {
    let allPer;
    if (
      permissions.raView === "On" &&
      permissions.raCreate === "On" &&
      permissions.raEdit === "On" &&
      permissions.raDelete === "On"
    ) {
      allPer = "On";
    } else if (
      permissions.raView === "Off" ||
      permissions.raCreate === "Off" ||
      permissions.raEdit === "Off" ||
      permissions.raDelete === "Off"
    ) {
      allPer = "Off";
    }
    if (allPer) {
      setPermissions({
        ...permissions,
        ["raAll"]: allPer,
      });
    }
  }, [
    permissions.raView,
    permissions.raCreate,
    permissions.raEdit,
    permissions.raDelete,
  ]);

  // Dashboard Recruiting Agency
  useEffect(() => {
    setPermissions({
      ...permissions,
      ["dshRaView"]: permissions.dshRaAll === "On" ? "On" : "Off",
    });
  }, [permissions.dshRaAll]);

  useEffect(() => {
    setPermissions({
      ...permissions,
      ["dshRaAll"]: permissions.dshRaView === "On" ? "On" : "Off",
    });
  }, [permissions.dshRaView]);

  // Dashboard Company
  useEffect(() => {
    setPermissions({
      ...permissions,
      ["dshCmpView"]: permissions.dshCmpAll === "On" ? "On" : "Off",
    });
  }, [permissions.dshCmpAll]);
  useEffect(() => {
    setPermissions({
      ...permissions,
      ["dshCmpAll"]: permissions.dshCmpView === "On" ? "On" : "Off",
    });
  }, [permissions.dshCmpView]);

  // Dashboard Applicants
  useEffect(() => {
    setPermissions({
      ...permissions,
      ["dshAppView"]: permissions.dshAppAll === "On" ? "On" : "Off",
    });
  }, [permissions.dshAppAll]);
  useEffect(() => {
    setPermissions({
      ...permissions,
      ["dshAppAll"]: permissions.dshAppView === "On" ? "On" : "Off",
    });
  }, [permissions.dshAppView]);

  // Dashboard Accounts
  useEffect(() => {
    setPermissions({
      ...permissions,
      ["dshAccView"]: permissions.dshAccAll === "On" ? "On" : "Off",
    });
  }, [permissions.dshAccAll]);
  useEffect(() => {
    setPermissions({
      ...permissions,
      ["dshAccAll"]: permissions.dshAccView === "On" ? "On" : "Off",
    });
  }, [permissions.dshAccView]);

  // Dashboard Upcoming meetings
  useEffect(() => {
    setPermissions({
      ...permissions,
      ["dshUpMetView"]: permissions.dshUpMetAll === "On" ? "On" : "Off",
    });
  }, [permissions.dshUpMetAll]);
  useEffect(() => {
    setPermissions({
      ...permissions,
      ["dshUpMetAll"]: permissions.dshUpMetView === "On" ? "On" : "Off",
    });
  }, [permissions.dshUpMetView]);

  // Dashboard Upcoming Task
  useEffect(() => {
    setPermissions({
      ...permissions,
      ["dshUpTskView"]: permissions.dshUpTskAll === "On" ? "On" : "Off",
    });
  }, [permissions.dshUpTskAll]);
  useEffect(() => {
    setPermissions({
      ...permissions,
      ["dshUpTskAll"]: permissions.dshUpTskView === "On" ? "On" : "Off",
    });
  }, [permissions.dshUpTskView]);

  // Lock
  useEffect(() => {
    if (permissions.lockAll === "On") {
      setPermissions({
        ...permissions,
        ["lockView"]: "On",
        ["lockCreate"]: "On",
      });
    }
    let newPer = permissions.lockAll === "On" ? "On" : "Off";
    if (permissions.lockView === "On" && permissions.lockCreate === "On") {
      setPermissions({
        ...permissions,
        ["lockView"]: newPer,
        ["lockCreate"]: newPer,
      });
    }
  }, [permissions.lockAll]);
  useEffect(() => {
    let allPer;
    if (permissions.lockView === "On" && permissions.lockCreate === "On") {
      allPer = "On";
    } else if (
      permissions.lockView === "Off" ||
      permissions.lockCreate === "Off"
    ) {
      allPer = "Off";
    }
    if (allPer) {
      setPermissions({
        ...permissions,
        ["lockAll"]: allPer,
      });
    }
  }, [permissions.lockView, permissions.lockCreate]);
};
