import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postData } from "utils/clientFunctions";

export const getPermissions = createAsyncThunk("user/verify", async () => {
  try {
    let response = await postData(`user/verify`);
    return response?.data;
  } catch (error) {
    console.error(error);
  }
});

export const permissionSlice = createSlice({
  name: "permissionList",
  initialState: {
    permissions: {},
    isLoading: false,
    hasError: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPermissions.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(getPermissions.fulfilled, (state, action) => {
        state.permissions = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(getPermissions.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      });
  },
});

export const selectPermissions = (state) => state.permissionList.permissions;
export const selectLoadingState = (state) => state.permissionList.isLoading;
export const selectErrorState = (state) => state.permissionList.hasError;

export default permissionSlice.reducer;
