// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import { Grid, IconButton } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import { useNavigate } from "react-router-dom";
import { Delete, Edit, RemoveRedEye } from "@mui/icons-material";
import { dateTimeFormat } from "utils/clientFunctions";
import { dateFormat } from "utils/clientFunctions";
import { firstLetterCapital } from "utils/clientFunctions";

function MobileList({
  attestationItem,
  handleOpenDialog,
  index,
  showEditButton,
  showDeleteButton,
}) {
  let {
    attestation_id,
    attestation_date,
    attestation_type_value,
    attestation_fees,
    vendor_name,
    paying_type,
    status_value,
    country_currency_code,
  } = attestationItem;
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <Grid container p={2} spacing={2}>
      <Grid item xs={12} md={12} lg={12}>
        <MDBox
          borderRadius="lg"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={1}
          sx={{
            border: ({ borders: { borderWidth, borderColor } }) =>
              `${borderWidth[1]} solid ${borderColor}`,
          }}
        >
          <MDBox width="100%" display="flex" flexDirection="column">
            <MDBox
              mb={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDTypography variant="caption" fontWeight="bold">
                {index}
              </MDTypography>
              <MDTypography variant="caption" fontWeight="bold">
                {dateFormat(attestation_date)}
              </MDTypography>
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems={{ xs: "center", sm: "center" }}
              flexDirection={{ xs: "row", sm: "row" }}
              // mb={2}
            >
              <MDBox>
                <MDBox mb={1} lineHeight={0}>
                  <MDTypography variant="caption" fontWeight="bold">
                    Type : {attestation_type_value}
                  </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0}>
                  <MDTypography variant="caption" fontWeight="bold">
                    Fees : {country_currency_code + " " + attestation_fees * 1}
                  </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0}>
                  <MDTypography variant="caption" fontWeight="bold">
                    Paid By :{" "}
                    {paying_type === "ra"
                      ? "Recruiting Agency"
                      : firstLetterCapital(paying_type)}
                  </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0}>
                  <MDTypography variant="caption" fontWeight="bold">
                    Status : {status_value}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </MDBox>
            <MDBox
              mt={2}
              display="flex"
              justifyContent="space-evenly"
              alignItems="center"
            >
              <MDButton
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => handleOpenDialog(attestationItem)}
              >
                View
              </MDButton>
              {showEditButton && (
                <MDButton
                  variant="outlined"
                  size="small"
                  color="info"
                  onClick={() => navigate(`/attestation/${attestation_id}`)}
                >
                  Edit
                </MDButton>
              )}
              {showDeleteButton && (
                <MDButton variant="outlined" size="small" color="error">
                  Delete
                </MDButton>
              )}
            </MDBox>
          </MDBox>
        </MDBox>
      </Grid>
    </Grid>
  );
}

// Setting default values for the props of Bill
MobileList.defaultProps = {
  noGutter: false,
};

export default MobileList;
