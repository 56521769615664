// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { fetchData } from "utils/clientFunctions";
import useSWR from "swr";
import { useEffect, useRef, useState } from "react";
import { Token } from "utils/clientFunctions";
import MDButton from "components/MDButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  Tooltip,
} from "@mui/material";
import { settingDefault } from "utils/clientFunctions";
import { ErrorMessage, Field, Form, Formik } from "formik";
import MDInput from "components/MDInput";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MDAvatar from "components/MDAvatar";
import { Delete, Edit, RemoveRedEye, TramSharp } from "@mui/icons-material";
import { useAuth } from "utils/auth";
import pxToRem from "assets/theme/functions/pxToRem";
import MobileList from "../components/mobileList";
import { dateFormat } from "utils/clientFunctions";
import { CardTopBoxAdd } from "layouts/commonComponents/cardTopBoxAdd";
import { dateTimeFormat } from "utils/clientFunctions";
import { useSelector, useDispatch } from "react-redux";
import { getPermissions, selectPermissions } from "reducers/permissionSlice";
import { firstLetterCapital } from "utils/clientFunctions";

import { liveSearchData } from "layouts/commonComponents/liveSearchData";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { CSVDownload, CSVLink } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";
import { dateTimeStamp } from "utils/clientFunctions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function AttestationList() {
  const csvLink = useRef();
  const [pageIndex, setPageIndex] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [pageResize, setPageResize] = useState(
    window.innerWidth < 992 ? true : false
  );
  const navigate = useNavigate();

  //Start : For permission
  const dispatch = useDispatch();
  var permissions = useSelector(selectPermissions);
  if (
    !(
      permissions?.user_id === 1 || permissions?.permission?.includes("attView")
    )
  ) {
    navigate("/dashboard");
  }
  let showAddButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("attCreate")
      ? true
      : false;
  let showEditButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("attEdit")
      ? true
      : false;
  let showDeleteButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("attDelete")
      ? true
      : false;
  useEffect(() => {
    dispatch(getPermissions());
  }, []);
  //End : For permission

  const [attestationList, setAttestationList] = useState([]);

  //View More RA data
  const [attestationData, setattestationData] = useState({});

  const [attestationInfo, setAttestationInfo] = useState([]);
  const { logout } = useAuth();

  const [applicantList, setApplicantList] = useState([]);
  const [attestationTypeList, setAttestationTypeList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [usersList, setUserList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [raList, setRaList] = useState([]);

  const initialLoadData = async () => {
    let queryUrl = getQueryParams();
    const url = `attestation/list${queryUrl}`;
    const data = await fetchData(url);
    if (
      data?.name === "JsonWebTokenError" ||
      data?.name === "TokenExpiredError" ||
      data?.message === "You are not authorized"
    ) {
      logout();
    }
    if (data && data.data) {
      let attestationCount = Math.ceil(data.count / 10);
      setPageCount(attestationCount);
      setAttestationList(data.data || []);
    }
  };

  useEffect(() => {
    //Get attestationType List
    const attestationTypeUrl = `setting/type/15`;
    getFetchData(attestationTypeUrl).then((res) => {
      setAttestationTypeList(res.data);
    });

    //Get company list
    const companyApiUrl = `company/list?page=1`;
    getFetchData(companyApiUrl).then((res) => {
      setCompanyList(res.data);
    });

    const applicantUrl = `applicant/list?page=1`;
    getFetchData(applicantUrl).then((res) => {
      setApplicantList(res.data);
    });

    const raUrl = `recruiting_agency/list?page=1`;
    getFetchData(raUrl).then((res) => {
      setRaList(res.data);
    });

    //Get status list
    const statusUrl = `setting/type/8`;
    getFetchData(statusUrl).then((res) => {
      setStatusList(res.data);
    });
  }, []);
  const searchSpot = (value, key) => {
    liveSearchData({
      countryId: null,
      value,
      key,
      setRaList,
      setCompanyList,
      setApplicantList,
      setUserList,
    });
  };

  //Search & Filter initial data
  const [searchFormData, setSearchFormData] = useState({
    vendor_type: "",
    vendor_type_id: "",
    attestation_type_id: "",
    attestation_date: "",
    status_id: "",
  });
  useEffect(() => {
    initialLoadData();
  }, [
    pageIndex,
    searchFormData.vendor_type,
    searchFormData.vendor_type_id,
    searchFormData.attestation_type_id,
    searchFormData.attestation_date,
    searchFormData.status_id,
  ]);

  const doPaginationChange = (event, value) => {
    setPageIndex(value);
  };

  //Dialog popup
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = async (callReportInfo) => {
    await getAttestationInfo(callReportInfo.attestation_id); // Get applicant info
    setattestationData(callReportInfo);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setattestationData({});
    setOpenDialog(false);
  };

  window.addEventListener("resize", () => {
    if (window.innerWidth < 992) {
      setPageResize(true);
    } else {
      setPageResize(false);
    }
  });

  const getFetchData = async (settingUrl) => {
    return await fetchData(settingUrl);
  };

  //Get the source lead Name : Comment as of now due to popup design loading issue 12 Feb 2024
  const getAttestationInfo = async (attestationId) => {
    const res = await getFetchData(`attestation/${attestationId}`);
    setAttestationInfo(res);
  };

  const displayTypoInfo = (label, value) => {
    return (
      <>
        <Grid item xs={6} md={6} lg={6}>
          <MDTypography variant="h6" fontWeight="medium">
            {label}
          </MDTypography>
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <MDTypography variant="h6" fontWeight="regular">
            {value}
          </MDTypography>
        </Grid>
      </>
    );
  };

  const getCountryFlag = (countryInfo) => {
    return (
      <>
        <MDTypography variant="h6" fontWeight="regular">
          <MDAvatar
            src={`https://flagcdn.com/w40/${countryInfo?.code.toLowerCase()}.png`}
            name={countryInfo?.name}
            size="md"
            style={{ display: "inline-block", overflow: "visible" }}
            sx={{ width: 20, height: 20 }}
          />{" "}
          {countryInfo?.name}
        </MDTypography>
      </>
    );
  };
  const ViewMoreRa = ({ openDialog, attestationData, handleCloseDialog }) => {
    let {
      attestation_id,
      attestation_date,
      attestation_type_value,
      attestation_fees,
      vendor_name,
      paying_type,
      status_value,
      country_currency_code,
      comment,
    } = attestationData;

    let document_list = attestationInfo?.data?.document_list;

    return (
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        fullScreen={window.innerWidth < 768 ? true : false}
      >
        <DialogTitle sx={{ m: 0 }} id="customized-dialog-title">
          Attestation Details
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container p={2} spacing={1}>
            {displayTypoInfo("Date", dateFormat(attestation_date))}
            {displayTypoInfo("Vendor Name", vendor_name)}
            {displayTypoInfo("Type", attestation_type_value)}
            {displayTypoInfo(
              " Fees",
              country_currency_code + " " + attestation_fees * 1
            )}
            {displayTypoInfo(
              "Paying Type",
              paying_type === "ra"
                ? "Recruiting Agency"
                : firstLetterCapital(paying_type)
            )}
            {displayTypoInfo("Status", status_value)}
            {displayTypoInfo("Remarks", comment)}

            <Grid item xs={12} md={12} lg={12}>
              <MDTypography variant="h6" fontWeight="medium">
                {document_list?.length ? (
                  <MDBox
                    py={1}
                    px={1}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      List of Documents
                    </MDTypography>
                  </MDBox>
                ) : (
                  ""
                )}
              </MDTypography>
            </Grid>
            {document_list?.map((document, i) => {
              const {
                document_name,
                issue_date,
                expiry_date,
                upload_document,
                issue_country_info,
              } = document;
              return (
                <>
                  <Grid item xs={12} md={12} lg={12}>
                    <MDTypography variant="h6" fontWeight="medium">
                      {i + 1}.
                    </MDTypography>
                  </Grid>
                  {displayTypoInfo("Name", document_name)}
                  {displayTypoInfo(
                    "Issue Country",
                    getCountryFlag(issue_country_info)
                  )}
                  {displayTypoInfo(
                    "Issue Date",
                    issue_date ? dateFormat(issue_date) : "-"
                  )}
                  {displayTypoInfo(
                    "Expiry Date",
                    expiry_date ? dateFormat(expiry_date) : "-"
                  )}
                  {displayTypoInfo(
                    "Document",
                    <Link
                      to={`${process.env.REACT_APP_URI_PATH}${upload_document}`}
                      target="_blank"
                    >
                      View Document
                    </Link>
                  )}
                </>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialog}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    );
  };

  //START : Search and Filter form data
  //Handle onchange search and filter
  const onChangeSearch = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    setSearchFormData({ ...searchFormData, [name]: val });
  };
  const [csvData, setCsvData] = useState([]);
  const [downloadCsv, setDownloadCsv] = useState(false);
  let csvHeaders = [
    { label: "Attestation Date", key: "attestation_date" },
    { label: "Vendor Name", key: "vendor_name" },
    { label: "Type of Attestation", key: "attestation_type_value" },
    { label: "Attestation Fees", key: "attestation_fees" },
    { label: "Paid By", key: "paid_by" },
    { label: "Status", key: "status" },
  ];
  //Prepare the csv data for export
  const exportCsv = async () => {
    let queryUrl = getQueryParams();
    queryUrl = queryUrl + `&download=1`;
    getFetchData(`attestation/list${queryUrl}`).then(async (res) => {
      if (res?.data) {
        let exportData = [];
        await Promise.all(
          res?.data.map((report) => {
            report.attestation_date = report?.attestation_date
              ? dateFormat(report?.attestation_date)
              : "-";
            report.attestation_fees = report?.attestation_fees
              ? report?.country_currency_code + " " + report?.attestation_fees
              : "-";
            report.paid_by =
              report?.paying_type === "ra"
                ? "Recruiting Agency"
                : firstLetterCapital(report?.paying_type);
            report.status = report?.status_value;
            exportData.push(report);
          })
        );
        setCsvData(exportData); //Set the csv data
        setDownloadCsv(true); // Show the CSVDwonload link
        setTimeout(() => {
          csvLink?.current?.link?.click();
          setDownloadCsv(false); // Hide the CSVDwonload link
        }, 100);
      }
    });
  };
  //Prepare the query string for search and filter
  const getQueryParams = () => {
    let queryUrl = `?page=${pageIndex}`;

    queryUrl = searchFormData?.vendor_type?.value
      ? queryUrl + "&vendor_type=" + searchFormData?.vendor_type?.value
      : queryUrl;

    let vendorType = searchFormData?.vendor_type?.value;

    let vendorTypeId =
      vendorType === "company"
        ? searchFormData.vendor_type_id?.company_id
        : vendorType === "ra"
        ? searchFormData.vendor_type_id?.ra_id
        : vendorType === "applicant"
        ? searchFormData.vendor_type_id?.applicant_id
        : "";

    queryUrl = vendorTypeId
      ? queryUrl + "&vendor_type_id=" + vendorTypeId
      : queryUrl;

    queryUrl = searchFormData?.attestation_type_id?.setting_id
      ? queryUrl +
        "&attestation_type_id=" +
        searchFormData?.attestation_type_id?.setting_id
      : queryUrl;

    queryUrl = searchFormData?.attestation_date
      ? queryUrl + "&attestation_date=" + searchFormData?.attestation_date
      : queryUrl;

    queryUrl = searchFormData.status_id?.setting_id
      ? queryUrl + "&status_id=" + searchFormData.status_id?.setting_id
      : queryUrl;

    return queryUrl;
  };
  //END : Search and Filter form data
  const vendorOptions = [
    { label: "Recruiting agency", value: "ra" },
    { label: "Company", value: "company" },
    { label: "Applicant", value: "applicant" },
  ];
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <CardTopBoxAdd
                title={"Attestations"}
                link={"/attestation"}
                showAddButton={showAddButton}
              />
              <MDBox>
                <Grid container p={2} spacing={2}>
                  {/* START : Search and filter block */}
                  <Grid
                    container
                    className="searchFilterBlock"
                    p={2}
                    spacing={2}
                    mt={1}
                  >
                    <Grid item xs={6} md={2}>
                      <MDBox mb={2} className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.vendor_type}
                          size="small"
                          name="vendor_type"
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearchFormData({
                                ...searchFormData,
                                ["vendor_type"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["vendor_type"]: "",
                              });
                            }
                          }}
                          options={vendorOptions}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Vendor Type"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDBox mb={2} className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.vendor_type_id}
                          size="small"
                          name="vendor_type_id"
                          options={
                            searchFormData.vendor_type?.value === "ra"
                              ? raList
                              : searchFormData.vendor_type?.value ===
                                "applicant"
                              ? applicantList
                              : searchFormData.vendor_type?.value === "company"
                              ? companyList
                              : []
                          }
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearchFormData({
                                ...searchFormData,
                                ["vendor_type_id"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["vendor_type_id"]: "",
                              });
                            }
                          }}
                          getOptionLabel={(paymentOptions) => {
                            if (searchFormData.vendor_type?.value === "ra") {
                              return paymentOptions?.ra_name || "";
                            } else if (
                              searchFormData.vendor_type?.value === "applicant"
                            ) {
                              return paymentOptions?.first_name
                                ? paymentOptions?.first_name +
                                    " " +
                                    paymentOptions?.last_name
                                : "";
                            } else if (
                              searchFormData.vendor_type?.value === "company"
                            ) {
                              return paymentOptions?.name
                                ? paymentOptions?.name
                                : "";
                            } else {
                              return "";
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Vendor Name"
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) =>
                                searchSpot(
                                  searchFormData.vendor_type?.value,
                                  e.target.value
                                )
                              }
                            />
                          )}
                        />
                      </MDBox>
                    </Grid>

                    <Grid item xs={6} md={2}>
                      <MDBox mb={2} className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.attestation_type_id}
                          size="small"
                          name="attestation_type_id"
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearchFormData({
                                ...searchFormData,
                                ["attestation_type_id"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["attestation_type_id"]: "",
                              });
                            }
                          }}
                          options={attestationTypeList}
                          getOptionLabel={(attestationTypeList) =>
                            attestationTypeList.setting_value || ""
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Attestation Type"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDBox mb={2} className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.status_id}
                          size="small"
                          name="status_id"
                          options={statusList}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearchFormData({
                                ...searchFormData,
                                ["status_id"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["status_id"]: "",
                              });
                            }
                          }}
                          getOptionLabel={(statusList) =>
                            statusList.setting_value || ""
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Status"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDBox mb={2}>
                        <MDInput
                          type="date"
                          name="attestation_date"
                          label="Attestation Date"
                          fullWidth
                          value={searchFormData.attestation_date}
                          onChange={onChangeSearch}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDBox display="flex" alignItems="center">
                        {/* {downloadCsv && (
                          <>
                            <CSVDownload
                              data={csvData}
                              filename={"my-3534564-file.csv"}
                              headers={csvHeaders}
                              target="_self"
                            />
                          </>
                        )} */}
                        <CSVLink
                          className=" d-none "
                          data={csvData}
                          filename={`Attestations ${dateTimeStamp()}`}
                          headers={csvHeaders}
                          ref={csvLink}
                          target="_self"
                        />
                        <MDButton
                          variant="gradient"
                          color="primary"
                          size="small"
                          type="button"
                          className="searchButton"
                          onClick={exportCsv}
                        >
                          <DownloadIcon /> Export CSV
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                  {/* END : Search and filter block */}

                  {!pageResize && (
                    <Grid item xs={12} md={12} lg={12}>
                      <MDBox
                        borderRadius="lg"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p={1}
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <Grid item xs={4} md={4} lg={1}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Attestation Date
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Vendor Name
                          </MDTypography>
                        </Grid>

                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Type of Attestation
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Attestation Fees
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Paid By
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Status
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={1}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Action
                          </MDTypography>
                        </Grid>
                      </MDBox>
                    </Grid>
                  )}
                  {attestationList.map((attestationItem, index) => {
                    let {
                      attestation_id,
                      attestation_date,
                      attestation_type_value,
                      attestation_fees,
                      vendor_name,
                      paying_type,
                      status_value,
                      country_currency_code,
                    } = attestationItem;
                    return (
                      <>
                        {!pageResize ? (
                          <Grid
                            item
                            key={attestation_id}
                            xs={12}
                            md={12}
                            lg={12}
                          >
                            <MDBox
                              borderRadius="lg"
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              p={1}
                              sx={{
                                border: ({
                                  borders: { borderWidth, borderColor },
                                }) => `${borderWidth[1]} solid ${borderColor}`,
                              }}
                            >
                              <Grid item xs={4} md={4} lg={1}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {dateFormat(attestation_date)}
                                </MDTypography>
                              </Grid>
                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {vendor_name}
                                </MDTypography>
                              </Grid>
                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {attestation_type_value}
                                </MDTypography>
                              </Grid>
                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {country_currency_code +
                                    " " +
                                    attestation_fees * 1}
                                </MDTypography>
                              </Grid>

                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {paying_type === "ra"
                                    ? "Recruiting Agency"
                                    : firstLetterCapital(paying_type)}
                                </MDTypography>
                              </Grid>
                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {status_value}
                                </MDTypography>
                              </Grid>
                              <Grid item xs={4} md={4} lg={1}>
                                <IconButton
                                  size="small"
                                  disableRipple
                                  color="inherit"
                                  onClick={() =>
                                    handleOpenDialog(attestationItem)
                                  }
                                >
                                  <RemoveRedEye />
                                </IconButton>
                                {showEditButton && (
                                  <IconButton
                                    size="small"
                                    disableRipple
                                    color="inherit"
                                    onClick={() =>
                                      navigate(`/attestation/${attestation_id}`)
                                    }
                                  >
                                    <Edit />
                                  </IconButton>
                                )}
                                {showDeleteButton && (
                                  <IconButton
                                    size="small"
                                    disableRipple
                                    color="inherit"
                                  >
                                    <Delete />
                                  </IconButton>
                                )}
                              </Grid>
                            </MDBox>
                          </Grid>
                        ) : (
                          <MobileList
                            key={attestation_id}
                            attestationItem={attestationItem}
                            index={index + 1}
                            handleOpenDialog={handleOpenDialog}
                            showEditButton={showEditButton}
                            showDeleteButton={showDeleteButton}
                          />
                        )}
                      </>
                    );
                  })}
                </Grid>
                <Grid item xs={12} md={3}>
                  <Stack spacing={2} mb={2} ml={2}>
                    <Pagination
                      count={pageCount}
                      page={pageIndex}
                      onChange={doPaginationChange}
                      variant="outlined"
                    />
                  </Stack>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <ViewMoreRa
        openDialog={openDialog}
        attestationData={attestationData}
        handleCloseDialog={handleCloseDialog}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default AttestationList;
