import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Data
import { Link } from "react-router-dom";
import { dateTimeFormat } from "utils/clientFunctions";
import { dateFormat } from "utils/clientFunctions";

function Tasks(task) {
  const [menu, setMenu] = useState(null);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <Link to="/taskdata">
        <MenuItem> New Task</MenuItem>{" "}
      </Link>
      <Link to="/task">
        <MenuItem> View All</MenuItem>{" "}
      </Link>
    </Menu>
  );

  function getData() {
    let allRows = [];
    task.taskList?.map((task) => {
      const { start_date, name, assigned_user_info: user_info } = task;
      allRows.push({
        startdate: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {dateFormat(start_date)}
          </MDTypography>
        ),
        assignedto: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {user_info?.first_name + " " + user_info?.last_name}
          </MDTypography>
        ),
        taskname: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {name}
          </MDTypography>
        ),
      });
    });

    return {
      columns: [
        {
          Header: "Start Date",
          accessor: "startdate",
          width: "33%",
          align: "left",
        },
        {
          Header: "Assigned To",
          accessor: "assignedto",
          width: "33%",
          align: "left",
        },
        {
          Header: "Task name",
          accessor: "taskname",
          width: "33%",
          align: "center",
        },
      ],
      rows: allRows,
    };
  }
  const { columns, rows } = getData();

  return (
    <Card>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
      >
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            Upcoming Tasks
          </MDTypography>
        </MDBox>
        <MDBox color="text" px={2}>
          <Icon
            sx={{ cursor: "pointer", fontWeight: "bold" }}
            fontSize="small"
            onClick={openMenu}
          >
            more_vert
          </Icon>
        </MDBox>
        {renderMenu}
      </MDBox>
      <MDBox>
        <DataTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
        />
      </MDBox>
    </Card>
  );
}

export default Tasks;
