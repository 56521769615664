import * as Yup from "yup";

export const createLeadSchema = Yup.object({
  lead_type: Yup.mixed().required("Please select lead"),
  lead_type_name: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter lead name")
    .max(120, "Max 120 characters allowed"),
  country_id: Yup.mixed().required("Please select country"),
  industry_id: Yup.mixed().required("Please select industry"),
  priority: Yup.mixed().required("Please select priority"),
  start_date: Yup.date().required("Please provide start date"),
  //end_date: Yup.date(),
  assigned_user_id: Yup.mixed().required("Please select assigned"),
  status_id: Yup.mixed().required("Please select status"),
  remarks: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter remarks")
    .max(250),

  // size_of_company: Yup.mixed().when("lead_type.value", {
  //   is: "company",
  //   then: () => Yup.mixed().required("Please select company size"),
  // }),

  size_of_company: Yup.mixed().when("lead_type.value", (value) => {
    if (value[0] === "company") {
      return Yup.mixed().required("Please select company size");
    } else {
      return Yup.mixed()
        .transform((value, originalValue) => {
          if (!value) {
            return null;
          }
          return originalValue;
        })
        .nullable()
        .optional();
    }
  }),

  website: Yup.string().when("lead_type.value", (value) => {
    if (value[0] === "company") {
      return Yup.string()
        .min(2, "Please enter minimum two characters")
        .required("Please enter website url");
    } else {
      return Yup.mixed()
        .transform((value, originalValue) => {
          if (!value) {
            return null;
          }
          return originalValue;
        })
        .nullable()
        .optional();
    }
  }),

  contact_code_id: Yup.mixed().when("lead_type.value", (value) => {
    if (value[0] === "company") {
      return Yup.mixed().required("Please select country code");
    } else {
      return Yup.mixed()
        .transform((value, originalValue) => {
          if (!value) {
            return null;
          }
          return originalValue;
        })
        .nullable()
        .optional();
    }
  }),

  contact_number: Yup.number().when("lead_type.value", (value) => {
    if (value[0] === "company") {
      return Yup.number()
        .typeError("Only numbers allowed")
        .min(2, "Please enter minimum two characters")
        .required("Please enter contact number");
    } else {
      return Yup.mixed()
        .transform((value, originalValue) => {
          if (!value) {
            return null;
          }
          return originalValue;
        })
        .nullable()
        .optional();
    }
  }),

  email_id: Yup.string().when("lead_type.value", (value) => {
    if (value[0] === "company") {
      return Yup.string()
        .required("Please enter valid email")
        .email("Please enter valid email")
        .max(255);
    } else {
      return Yup.mixed()
        .transform((value, originalValue) => {
          if (!value) {
            return null;
          }
          return originalValue;
        })
        .nullable()
        .optional();
    }
  }),
  position_id: Yup.mixed().when("lead_type.value", (value) => {
    if (value[0] === "applicant") {
      return Yup.mixed().required("Please select position");
    } else {
      return Yup.mixed()
        .transform((value, originalValue) => {
          // Convert empty values to null
          if (!value) {
            return null;
          }
          return originalValue;
        })
        .nullable()
        .optional();
    }
  }),
});
