// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import { Grid, IconButton } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import { useNavigate } from "react-router-dom";
import { Delete, Edit, RemoveRedEye } from "@mui/icons-material";
import { dateFormat } from "utils/clientFunctions";
import { firstLetterCapital } from "utils/clientFunctions";

function AccountRes({
  raItem,
  handleOpenDialog,
  index,
  showEditButton,
  showDeleteButton,
}) {
  let {
    account_id,
    debit_credit_type,
    vendor_name,
    party_name,
    start_date,
    end_date,
    payment_status,
    payment_value,
  } = raItem;
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const BodyTypo = ({ title }) => {
    return (
      <MDTypography variant="caption" fontWeight="bold">
        {title}
      </MDTypography>
    );
  };
  const BodyCSSTypo = ({ title }) => {
    return (
      <MDBox mb={1} lineHeight={0}>
        <MDTypography variant="caption" fontWeight="bold">
          {title}
        </MDTypography>
      </MDBox>
    );
  };

  return (
    <Grid container p={2} spacing={2}>
      <Grid item xs={12} md={12} lg={12}>
        <MDBox
          borderRadius="lg"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={1}
          sx={{
            border: ({ borders: { borderWidth, borderColor } }) =>
              `${borderWidth[1]} solid ${borderColor}`,
          }}
        >
          <MDBox width="100%" display="flex" flexDirection="column">
            <MDBox
              mb={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <BodyTypo title={index} />
              {/* <BodyTypo
                title={
                  (start_date ? dateFormat(start_date) : "") +
                  " - " +
                  (end_date ? dateFormat(end_date) : "")
                }
              /> */}

              <BodyTypo title={firstLetterCapital(debit_credit_type)} />
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems={{ xs: "center", sm: "center" }}
              flexDirection={{ xs: "row", sm: "row" }}
              // mb={2}
            >
              <MDBox>
                <BodyCSSTypo title={`Vendor name : ${vendor_name}`} />
                <BodyCSSTypo title={`Payment Type : ${payment_value}`} />
                <BodyCSSTypo title={`Party name : ${party_name}`} />
                <BodyCSSTypo title={`Status : ${payment_status}`} />
              </MDBox>
              <MDBox></MDBox>
            </MDBox>
            <MDBox
              mt={2}
              display="flex"
              justifyContent="space-evenly"
              alignItems="center"
            >
              <MDButton
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => handleOpenDialog(raItem)}
              >
                View
              </MDButton>
              {showEditButton && (
                <MDButton
                  variant="outlined"
                  size="small"
                  color="info"
                  onClick={() => navigate(`/accountdata/${account_id}`)}
                >
                  Edit
                </MDButton>
              )}
              {showDeleteButton && (
                <MDButton variant="outlined" size="small" color="error">
                  Delete
                </MDButton>
              )}
            </MDBox>
          </MDBox>
        </MDBox>
      </Grid>
    </Grid>
  );
}

// Setting default values for the props of AccountRes
AccountRes.defaultProps = {
  noGutter: false,
};

export default AccountRes;
