import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { useAuth } from "utils/auth";
import { postData } from "utils/clientFunctions";
import { useToast } from "UI/snakToast/ToastProvider";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const { showToast } = useToast();
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });

  const onHandleChange = (e) => {
    const { name, value } = e.target;
    setUserData((pre) => ({
      ...pre,
      [name]: value,
    }));
  };

  const { login } = useAuth();

  const handleSubmit = async (val) => {
    const loginData = {
      email: val.email,
      password: val.password,
    };
    const url = `user/login`;
    const response = await postData(url, loginData);
    if (response.message === "success") {
      showToast("Sign in Successfull", "success");
      login(response.token);
    } else {
      console.log(response.message);
      showToast(response.message, "error");
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          {/* <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid> */}
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <Formik
            initialValues={userData}
            validationSchema={validationSchema}
            onSubmit={(val) => handleSubmit(val)}
          >
            <Form>
              <MDBox mb={2}>
                <Field name="email">
                  {({ field, form }) => (
                    <MDInput
                      {...field}
                      type="email"
                      label="Email"
                      fullWidth
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      value={field.value}
                      error={form.touched.email && Boolean(form.errors.email)}
                      helperText={<ErrorMessage name="email" />}
                    />
                  )}
                </Field>
              </MDBox>
              <MDBox mb={2}>
                <Field name="password">
                  {({ field, form }) => (
                    <MDInput
                      {...field}
                      type="password"
                      label="Password"
                      fullWidth
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      value={field.value}
                      error={
                        form.touched.password && Boolean(form.errors.password)
                      }
                      helperText={<ErrorMessage name="password" />}
                    />
                  )}
                </Field>
              </MDBox>
              {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox> */}
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  type="submit"
                >
                  sign in
                </MDButton>
              </MDBox>
              {/* <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox> */}
            </Form>
          </Formik>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
