// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
//import useSWR from "swr";
import { useEffect, useState } from "react";
//import { Token } from "utils/clientFunctions";
import MDButton from "components/MDButton";
import { DialogContent } from "@mui/material";
//import { settingDefault } from "utils/clientFunctions";
import { Field, Form, Formik } from "formik";
import MDInput from "components/MDInput";
import { fetchData } from "utils/clientFunctions";
import useSWR from "swr";

import { postData, doUpload } from "utils/clientFunctions";
import { useToasts } from "react-toast-notifications";

import { useNavigate, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "../../../../src/assets/css/Custom.css";
import { useFormik } from "formik";

import { createAccountSchema } from "../components/schemas";
import { CardTopBoxBack } from "layouts/commonComponents/cardTopBoxBack";
import { updateData } from "utils/clientFunctions";
import { removeDuplicates } from "utils/clientFunctions";
import { liveSearchData } from "layouts/commonComponents/liveSearchData";
import { useSelector, useDispatch } from "react-redux";
import { getPermissions, selectPermissions } from "reducers/permissionSlice";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MDTypography from "components/MDTypography";
import IconButton from "@mui/material/IconButton";
function AccountData() {
  let { id } = useParams();
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [applicantList, setApplicantList] = useState([]);
  const [raList, setRaList] = useState([]);
  const [usersList, setUserList] = useState([]);
  const [payments, setPayments] = useState([]);

  const [partialPaidAmountSum, setPartialPaidAmountSum] = useState(0);

  const { addToast } = useToasts();
  const navigate = useNavigate();

  //Start : For permission
  const dispatch = useDispatch();
  const permissions = useSelector(selectPermissions);
  if (id) {
    if (
      !(
        permissions?.user_id === 1 ||
        permissions?.permission?.includes("accEdit")
      )
    ) {
      navigate("/account");
    }
  } else {
    if (
      !(
        permissions?.user_id === 1 ||
        permissions?.permission?.includes("accCreate")
      )
    ) {
      navigate("/account");
    }
  }
  useEffect(() => {
    dispatch(getPermissions());
  }, []);
  //End : For permission

  useEffect(() => {
    if (values?.payment_status?.value === "Partially paid") {
      let sum = 0;
      payments.map(({ pay_amount }) => {
        pay_amount = parseFloat(pay_amount);
        if (pay_amount != NaN && pay_amount > 0) {
          sum += pay_amount;
        }
      });
      setPartialPaidAmountSum(sum);
    }
  });

  const [formData, setFormData] = useState({
    debit_credit_type: "",
    vendor_type: { label: "Company", value: "company" },
    vendor_id: "",
    payment_type_id: "",
    currency_id: "",
    acc_amount: "",
    acc_date: "",
    party_type: "",
    party_id: "",
    payment_status: "",
    remarks: "",
  });

  const searchSpot = (value, key) => {
    liveSearchData({
      countryId: null,
      value,
      key,
      setRaList,
      setCompanyList,
      setApplicantList,
      setUserList,
    });
  };

  const getFetchData = async (settingUrl) => {
    return await fetchData(settingUrl);
  };

  //Get data
  useEffect(() => {
    if (id) {
      const updateUrl = `account/${id}`;
      getFetchData(updateUrl).then((res) => {
        let {
          debit_credit_type,
          remarks,
          acc_amount,
          acc_date,
          payment_type_info,
          currency_info,
          payment_status,
          vendor_type,
          party_type,
          payment_list,
        } = res.account;
        let newVendorType = vendorLeadOptions?.filter(
          (tp) => tp.value === vendor_type
        );

        let debitCreditType = creditDebitOptions?.filter(
          (cdt) => cdt.value === debit_credit_type
        );

        let newPartyType = leadOptions?.filter((tp) => tp.value === party_type);
        let newpayment_status = {
          label: payment_status,
          value: payment_status,
        };
        setFormData({
          debit_credit_type: debitCreditType && debitCreditType[0],
          vendor_type: newVendorType && newVendorType[0],
          vendor_id: res.vendor,
          party_type: newPartyType && newPartyType[0],
          party_id: res.party,
          payment_type_id: payment_type_info,
          currency_id: currency_info,
          acc_amount,
          acc_date,
          payment_status: newpayment_status,
          remarks: remarks,
        });

        let paymentsList = [];
        payment_list?.map(({ pay_amount, pay_date, remark }) => {
          paymentsList.push({
            pay_amount,
            pay_date,
            remark,
          });
        });
        setPayments(paymentsList);
      });
    }
    const countryUrl = `country/list`;
    getFetchData(countryUrl).then((res) => {
      let countryList = res.data;
      let CurrencyList = removeDuplicates(countryList, "currency_code"); //countryList?.filter((cr)=>cr.currency_code != "")
      setCountryList(CurrencyList);
    });

    const paymentUrl = `setting/type/10`;
    getFetchData(paymentUrl).then((res) => {
      let paymentList = res.data;
      setPaymentTypes(paymentList);
    });

    const companyUrl = `company/list?page=1`;
    getFetchData(companyUrl).then((res) => {
      let companyList = res.data;
      setCompanyList(companyList);
    });

    const applicantUrl = `applicant/list?page=1`;
    getFetchData(applicantUrl).then((res) => {
      let applicantList = res.data;
      setApplicantList(applicantList);
    });

    const raUrl = `recruiting_agency/list?page=1`;
    getFetchData(raUrl).then((res) => {
      let raList = res.data;
      setRaList(raList);
    });

    const userUrl = `user/list?page=1`;
    getFetchData(userUrl).then((res) => {
      let userList = res.data;
      setUserList(userList);
    });
  }, []);

  const handleFormSubmit = async (data, resetForm) => {
    let submitData = {};
    submitData = data;

    //Update below fields to send only ids
    submitData.debit_credit_type = data.debit_credit_type?.value;
    submitData.vendor_type = data.vendor_type?.value;
    submitData.party_type = data.party_type?.value;
    submitData.payment_type_id = data.payment_type_id?.setting_id;
    submitData.currency_id = data.currency_id?.country_id;
    submitData.payment_status = data.payment_status?.value;

    //Convert payments into array
    let allPayments = [];
    if (payments.length && submitData.payment_status === "Partially paid") {
      payments?.map(({ pay_amount, pay_date, remark }) => {
        if (pay_amount && pay_date) {
          allPayments.push({
            pay_amount,
            pay_date,
            remark,
          });
        }
      });
    }
    submitData.payments = allPayments;

    const url = `account`;
    const response = id
      ? await updateData(`account/${id}`, submitData)
      : await postData(url, submitData);
    if (!response.status) {
      if (response.message) {
        addToast(response.message, { appearance: "error", autoDismiss: true });
      }
      if (response.errors) {
        let apiErrors = response.errors;
        for (let i = 0; i < apiErrors.length; i++) {
          let errorMessage = apiErrors[i].message;
          errorMessage = errorMessage.replace(/['"]+/g, "").replace(/_/g, " ");
          errorMessage =
            errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
          addToast(errorMessage, { appearance: "error", autoDismiss: true });
        }
      }
    } else {
      let successMessage = id
        ? "Account has been updated successfully"
        : "New account has been created successfully";
      addToast(successMessage, {
        appearance: "success",
        autoDismiss: true,
      });
      navigate(-1);
    }
  };

  const ErrorDisp = (name) => {
    return (
      errors[name] &&
      touched[name] && ( // Use `errors[name]` instead of `errors.name`
        <p className="formError">{errors[name]}</p>
      )
    );
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: formData,
    enableReinitialize: true,
    validationSchema: createAccountSchema,
    onSubmit: ({ resetForm }) => {
      values.vendor_id =
        values.vendor_type?.value === "ra"
          ? values.vendor_id?.ra_id
          : values.vendor_type?.value === "company"
          ? values.vendor_id?.company_id
          : values.vendor_id?.applicant_id;
      values.party_id =
        values.party_type?.value === "ra"
          ? values.party_id?.ra_id
          : values.party_type?.value === "company"
          ? values.party_id?.company_id
          : values.party_type?.value === "user"
          ? values.party_id?.user_id
          : values.party_id?.applicant_id;

      handleFormSubmit(values, resetForm);
    },
  });

  const leadOptions = [
    { label: "Recruiting agency", value: "ra" },
    { label: "Company", value: "company" },
    { label: "Applicant", value: "applicant" },
    { label: "Staff/Partner", value: "user" },
  ];
  const vendorLeadOptions = [
    { label: "Recruiting agency", value: "ra" },
    { label: "Company", value: "company" },
    { label: "Applicant", value: "applicant" },
  ];

  const paymentOptions = [
    { label: "Not paid", value: "Not paid" },
    { label: "Partially paid", value: "Partially paid" },
    { label: "Paid", value: "Paid" },
  ];

  const creditDebitOptions = [
    { label: "Credit", value: "credit" },
    { label: "Debit", value: "debit" },
  ];

  // START Payments section
  const addMorePayment = () => {
    setPayments([...payments, {}]);
  };
  const handlePaymentChange = (e, i) => {
    let { name, value } = e.target;
    if (name === "is_lock") {
      value = value == "On" ? "Off" : "On";
    }
    const onchangeVal = [...payments];
    onchangeVal[i][name] = value;
    setPayments(onchangeVal);
  };
  const handlePaymentDelete = (i) => {
    const deleteVal = [...payments];
    deleteVal.splice(i, 1);
    setPayments(deleteVal);
  };
  // END Payments section

  console.log("errorggggs", errors);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Formik
          initialValues={formData}
          enableReinitialze={true}
          onSubmit={handleSubmit}
        >
          <Form>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <CardTopBoxBack
                    title={id ? "Update Account" : "New Account"}
                  />
                  <MDBox
                    variant="contained"
                    bgColor="white"
                    color="black"
                    borderRadius="md"
                    shadow="md"
                  >
                    <DialogContent>
                      <Box sx={{ flexGrow: 1 }}>
                        <MDBox pt={1} pb={1}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.debit_credit_type}
                                  size="small"
                                  name="debit_credit_type"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue(
                                          "debit_credit_type",
                                          newValue
                                        )
                                      : setFieldValue("debit_credit_type", "")
                                  }
                                  options={creditDebitOptions}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Debit/Credit *"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {ErrorDisp("debit_credit_type")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.vendor_type}
                                  size="small"
                                  name="vendor_type"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue("vendor_type", newValue)
                                      : setFieldValue("vendor_type", "")
                                  }
                                  options={vendorLeadOptions}
                                  required
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Vendor Type *"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {ErrorDisp("vendor_type")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.vendor_id}
                                  size="small"
                                  name="vendor_id"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue("vendor_id", newValue)
                                      : setFieldValue("vendor_id", "")
                                  }
                                  options={
                                    values.vendor_type?.value === "ra"
                                      ? raList
                                      : values.vendor_type?.value ===
                                        "applicant"
                                      ? applicantList
                                      : companyList
                                  }
                                  getOptionLabel={(paymentOptions) =>
                                    values.vendor_type?.value === "ra"
                                      ? paymentOptions?.ra_name || ""
                                      : values.vendor_type?.value ===
                                        "applicant"
                                      ? paymentOptions?.first_name
                                        ? paymentOptions?.first_name +
                                          " " +
                                          paymentOptions.last_name
                                        : ""
                                      : paymentOptions?.name || "" || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Vendor Name *"
                                      InputLabelProps={{ shrink: true }}
                                      onChange={(e) =>
                                        searchSpot(
                                          values.vendor_type?.value,
                                          e.target.value
                                        )
                                      }
                                    />
                                  )}
                                />
                                {ErrorDisp("vendor_id")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.payment_type_id}
                                  size="small"
                                  name="payment_type_id"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue(
                                          "payment_type_id",
                                          newValue
                                        )
                                      : setFieldValue("payment_type_id", "")
                                  }
                                  options={paymentTypes}
                                  getOptionLabel={(paymentTypes) =>
                                    paymentTypes.setting_value || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Payment Type *"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {ErrorDisp("payment_type_id")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.currency_id}
                                  size="small"
                                  name="currency_id"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue("currency_id", newValue)
                                      : setFieldValue("currency_id", "")
                                  }
                                  options={countryList}
                                  getOptionLabel={(countryList) =>
                                    countryList.currency_code || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Currency *"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {ErrorDisp("currency_id")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="acc_amount"
                                  type="text"
                                  label="Amount *"
                                  fullWidth
                                  value={values.acc_amount}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {ErrorDisp("acc_amount")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  type="text"
                                  label="Balance"
                                  fullWidth
                                  disabled={true}
                                  value={
                                    values?.payment_status?.value ===
                                    "Partially paid"
                                      ? values.acc_amount - partialPaidAmountSum
                                      : values?.payment_status?.value ===
                                        "Not paid"
                                      ? values.acc_amount
                                      : 0
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  name="acc_date"
                                  type="date"
                                  label="Date *"
                                  fullWidth
                                  value={values.acc_date}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {ErrorDisp("acc_date")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.party_type}
                                  size="small"
                                  name="party_type"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue("party_type", newValue)
                                      : setFieldValue("party_type", "")
                                  }
                                  options={leadOptions}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Party Type *"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {ErrorDisp("party_type")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.party_id}
                                  size="small"
                                  name="party_id"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue("party_id", newValue)
                                      : setFieldValue("party_id", "")
                                  }
                                  options={
                                    values.party_type?.value === "ra"
                                      ? raList
                                      : values.party_type?.value === "applicant"
                                      ? applicantList
                                      : values.party_type?.value === "user"
                                      ? usersList
                                      : companyList
                                  }
                                  getOptionLabel={(paymentOptions) => {
                                    console.log(
                                      "values.party_type?.value",
                                      values.party_type?.value
                                    );
                                    if (values.party_type?.value === "ra") {
                                      return paymentOptions?.ra_name || "";
                                    } else if (
                                      values.party_type?.value === "applicant"
                                    ) {
                                      return paymentOptions?.first_name
                                        ? paymentOptions?.first_name +
                                            " " +
                                            paymentOptions?.last_name
                                        : "";
                                    } else if (
                                      values.party_type?.value === "user"
                                    ) {
                                      return paymentOptions?.first_name
                                        ? paymentOptions?.first_name +
                                            " " +
                                            paymentOptions?.last_name
                                        : "";
                                    } else {
                                      return paymentOptions?.name || "" || "";
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Party Name *"
                                      InputLabelProps={{ shrink: true }}
                                      onChange={(e) =>
                                        searchSpot(
                                          values.party_type?.value,
                                          e.target.value
                                        )
                                      }
                                    />
                                  )}
                                />
                                {ErrorDisp("party_id")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.payment_status}
                                  size="small"
                                  name="payment_status"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue(
                                          "payment_status",
                                          newValue
                                        )
                                      : setFieldValue("payment_status", "")
                                  }
                                  options={paymentOptions}
                                  getOptionLabel={(paymentOptions) =>
                                    paymentOptions.label || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Payment Status *"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {ErrorDisp("payment_status")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={12}>
                              <MDBox mb={2}>
                                <TextField
                                  id="outlined-multiline-static"
                                  label="Remarks *"
                                  name="remarks"
                                  InputLabelProps={{ shrink: true }}
                                  multiline
                                  fullWidth
                                  rows={3}
                                  value={values.remarks}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {ErrorDisp("remarks")}
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Box>
                    </DialogContent>
                  </MDBox>
                </Card>

                {values?.payment_status?.value === "Partially paid" && (
                  <>
                    {/* Start Payments Management  */}
                    <Card className="mt4rem">
                      <MDBox
                        mx={2}
                        mt={-5}
                        py={1}
                        borderRadius="lg"
                        px={2}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        variant="gradient"
                        bgColor="info"
                        coloredShadow="info"
                      >
                        <MDTypography variant="h6" color="white">
                          Payments
                        </MDTypography>
                        <MDButton
                          variant="outlined"
                          onClick={() => addMorePayment()}
                          color="white"
                          size="small"
                        >
                          Add Payment
                        </MDButton>
                      </MDBox>

                      <MDBox
                        variant="contained"
                        bgColor="white"
                        color="black"
                        borderRadius="md"
                        shadow="md"
                      >
                        <DialogContent pt={1} pb={1}>
                          <Box sx={{ flexGrow: 1 }}>
                            <MDBox pt={1} pb={1}>
                              <Grid container spacing={2}>
                                {payments.map((val, i) => (
                                  <Grid
                                    item
                                    className="borderBottom"
                                    style={{
                                      borderBottom: "1px solid #33333338",
                                    }}
                                    container
                                    xs={12}
                                    md={12}
                                    key={i}
                                    spacing={2}
                                  >
                                    <Grid item xs={12} md={3}>
                                      <MDBox mb={2}>
                                        <MDInput
                                          name="pay_amount"
                                          type="number"
                                          label="Amount"
                                          required
                                          fullWidth
                                          value={val.pay_amount}
                                          onChange={(e) =>
                                            handlePaymentChange(e, i)
                                          }
                                          onBlur={handleBlur}
                                        />
                                      </MDBox>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                      <MDBox mb={2}>
                                        <MDInput
                                          name="pay_date"
                                          type="date"
                                          label="Date"
                                          required
                                          fullWidth
                                          value={val.pay_date}
                                          onChange={(e) =>
                                            handlePaymentChange(e, i)
                                          }
                                          onBlur={handleBlur}
                                        />
                                      </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                      <MDBox mb={2}>
                                        <MDInput
                                          name="remark"
                                          type="text"
                                          label="Remarks"
                                          fullWidth
                                          value={val.remark}
                                          onChange={(e) =>
                                            handlePaymentChange(e, i)
                                          }
                                          onBlur={handleBlur}
                                        />
                                      </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                      <IconButton className="mdsRemoveBtn">
                                        <DeleteForeverIcon
                                          variant="gradient"
                                          color="error"
                                          type="button"
                                          fontSize="large"
                                          onClick={() => handlePaymentDelete(i)}
                                        />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                ))}
                              </Grid>
                            </MDBox>
                          </Box>
                        </DialogContent>
                      </MDBox>
                    </Card>
                    {/* End Payments Management  */}
                  </>
                )}
              </Grid>
            </Grid>
            <MDBox mt={2} display="flex" alignItems="center">
              <MDButton
                variant="gradient"
                color="primary"
                size="small"
                type="submit"
                className="saveButton"
              >
                Save
              </MDButton>
            </MDBox>
          </Form>
        </Formik>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AccountData;
