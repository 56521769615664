// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { fetchData } from "utils/clientFunctions";
import useSWR from "swr";
import { useEffect, useRef, useState } from "react";
import { Token } from "utils/clientFunctions";
import MDButton from "components/MDButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  Tooltip,
} from "@mui/material";
import { settingDefault } from "utils/clientFunctions";
import { ErrorMessage, Field, Form, Formik } from "formik";
import MDInput from "components/MDInput";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MDAvatar from "components/MDAvatar";
import { Delete, Edit, RemoveRedEye, TramSharp } from "@mui/icons-material";
import { useAuth } from "utils/auth";
import pxToRem from "assets/theme/functions/pxToRem";
import MobileList from "../components/mobileList";
import { dateFormat } from "utils/clientFunctions";
import { CardTopBoxAdd } from "layouts/commonComponents/cardTopBoxAdd";

import { useSelector, useDispatch } from "react-redux";
import { getPermissions, selectPermissions } from "reducers/permissionSlice";
import { dateDifference } from "utils/clientFunctions";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { CSVDownload, CSVLink } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";
import { dateTimeStamp } from "utils/clientFunctions";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function ApplicantsList() {
  const csvLink = useRef();
  const [pageIndex, setPageIndex] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [pageResize, setPageResize] = useState(
    window.innerWidth < 992 ? true : false
  );
  const navigate = useNavigate();

  const [applicantsList, setApplicantsList] = useState([]);
  const [applicantInfo, setApplicantInfo] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [deploymentTypeList, setDeploymentTypeList] = useState([]);

  //View More data
  const [applicantData, setApplicantData] = useState({});
  const { logout } = useAuth();

  //Start : For permission
  const dispatch = useDispatch();
  var permissions = useSelector(selectPermissions);
  if (
    !(
      permissions?.user_id === 1 || permissions?.permission?.includes("appView")
    )
  ) {
    navigate("/dashboard");
  }
  let showAddButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("appCreate")
      ? true
      : false;
  let showEditButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("appEdit")
      ? true
      : false;
  let showDeleteButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("appDelete")
      ? true
      : false;
  useEffect(() => {
    dispatch(getPermissions());
  }, []);
  //End : For permission

  //Search & Filter initial data
  const [searchFormData, setSearchFormData] = useState({
    name: "",
    passport_number: "",
    country_id: "",
    deployment_type_id: "",
  });
  //Get applicant list data
  const getApplicantsData = async () => {
    let queryUrl = getQueryParams();
    const url = `applicant/list${queryUrl}`;
    const data = await fetchData(url);
    if (
      data?.name === "JsonWebTokenError" ||
      data?.name === "TokenExpiredError" ||
      data?.message === "You are not authorized"
    ) {
      logout();
    }
    if (data && data.data) {
      let applicantsCount = Math.ceil(data.count / 10);
      setPageCount(applicantsCount);
      setApplicantsList(data.data || []);
    }
  };

  useEffect(() => {
    getApplicantsData();
  }, [
    pageIndex,
    searchFormData.name,
    searchFormData.passport_number,
    searchFormData.country_id,
    searchFormData.deployment_type_id,
  ]);

  useEffect(() => {
    //Get deploymentType List
    const deploymentTypeUrl = `setting/type/16`;
    getFetchData(deploymentTypeUrl).then((res) => {
      let deploymentTypeListResult = res.data;
      setDeploymentTypeList(deploymentTypeListResult);
    });

    //Get country List
    const countryUrl = `country/list`;
    getFetchData(countryUrl).then((res) => {
      let countryList = res.data;
      setCountryList(countryList);
    });
  }, []);

  const doPaginationChange = (event, value) => {
    setPageIndex(value);
  };
  //Dialog popup
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = async (applicantInfo) => {
    await getApplicantInfo(applicantInfo.applicant_id); // Get applicant info
    setApplicantData(applicantInfo); //View More data
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setApplicantData({});
    setOpenDialog(false);
  };

  window.addEventListener("resize", () => {
    if (window.innerWidth < 992) {
      setPageResize(true);
    } else {
      setPageResize(false);
    }
  });
  const getFetchData = async (settingUrl) => {
    return await fetchData(settingUrl);
  };

  //Get the source lead Name : Comment as of now due to popup design loading issue 12 Feb 2024
  const getApplicantInfo = async (applicantId) => {
    const res = await getFetchData(`applicant/${applicantId}`);
    setApplicantInfo(res);
  };

  const displayTypoInfo = (label, value) => {
    return (
      <>
        <Grid item xs={6} md={6} lg={6}>
          <MDTypography variant="h6" fontWeight="medium">
            {label}
          </MDTypography>
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <MDTypography variant="h6" fontWeight="regular">
            {value}
          </MDTypography>
        </Grid>
      </>
    );
  };

  const getCountryFlag = (countryInfo) => {
    return (
      <>
        <MDTypography variant="h6" fontWeight="regular">
          <MDAvatar
            src={`https://flagcdn.com/w40/${countryInfo?.code.toLowerCase()}.png`}
            name={countryInfo?.name}
            size="md"
            style={{ display: "inline-block", overflow: "visible" }}
            sx={{ width: 20, height: 20 }}
          />{" "}
          {countryInfo?.name}
        </MDTypography>
      </>
    );
  };
  //Show view more Data popup
  const ViewMoreRa = ({ openDialog, applicantData, handleCloseDialog }) => {
    let {
      applicant_code,
      area,
      image,
      salutation,
      first_name,
      last_name,
      passport_number,
      date_of_birth,
      mobile_number,
      email_id,
      whatsapp_number,
      emergency_contact_number,
      source_of_lead_id,
      mobile_code_id,
      whatsapp_code_id,
      emergency_code_id,
      emergency_contact_name,
      emergency_contact_relation,
      address,
      landmark,
      postal_code,
      city,
      state,
      country_id,
      mobile_code_info,
      whatsapp_code_info,
      emergency_code_info,
      country_info,
      religion_info,
      position_info,
      source_of_lead_info,
      company_info,
      issue_country_info,
      issue_date,
      expiry_date,
      source_of_lead_type: stype,
      document_list,
      deployment_visa_issue_date,
      deployment_visa_expiry_date,
      deployment_date,
      deployment_type_info,
      deployment_fine_currency_info,
      deployment_fine_payment,
      deployment_remarks,
    } = applicantData;
    let leadSource = applicantInfo?.sourceOfLead;
    let displaySourceOfLead = "";
    if (stype == "ra") {
      displaySourceOfLead = leadSource?.ra_name;
    } else if (stype == "company") {
      displaySourceOfLead = leadSource?.name;
    } else if (stype == "user" || stype == "applicant") {
      displaySourceOfLead =
        leadSource?.first_name + " " + leadSource?.last_name;
    }
    return (
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        fullScreen={window.innerWidth < 768 ? true : false}
      >
        <DialogTitle sx={{ m: 0 }} id="customized-dialog-title">
          {passport_number}
          <div className="mdsCountryInfo">
            <span>{issue_country_info?.name}</span>{" "}
            <MDAvatar
              src={`https://flagcdn.com/w40/${issue_country_info?.code.toLowerCase()}.png`}
              name={issue_country_info?.name}
              size="md"
              style={{ display: "inline-block", overflow: "visible" }}
              sx={{ width: 20, height: 20 }}
            />
          </div>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container p={2} spacing={1}>
            {displayTypoInfo("Area", area)}
            {displayTypoInfo(
              "Image",
              <MDAvatar
                src={process.env.REACT_APP_URI_PATH + image}
                name={image}
                size="md"
                sx={{ width: 56, height: 56 }}
                variant="rounded"
              />
            )}
            {displayTypoInfo(
              "Name",
              salutation + " " + first_name + " " + last_name
            )}
            {displayTypoInfo("Date of birth", dateFormat(date_of_birth))}
            {displayTypoInfo("Issue Date", dateFormat(issue_date))}
            {displayTypoInfo(
              "Expiry Date",
              expiry_date ? dateFormat(expiry_date) : "-"
            )}
            {displayTypoInfo("E-mail Id", email_id)}
            {displayTypoInfo("Religion", religion_info?.setting_value)}
            {displayTypoInfo(
              "Mobile Number",
              mobile_code_info?.dial_code + " " + mobile_number
            )}
            {displayTypoInfo(
              "Whatsapp Number",
              whatsapp_code_info?.dial_code + " " + whatsapp_number
            )}
            {displayTypoInfo(
              "Emergency Contact Number",
              emergency_code_info?.dial_code + " " + emergency_contact_number
            )}
            {displayTypoInfo("Emergency Contact Name", emergency_contact_name)}
            {displayTypoInfo(
              "Emergency Contact Relation",
              emergency_contact_relation
            )}
            {displayTypoInfo("Company", company_info?.name)}
            {displayTypoInfo("Position", position_info?.setting_value)}
            {displayTypoInfo(
              "Type Source of Lead",
              stype === "ra"
                ? "Recruiting Agency"
                : stype === "company"
                ? "Company"
                : stype === "user"
                ? "Staff/Partner"
                : "Applicant"
            )}
            {displayTypoInfo("Source of Lead", displaySourceOfLead)}
            {displayTypoInfo("Address", address)}
            {displayTypoInfo("Landmark", landmark)}
            {displayTypoInfo("Postal Code", postal_code)}
            {displayTypoInfo("City", city)}
            {displayTypoInfo("State", state)}
            {displayTypoInfo("Country", getCountryFlag(country_info))}

            <Grid item xs={12} md={12} lg={12}>
              <MDTypography variant="h6" fontWeight="medium">
                <MDBox
                  py={1}
                  px={1}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Deployment Details
                  </MDTypography>
                </MDBox>
              </MDTypography>
            </Grid>

            {displayTypoInfo(
              "Visa Issue Date",
              deployment_visa_issue_date
                ? dateFormat(deployment_visa_issue_date)
                : "-"
            )}
            {displayTypoInfo(
              "Visa Expiry Date",
              deployment_visa_expiry_date
                ? dateFormat(deployment_visa_expiry_date)
                : "-"
            )}
            {displayTypoInfo(
              "Deployment Date",
              deployment_date ? dateFormat(deployment_date) : "-"
            )}
            {displayTypoInfo("Status", deployment_type_info?.setting_value)}
            {displayTypoInfo(
              "Fine Payment",
              deployment_fine_payment
                ? deployment_fine_currency_info?.currency_code +
                    " " +
                    deployment_fine_payment * 1
                : "-"
            )}
            {displayTypoInfo(
              "Remarks",
              deployment_remarks ? deployment_remarks : "-"
            )}

            <Grid item xs={12} md={12} lg={12}>
              <MDTypography variant="h6" fontWeight="medium">
                {document_list?.length ? (
                  <MDBox
                    py={1}
                    px={1}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      List of Documents
                    </MDTypography>
                  </MDBox>
                ) : (
                  ""
                )}
              </MDTypography>
            </Grid>
            {document_list?.map((document, i) => {
              const {
                document_name,
                issue_date,
                expiry_date,
                upload_document,
                issue_country_info,
              } = document;
              return (
                <>
                  <Grid item xs={12} md={12} lg={12}>
                    <MDTypography variant="h6" fontWeight="medium">
                      {i + 1}.
                    </MDTypography>
                  </Grid>
                  {displayTypoInfo("Name", document_name)}
                  {displayTypoInfo(
                    "Issue Country",
                    getCountryFlag(issue_country_info)
                  )}
                  {displayTypoInfo("Issue Date", dateFormat(issue_date))}
                  {displayTypoInfo(
                    "Expiry Date",
                    expiry_date ? dateFormat(expiry_date) : "-"
                  )}
                  {displayTypoInfo(
                    "Document",
                    <Link
                      to={`${process.env.REACT_APP_URI_PATH}${upload_document}`}
                      target="_blank"
                    >
                      View Document
                    </Link>
                  )}
                </>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialog}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    );
  };

  //START : Search and Filter form data
  //Handle onchange search and filter
  const onChangeSearch = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    setSearchFormData({ ...searchFormData, [name]: val });
  };
  const [csvData, setCsvData] = useState([]);
  const [downloadCsv, setDownloadCsv] = useState(false);
  let csvHeaders = [
    { label: "Country", key: "country" },
    { label: "Passport Number", key: "passport_number" },
    { label: "Area", key: "area" },
    { label: "Applicant Name", key: "applicant_name" },
    { label: "DOB", key: "date_of_birth" },
    { label: "Deployment Date", key: "deployment_date" },
    { label: "Guarantee Period(Days)", key: "guarantee_period" },
    { label: "Deployment Status", key: "deployment_status" },
    { label: "Visa Issue Date", key: "deployment_visa_issue_date" },
    { label: "Visa Exp Date", key: "deployment_visa_expiry_date" },
  ];
  //Prepare the csv data for export
  const exportCsv = async () => {
    let queryUrl = getQueryParams();
    queryUrl = queryUrl + `&download=1`;
    console.log("queryUrl", queryUrl);
    getFetchData(`applicant/list${queryUrl}`).then(async (res) => {
      if (res?.data) {
        let exportData = [];
        await Promise.all(
          res?.data.map((report) => {
            report.applicant_name =
              report.salutation +
              " " +
              report.first_name +
              " " +
              report.last_name;

            report.country = report.country_info?.name;

            report.date_of_birth = report.date_of_birth
              ? dateFormat(report.date_of_birth)
              : "-";

            let deploymentDate = report?.deployment_date
              ? report?.deployment_date
              : report?.deployment_visa_expiry_date;

            report.guarantee_period = deploymentDate
              ? parseInt(
                  report?.company_info?.guarantee_period -
                    dateDifference(deploymentDate, new Date())
                )
              : 0;

            report.deployment_date = report.deployment_date
              ? dateFormat(report.deployment_date)
              : "-";

            report.deployment_status =
              report?.deployment_type_info?.setting_value;

            report.deployment_visa_issue_date =
              report.deployment_visa_issue_date
                ? dateFormat(report.deployment_visa_issue_date)
                : "-";
            report.deployment_visa_expiry_date =
              report.deployment_visa_expiry_date
                ? dateFormat(report.deployment_visa_expiry_date)
                : "-";
            exportData.push(report);
          })
        );

        setCsvData(exportData); //Set the csv data
        setDownloadCsv(true); // Show the CSVDwonload link
        setTimeout(() => {
          csvLink?.current?.link?.click();
          setDownloadCsv(false); // Hide the CSVDwonload link
        }, 100);
      }
    });
  };
  //Prepare the query string for search and filter
  const getQueryParams = () => {
    let queryUrl = `?page=${pageIndex}`;

    queryUrl = searchFormData?.name
      ? queryUrl + "&name=" + searchFormData?.name
      : queryUrl;

    queryUrl = searchFormData?.passport_number
      ? queryUrl + "&passport_number=" + searchFormData?.passport_number
      : queryUrl;

    queryUrl = searchFormData?.country_id?.country_id
      ? queryUrl + "&country_id=" + searchFormData?.country_id?.country_id
      : queryUrl;

    queryUrl = searchFormData.deployment_type_id?.setting_id
      ? queryUrl +
        "&deployment_type_id=" +
        searchFormData.deployment_type_id?.setting_id
      : queryUrl;
    return queryUrl;
  };
  //END : Search and Filter form data

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <CardTopBoxAdd
                title={"Applicants"}
                link={"/save_applicant"}
                showAddButton={showAddButton}
              />
              <MDBox>
                <Grid container p={2} spacing={2}>
                  {/* START : Search and filter block */}
                  <Grid
                    container
                    className="searchFilterBlock"
                    p={2}
                    spacing={2}
                    mt={1}
                  >
                    <Grid item xs={6} md={2}>
                      <MDBox mb={2} className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.country_id}
                          size="small"
                          name="country_id"
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearchFormData({
                                ...searchFormData,
                                ["country_id"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["country_id"]: "",
                              });
                            }
                          }}
                          options={countryList}
                          getOptionLabel={(countryList) =>
                            countryList.name || ""
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Country"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDBox mb={2}>
                        <MDInput
                          name="passport_number"
                          type="text"
                          label="Code"
                          fullWidth
                          value={searchFormData.passport_number}
                          onChange={onChangeSearch}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDBox mb={2}>
                        <MDInput
                          name="name"
                          type="text"
                          label="Applicant Name"
                          fullWidth
                          value={searchFormData.name}
                          onChange={onChangeSearch}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDBox mb={2} className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.deployment_type_id}
                          size="small"
                          name="deployment_type_id"
                          options={deploymentTypeList}
                          getOptionLabel={(deploymentTypeList) =>
                            deploymentTypeList.setting_value || ""
                          }
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearchFormData({
                                ...searchFormData,
                                ["deployment_type_id"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["deployment_type_id"]: "",
                              });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Deployment Status"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <MDBox display="flex" alignItems="center">
                        {/* {downloadCsv && (
                          <>
                            <CSVDownload
                              data={csvData}
                              filename={"my-3534564-file.csv"}
                              headers={csvHeaders}
                              target="_self"
                            />
                          </>
                        )} */}
                        <CSVLink
                          className=" d-none "
                          data={csvData}
                          filename={`Applicants ${dateTimeStamp()}`}
                          headers={csvHeaders}
                          ref={csvLink}
                          target="_self"
                        />
                        <MDButton
                          variant="gradient"
                          color="primary"
                          size="small"
                          type="button"
                          className="searchButton"
                          onClick={exportCsv}
                        >
                          <DownloadIcon /> Export CSV
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                  {/* END : Search and filter block */}

                  {!pageResize && (
                    <Grid item xs={12} md={12} lg={12}>
                      <MDBox
                        borderRadius="lg"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p={1}
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Country
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={1}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Image
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Code
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Name
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Company
                          </MDTypography>
                        </Grid>

                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Guarantee Period(Days)
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Deployment Status
                          </MDTypography>
                        </Grid>

                        <Grid item xs={4} md={4} lg={1}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Action
                          </MDTypography>
                        </Grid>
                      </MDBox>
                    </Grid>
                  )}
                  {applicantsList.map((applicantItem, index) => {
                    let {
                      applicant_id,
                      passport_number,
                      image,
                      applicant_code,
                      country_info,
                      first_name,
                      middle_name,
                      last_name,
                      email_id,
                      mobile_number,
                      mobile_code_info,
                      company_info,
                      deployment_date,
                      deployment_visa_expiry_date,
                      deployment_type_info,
                    } = applicantItem;

                    let deploymentDate = deployment_date
                      ? deployment_date
                      : deployment_visa_expiry_date;

                    const guaranteePeriodDays = deploymentDate
                      ? parseInt(
                          company_info?.guarantee_period -
                            dateDifference(deploymentDate, new Date())
                        )
                      : 0;

                    return (
                      <>
                        {!pageResize ? (
                          <Grid item key={applicant_id} xs={12} md={12} lg={12}>
                            <MDBox
                              borderRadius="lg"
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              p={1}
                              sx={{
                                border: ({
                                  borders: { borderWidth, borderColor },
                                }) => `${borderWidth[1]} solid ${borderColor}`,
                              }}
                            >
                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  <MDAvatar
                                    src={`https://flagcdn.com/w40/${country_info?.code.toLowerCase()}.png`}
                                    name={country_info?.name}
                                    size="md"
                                    style={{
                                      display: "inline-block",
                                      overflow: "visible",
                                    }}
                                    sx={{ width: 20, height: 20 }}
                                  />{" "}
                                  {country_info?.name}
                                </MDTypography>
                              </Grid>
                              <Grid item xs={4} md={4} lg={1}>
                                <MDAvatar
                                  src={process.env.REACT_APP_URI_PATH + image}
                                  name={image}
                                  size="md"
                                  sx={{ width: 56, height: 56 }}
                                  variant="rounded"
                                />
                              </Grid>
                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {passport_number}
                                </MDTypography>
                              </Grid>

                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {first_name + " " + last_name}
                                </MDTypography>
                              </Grid>

                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {company_info?.name}
                                </MDTypography>
                              </Grid>

                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {guaranteePeriodDays}
                                </MDTypography>
                              </Grid>
                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {deployment_type_info?.setting_value}
                                </MDTypography>
                              </Grid>

                              <Grid item xs={4} md={4} lg={1}>
                                <IconButton
                                  size="small"
                                  disableRipple
                                  color="inherit"
                                  onClick={() =>
                                    handleOpenDialog(applicantItem)
                                  }
                                >
                                  <RemoveRedEye />
                                </IconButton>
                                {showEditButton && (
                                  <IconButton
                                    size="small"
                                    disableRipple
                                    color="inherit"
                                    onClick={() =>
                                      navigate(
                                        `/save_applicant/${applicant_id}`
                                      )
                                    }
                                  >
                                    <Edit />
                                  </IconButton>
                                )}
                                {showDeleteButton && (
                                  <IconButton
                                    size="small"
                                    disableRipple
                                    color="inherit"
                                  >
                                    <Delete />
                                  </IconButton>
                                )}
                              </Grid>
                            </MDBox>
                          </Grid>
                        ) : (
                          <MobileList
                            key={applicant_id}
                            applicantItem={applicantItem}
                            index={index + 1}
                            handleOpenDialog={handleOpenDialog}
                            showEditButton={showEditButton}
                            showDeleteButton={showDeleteButton}
                          />
                        )}
                      </>
                    );
                  })}
                </Grid>
                <Grid item xs={12} md={3}>
                  <Stack spacing={2} mb={2} ml={2}>
                    <Pagination
                      count={pageCount}
                      page={pageIndex}
                      onChange={doPaginationChange}
                      variant="outlined"
                    />
                  </Stack>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <ViewMoreRa
        openDialog={openDialog}
        applicantData={applicantData}
        handleCloseDialog={handleCloseDialog}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default ApplicantsList;
