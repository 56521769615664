// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { fetchData } from "utils/clientFunctions";
import { useEffect, useRef, useState } from "react";
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import MDInput from "components/MDInput";
import { Delete, Edit, RemoveRedEye } from "@mui/icons-material";
import { useAuth } from "utils/auth";
import pxToRem from "assets/theme/functions/pxToRem";
import { dateFormat } from "utils/clientFunctions";
import { CardTopBoxAdd } from "layouts/commonComponents/cardTopBoxAdd";
import TaskRes from "../components/TaskRes";
import { useSelector, useDispatch } from "react-redux";
import { getPermissions, selectPermissions } from "reducers/permissionSlice";
import { firstLetterCapital } from "utils/clientFunctions";
import { liveSearchData } from "layouts/commonComponents/liveSearchData";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { CSVDownload, CSVLink } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";
import MDButton from "components/MDButton";
import { dateTimeStamp } from "utils/clientFunctions";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function TasksList() {
  const csvLink = useRef();
  const [pageIndex, setPageIndex] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [pageResize, setPageResize] = useState(
    window.innerWidth < 992 ? true : false
  );
  const navigate = useNavigate();

  //Start : For permission
  const dispatch = useDispatch();
  var permissions = useSelector(selectPermissions);
  if (
    !(
      permissions?.user_id === 1 || permissions?.permission?.includes("tskView")
    )
  ) {
    navigate("/dashboard");
  }
  let showAddButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("tskCreate")
      ? true
      : false;
  let showEditButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("tskEdit")
      ? true
      : false;
  let showDeleteButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("tskDelete")
      ? true
      : false;
  useEffect(() => {
    dispatch(getPermissions());
  }, []);
  //End : For permission

  const [List, setList] = useState([]);
  const [raData, setRaData] = useState({});

  const [companies, setCompanyList] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [usersList, setUserList] = useState([]);
  const [statusList, setstatusList] = useState([]);

  const { logout } = useAuth();

  const initialLoadData = async () => {
    let queryUrl = getQueryParams();
    const url = `task/list${queryUrl}`;
    const data = await fetchData(url);
    if (
      data?.name === "JsonWebTokenError" ||
      data?.name === "TokenExpiredError" ||
      data?.message === "You are not authorized"
    ) {
      logout();
    }
    if (data && data.data) {
      let tasksCount = Math.ceil(data.count / 10);
      setPageCount(tasksCount);
      setList(data.data || []);
    }
  };

  //Search & Filter initial data
  const [searchFormData, setSearchFormData] = useState({
    name: "",
    created_by_user_id: "",
    start_date: "",
    end_date: "",
    priority: "",
    status_id: "",
  });

  useEffect(() => {
    initialLoadData();
  }, [
    pageIndex,
    searchFormData.name,
    searchFormData.created_by_user_id,
    searchFormData.start_date,
    searchFormData.end_date,
    searchFormData.priority,
    searchFormData.status_id,
  ]);
  const searchSpot = (value, key) => {
    liveSearchData({
      countryId: null,
      value,
      key,
      setCompanyList,
      setUserList,
    });
  };

  const getFetchData = async (settingUrl) => {
    return await fetchData(settingUrl);
  };

  useEffect(() => {
    const reasonUrl = `setting/type/9`;
    getFetchData(reasonUrl).then((res) => {
      let reasonList = res.data;
      setReasons(reasonList);
    });

    const userUrl = `user/list?page=1`;
    getFetchData(userUrl).then((res) => {
      let userList = res.data;
      setUserList(userList);
    });

    const companyUrl = `company/list?page=1`;
    getFetchData(companyUrl).then((res) => {
      let companyList = res.data;
      setCompanyList(companyList);
    });

    const statusUrl = `setting/type/8`;
    getFetchData(statusUrl).then((res) => {
      let statusList = res.data;
      setstatusList(statusList);
    });
  }, []);

  const doPaginationChange = (event, value) => {
    setPageIndex(value);
  };

  //Dialog popup
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = (raInfo) => {
    setRaData(raInfo);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setRaData({});
    setOpenDialog(false);
  };

  window.addEventListener("resize", () => {
    if (window.innerWidth < 992) {
      setPageResize(true);
    } else {
      setPageResize(false);
    }
  });

  const captureDays = (end_date) => {
    if (end_date) {
      const endDate = new Date(end_date);
      const today = new Date(); // Get today's date

      // Calculate the difference in milliseconds between the end date and today's date
      const timeDifference = endDate.getTime() - today.getTime();

      // Convert milliseconds to days
      const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
      const formattedDaysDifference = String(Math.abs(daysDifference)).padStart(
        2,
        "0"
      );
      if (daysDifference >= 0) {
        return (
          <Chip label={formattedDaysDifference} color="success" size="small" />
        );
      } else if (timeDifference < 0) {
        return (
          <Chip label={formattedDaysDifference} color="error" size="small" />
        );
      }
    }
  };

  const ViewMoreRa = ({ openDialog, raData, handleCloseDialog }) => {
    const {
      task_id,
      name,
      priority,
      start_date,
      end_date,
      upload_document,
      remarks,
      reason_info,
      company_info,
      country_info,
      assigned_user_info,
      status_info,
      created_user_info,
    } = raData;

    return (
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        fullScreen={window.innerWidth < 768 ? true : false}
      >
        <DialogTitle sx={{ m: 0 }} id="customized-dialog-title">
          {"Task Details"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container px={2} spacing={1}>
            <ViewTypo title={"Task Name"} res={name} />
            <ViewTypo
              title={"Initiated By"}
              res={
                created_user_info?.first_name +
                " " +
                created_user_info?.last_name
              }
            />
            <ViewTypo
              title={"Assigned To"}
              res={
                assigned_user_info?.first_name +
                " " +
                assigned_user_info?.last_name
              }
            />
            <ViewTypo title={"Reason"} res={reason_info?.setting_value} />
            <ViewTypo title={"Company"} res={company_info?.name} />
            <ViewTypo title={"Country"} res={country_info?.name} />
            <ViewTypo title={"Priority"} res={priority} />
            <ViewTypo title={"Status"} res={status_info?.setting_value} />
            <ViewTypo
              title={"Start Date"}
              res={start_date ? dateFormat(start_date) : ""}
            />
            <ViewTypo
              title={"End Date"}
              res={end_date ? dateFormat(end_date) : ""}
            />
            <ViewTypo title={"Remarks  "} res={remarks} />
            {upload_document && (
              <ViewTypo
                title={"Document  "}
                res={
                  <Link
                    to={`${process.env.REACT_APP_URI_PATH}${upload_document}`}
                    target="_blank"
                  >
                    View Document
                  </Link>
                }
              />
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialog}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    );
  };

  //START : Search and Filter form data
  //Handle onchange search and filter
  const onChangeSearch = (event) => {
    let name = event.target.name;
    let val = event.target.value;
    setSearchFormData({ ...searchFormData, [name]: val });
  };
  const [csvData, setCsvData] = useState([]);
  const [downloadCsv, setDownloadCsv] = useState(false);

  let csvHeaders = [
    { label: "Task Name", key: "name" },
    { label: "Initiated By", key: "initiated_by" },
    { label: "Assigned To", key: "assigned_by" },
    { label: "Reason", key: "reason" },
    { label: "Company", key: "company" },
    { label: "Country", key: "country" },
    { label: "Priority", key: "priority" },
    { label: "Status", key: "status" },
    { label: "Start Date", key: "start_date" },
    { label: "End Date", key: "end_date" },
  ];
  //Prepare the csv data for export
  const exportCsv = async () => {
    let queryUrl = getQueryParams();
    queryUrl = queryUrl + `&download=1`;
    getFetchData(`task/list${queryUrl}`).then(async (res) => {
      if (res?.data) {
        let exportData = [];
        await Promise.all(
          res?.data.map((report) => {
            report.initiated_by =
              report?.created_user_info?.first_name +
              " " +
              report?.created_user_info?.last_name;

            report.assigned_by =
              report?.assigned_user_info?.first_name +
              " " +
              report?.assigned_user_info?.last_name;

            report.reason = report?.reason_info?.setting_value;
            report.company = report?.company_info?.name;
            report.country = report?.country_info?.name;

            report.status = report?.status_info?.setting_value;

            report.start_date = report?.start_date
              ? dateFormat(report?.start_date)
              : "-";
            report.end_date = report?.end_date
              ? dateFormat(report?.end_date)
              : "-";
            exportData.push(report);
          })
        );
        setCsvData(exportData); //Set the csv data
        setDownloadCsv(true); // Show the CSVDwonload link
        setTimeout(() => {
          csvLink?.current?.link?.click();
          setDownloadCsv(false); // Hide the CSVDwonload link
        }, 100);
      }
    });
  };
  //Prepare the query string for search and filter
  const getQueryParams = () => {
    let queryUrl = `?page=${pageIndex}`;

    queryUrl = searchFormData?.name
      ? queryUrl + "&name=" + searchFormData?.name
      : queryUrl;

    queryUrl = searchFormData?.created_by_user_id?.user_id
      ? queryUrl +
        "&created_by_user_id=" +
        searchFormData?.created_by_user_id?.user_id
      : queryUrl;

    queryUrl = searchFormData?.start_date
      ? queryUrl + "&start_date=" + searchFormData?.start_date
      : queryUrl;

    queryUrl = searchFormData?.end_date
      ? queryUrl + "&end_date=" + searchFormData?.end_date
      : queryUrl;

    queryUrl = searchFormData?.priority?.value
      ? queryUrl + "&priority=" + searchFormData?.priority?.value
      : queryUrl;

    queryUrl = searchFormData?.status_id?.setting_id
      ? queryUrl + "&status_id=" + searchFormData?.status_id?.setting_id
      : queryUrl;

    return queryUrl;
  };
  //END : Search and Filter form data
  const priorityOptions = [
    { label: "Low", value: "Low" },
    { label: "Medium", value: "Medium" },
    { label: "High", value: "High" },
  ];

  const HeadTypo = ({ title, lg }) => {
    return (
      <Grid item xs={4} md={4} lg={lg}>
        <MDTypography fontSize={pxToRem(14)} fontWeight="medium">
          {title}
        </MDTypography>
      </Grid>
    );
  };

  const BodyTypo = ({ title, lg }) => {
    return (
      <Grid item xs={4} md={4} lg={lg}>
        <MDTypography fontSize={pxToRem(14)} fontWeight="regular">
          {title}
        </MDTypography>
      </Grid>
    );
  };

  const ViewTypo = ({ title, res }) => {
    return (
      <>
        <Grid item xs={6} md={6} lg={6}>
          <MDTypography variant="h6" fontWeight="medium">
            {title}
          </MDTypography>
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <MDTypography variant="h6" fontWeight="regular">
            {res}
          </MDTypography>
        </Grid>
      </>
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <CardTopBoxAdd
                title={"Tasks"}
                link={"/taskdata"}
                showAddButton={showAddButton}
              />
              <MDBox>
                <Grid container p={2} spacing={2}>
                  {/* START : Search and filter block */}
                  <Grid
                    container
                    className="searchFilterBlock"
                    p={2}
                    spacing={2}
                    mt={1}
                  >
                    <Grid item xs={6} md={2}>
                      <MDBox mb={2}>
                        <MDInput
                          name="name"
                          type="text"
                          label="Name"
                          fullWidth
                          value={searchFormData.name}
                          onChange={onChangeSearch}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDBox mb={2} className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.priority}
                          size="small"
                          name="priority"
                          options={priorityOptions}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearchFormData({
                                ...searchFormData,
                                ["priority"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["priority"]: "",
                              });
                            }
                          }}
                          getOptionLabel={(priorityOptions) =>
                            priorityOptions.label || ""
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Priority"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDBox mb={2} className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.created_by_user_id}
                          size="small"
                          name="created_by_user_id"
                          options={usersList}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearchFormData({
                                ...searchFormData,
                                ["created_by_user_id"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["created_by_user_id"]: "",
                              });
                            }
                          }}
                          getOptionLabel={(usersList) =>
                            (usersList &&
                              usersList.first_name +
                                " " +
                                usersList.last_name) ||
                            ""
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select User"
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) =>
                                searchSpot("user", e.target.value)
                              }
                            />
                          )}
                        />
                      </MDBox>
                    </Grid>

                    <Grid item xs={6} md={2}>
                      <MDBox mb={2} className="mdsAutocompleteGrid">
                        <Autocomplete
                          value={searchFormData.status_id}
                          size="small"
                          name="status_id"
                          options={statusList}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearchFormData({
                                ...searchFormData,
                                ["status_id"]: newValue,
                              });
                            } else {
                              setSearchFormData({
                                ...searchFormData,
                                ["status_id"]: "",
                              });
                            }
                          }}
                          getOptionLabel={(statusList) =>
                            statusList.setting_value || ""
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Status *"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDBox mb={2}>
                        <MDInput
                          name="start_date"
                          type="date"
                          label="Start Date"
                          fullWidth
                          value={searchFormData.start_date}
                          onChange={onChangeSearch}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDBox mb={2}>
                        <MDInput
                          name="end_date"
                          type="date"
                          label="End Date"
                          fullWidth
                          value={searchFormData.end_date}
                          onChange={onChangeSearch}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <MDBox display="flex" alignItems="center">
                        {/* {downloadCsv && (
                          <>
                            <CSVDownload
                              data={csvData}
                              filename={"my-3534564-file.csv"}
                              headers={csvHeaders}
                              target="_self"
                            />
                          </>
                        )} */}
                        <CSVLink
                          className=" d-none "
                          data={csvData}
                          filename={`Tasks ${dateTimeStamp()}`}
                          headers={csvHeaders}
                          ref={csvLink}
                          target="_self"
                        />
                        <MDButton
                          variant="gradient"
                          color="primary"
                          size="small"
                          type="button"
                          className="searchButton"
                          onClick={exportCsv}
                        >
                          <DownloadIcon /> Export CSV
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                  {/* END : Search and filter block */}

                  {!pageResize && (
                    <Grid item xs={12} md={12} lg={12}>
                      <MDBox
                        borderRadius="lg"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p={1}
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <HeadTypo title="Name" lg={2} />
                        {/* <HeadTypo title="Initiated By" lg={2} /> */}
                        <HeadTypo title="Priority" lg={1} />
                        <HeadTypo title="Assigned To" lg={2} />
                        <HeadTypo title="Start - end date" lg={3} />
                        <HeadTypo title="Days" lg={1} />
                        <HeadTypo title="Status" lg={1} />
                        <HeadTypo title="Action" lg={1} />
                      </MDBox>
                    </Grid>
                  )}
                  {List.map((raItem, index) => {
                    let {
                      task_id,
                      name,
                      priority,
                      start_date,
                      end_date,
                      remarks,
                      reason_info,
                      company_info,
                      country_info,
                      assigned_user_info,
                      status_info,
                      created_user_info,
                    } = raItem;
                    return (
                      <>
                        {!pageResize ? (
                          <Grid item key={task_id} xs={12} md={12} lg={12}>
                            <MDBox
                              borderRadius="lg"
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              p={1}
                              sx={{
                                border: ({
                                  borders: { borderWidth, borderColor },
                                }) => `${borderWidth[1]} solid ${borderColor}`,
                              }}
                            >
                              <BodyTypo title={name} lg={2} />
                              {/* <BodyTypo
                                title={
                                  created_user_info?.first_name +
                                  " " +
                                  created_user_info?.last_name
                                }
                                lg={2}
                              /> */}
                              <BodyTypo title={priority} lg={1} />
                              <BodyTypo
                                title={
                                  assigned_user_info?.first_name +
                                  " " +
                                  assigned_user_info?.last_name
                                }
                                lg={2}
                              />
                              <BodyTypo
                                title={
                                  (start_date ? dateFormat(start_date) : "") +
                                  " - " +
                                  (end_date ? dateFormat(end_date) : "")
                                }
                                lg={3}
                              />
                              <BodyTypo
                                title={
                                  status_info?.setting_value != "Completed" && (
                                    <>{captureDays(end_date)}</>
                                  )
                                }
                                lg={1}
                              />
                              <BodyTypo
                                title={status_info?.setting_value}
                                lg={1}
                              />
                              <Grid item xs={4} md={4} lg={1}>
                                <IconButton
                                  size="small"
                                  disableRipple
                                  color="inherit"
                                  onClick={() => handleOpenDialog(raItem)}
                                >
                                  <RemoveRedEye />
                                </IconButton>
                                {showEditButton && (
                                  <IconButton
                                    size="small"
                                    disableRipple
                                    color="inherit"
                                    onClick={() =>
                                      navigate(`/taskdata/${task_id}`)
                                    }
                                  >
                                    <Edit />
                                  </IconButton>
                                )}
                                {showDeleteButton && (
                                  <IconButton
                                    size="small"
                                    disableRipple
                                    color="inherit"
                                  >
                                    <Delete />
                                  </IconButton>
                                )}
                              </Grid>
                            </MDBox>
                          </Grid>
                        ) : (
                          <TaskRes
                            key={task_id}
                            raItem={raItem}
                            index={index + 1}
                            handleOpenDialog={handleOpenDialog}
                            showEditButton={showEditButton}
                            showDeleteButton={showDeleteButton}
                          />
                        )}
                      </>
                    );
                  })}
                </Grid>
                <Grid item xs={12} md={3}>
                  <Stack spacing={2} mb={2} ml={2}>
                    <Pagination
                      count={pageCount}
                      page={pageIndex}
                      onChange={doPaginationChange}
                      variant="outlined"
                    />
                  </Stack>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <ViewMoreRa
        openDialog={openDialog}
        raData={raData}
        handleCloseDialog={handleCloseDialog}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default TasksList;
