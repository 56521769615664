/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Settings from "layouts/settings";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import CreateRa from "layouts/recruiting_agency/create";
//import UpdateRa from "layouts/recruiting_agency/update";
import RaList from "layouts/recruiting_agency/list";

import CreateCompany from "layouts/company/create";
import CompanyList from "layouts/company/list";

import CreateApplicant from "layouts/applicant/create";
import ApplicantsList from "layouts/applicant/list";
// @mui icons
import Icon from "@mui/material/Icon";
import StaffsList from "layouts/staffs/list";
import LeadsList from "layouts/leads/list";
import LeadData from "layouts/leads/create";
import StaffData from "layouts/staffs/create";
import TaskData from "layouts/tasks/create";
import TasksList from "layouts/tasks/list";
import AccountData from "layouts/accounts/create";
import AccountsList from "layouts/accounts/list";
import MeetingData from "layouts/meetings/create";
import MeetingsList from "layouts/meetings/list";
import DealsList from "layouts/deals/list";
import DealData from "layouts/deals/create";
import CallReport from "layouts/call_report/save";
import CallReportList from "layouts/call_report/list";
import BookingList from "layouts/booking/list";
import SaveBooking from "layouts/booking/save";
import InterviewList from "layouts/interview/list";
import SaveInterview from "layouts/interview/save";
import AttestationList from "layouts/attestation/list";
import SaveAttestation from "layouts/attestation/save";
import DeploymentList from "layouts/deployment/list";
import SaveDeployment from "layouts/deployment/save";
import TaxList from "layouts/tax/list";
import SaveTax from "layouts/tax/save";
import InvoiceList from "layouts/invoice/list";
import SaveInvoice from "layouts/invoice/save";
import ViewInvoice from "layouts/invoice/list/view";
import RevenueReport from "layouts/reports/revenue";
import ExpenseReport from "layouts/reports/expense";
import ClientGrowthReport from "layouts/reports/client_growth";
import CandidatePlacementReport from "layouts/reports/candidate_placement";
import CreditReport from "layouts/reports/credit_note";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Tables",
    key: "tables",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/tables",
    component: <Tables />,
  },
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing",
    component: <Billing />,
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/settings",
    component: <Settings />,
  },

  {
    type: "collapse",
    name: "Create Recruiting Agency",
    key: "CreateRa",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/create_ra",
    component: <CreateRa />,
  },
  {
    type: "collapse",
    name: "Update Recruiting Agency",
    key: "CreateRa",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/create_ra/:raId",
    component: <CreateRa />,
  },
  // {
  //  type: "collapse",
  //   name: "Update Recruiting Agency",
  //   key: "UpdateRa",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/update_ra/:ra_id",
  //   component: <UpdateRa />,
  // },
  {
    type: "collapse",
    name: "List of Recruiting Agency",
    key: "ra_list",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/ra_list",
    component: <RaList />,
  },
  {
    type: "collapse",
    name: "List of Company",
    key: "company_list",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/company_list",
    component: <CompanyList />,
  },
  {
    type: "collapse",
    name: "Create Company",
    key: "CreateCompany",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/create_company",
    component: <CreateCompany />,
  },
  {
    type: "collapse",
    name: "Create Company",
    key: "CreateCompany",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/create_company/:companyId",
    component: <CreateCompany />,
  },
  {
    type: "collapse",
    name: "Create Applicant",
    key: "CreateApplicant",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/save_applicant",
    component: <CreateApplicant />,
  },

  {
    type: "collapse",
    name: "Create Applicant",
    key: "CreateApplicant",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/save_applicant/:applicantId",
    component: <CreateApplicant />,
  },
  {
    type: "collapse",
    name: "List of Applicants",
    key: "applicant",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/applicant",
    component: <ApplicantsList />,
  },
  {
    type: "collapse",
    name: "Staffs",
    key: "staffs",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/staffs",
    component: <StaffsList />,
  },
  {
    type: "collapse",
    name: "StaffData",
    key: "staffdata",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/staffdata",
    component: <StaffData />,
  },
  {
    type: "collapse",
    name: "StaffData",
    key: "staffdata",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/staffdata/:id",
    component: <StaffData />,
  },
  {
    type: "collapse",
    name: "Leads",
    key: "lead",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/lead",
    component: <LeadsList />,
  },
  {
    type: "collapse",
    name: "LeadData",
    key: "leaddata",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/leaddata",
    component: <LeadData />,
  },
  {
    type: "collapse",
    name: "LeadData",
    key: "leaddata",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/leaddata/:id",
    component: <LeadData />,
  },
  {
    type: "collapse",
    name: "Tasks",
    key: "task",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/task",
    component: <TasksList />,
  },
  {
    type: "collapse",
    name: "taskData",
    key: "taskdata",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/taskdata",
    component: <TaskData />,
  },
  {
    type: "collapse",
    name: "taskData",
    key: "taskdata",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/taskdata/:id",
    component: <TaskData />,
  },
  {
    type: "collapse",
    name: "Accounts",
    key: "account",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/account",
    component: <AccountsList />,
  },
  {
    type: "collapse",
    name: "AccountData",
    key: "accountdata",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/accountdata",
    component: <AccountData />,
  },
  {
    type: "collapse",
    name: "AccountData",
    key: "accountdata",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/accountdata/:id",
    component: <AccountData />,
  },
  {
    type: "collapse",
    name: "Meetings",
    key: "meeting",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/meeting",
    component: <MeetingsList />,
  },
  {
    type: "collapse",
    name: "meetingData",
    key: "meetingdata",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/meetingdata",
    component: <MeetingData />,
  },
  {
    type: "collapse",
    name: "meetingData",
    key: "meetingdata",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/meetingdata/:id",
    component: <MeetingData />,
  },
  {
    type: "collapse",
    name: "Deals",
    key: "deal",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/deal",
    component: <DealsList />,
  },
  {
    type: "collapse",
    name: "dealData",
    key: "dealdata",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/dealdata",
    component: <DealData />,
  },
  {
    type: "collapse",
    name: "dealData",
    key: "dealdata",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/dealdata/:id",
    component: <DealData />,
  },
  {
    type: "collapse",
    name: "Call Report",
    key: "call_report_list",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/call_report_list",
    component: <CallReportList />,
  },
  {
    type: "collapse",
    name: "callReportData",
    key: "callReportData",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/call_report/:id",
    component: <CallReport />,
  },
  {
    type: "collapse",
    name: "callReportData",
    key: "callReportData",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/call_report",
    component: <CallReport />,
  },
  {
    type: "collapse",
    name: "Booking",
    key: "booking_list",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/booking_list",
    component: <BookingList />,
  },
  {
    type: "collapse",
    name: "SaveBooking",
    key: "SaveBooking",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/booking/:id",
    component: <SaveBooking />,
  },
  {
    type: "collapse",
    name: "SaveBooking",
    key: "SaveBooking",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/booking",
    component: <SaveBooking />,
  },
  {
    type: "collapse",
    name: "Interview",
    key: "interview_list",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/interview_list",
    component: <InterviewList />,
  },
  {
    type: "collapse",
    name: "SaveInterview",
    key: "SaveInterview",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/interview/:id",
    component: <SaveInterview />,
  },
  {
    type: "collapse",
    name: "SaveInterview",
    key: "SaveInterview",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/interview",
    component: <SaveInterview />,
  },
  {
    type: "collapse",
    name: "Attestation",
    key: "attestation_list",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/attestation_list",
    component: <AttestationList />,
  },
  {
    type: "collapse",
    name: "SaveAttestation",
    key: "SaveAttestation",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/attestation/:id",
    component: <SaveAttestation />,
  },
  {
    type: "collapse",
    name: "SaveAttestation",
    key: "SaveAttestation",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/attestation",
    component: <SaveAttestation />,
  },
  // {
  //   type: "collapse",
  //   name: "Attestation",
  //   key: "deployment_list",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/deployment_list",
  //   component: <DeploymentList />,
  // },
  // {
  //   type: "collapse",
  //   name: "SaveDeployment",
  //   key: "SaveDeployment",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/deployment/:id",
  //   component: <SaveDeployment />,
  // },
  // {
  //   type: "collapse",
  //   name: "SaveDeployment",
  //   key: "SaveDeployment",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/deployment",
  //   component: <SaveDeployment />,
  // },
  {
    type: "collapse",
    name: "Tax",
    key: "tax_list",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/tax_list",
    component: <TaxList />,
  },
  {
    type: "collapse",
    name: "CreateTax",
    key: "CreateTax",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/tax/:id",
    component: <SaveTax />,
  },
  {
    type: "collapse",
    name: "UpdateTax",
    key: "UpdateTax",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/tax",
    component: <SaveTax />,
  },

  {
    type: "collapse",
    name: "Invoice",
    key: "invoice_list",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/invoice_list",
    component: <InvoiceList />,
  },
  {
    type: "collapse",
    name: "ViewInvoice",
    key: "ViewInvoice",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/invoice/view/:id",
    component: <ViewInvoice />,
  },
  {
    type: "collapse",
    name: "CreateInvoice",
    key: "CreateInvoice",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/invoice/:id",
    component: <SaveInvoice />,
  },
  {
    type: "collapse",
    name: "UpdateInvoice",
    key: "UpdateInvoice",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/invoice",
    component: <SaveInvoice />,
  },
  {
    type: "collapse",
    name: "RevenueReport",
    key: "RevenueReport",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/revenue_report",
    component: <RevenueReport />,
  },
  {
    type: "collapse",
    name: "ExpenseReport",
    key: "ExpenseReport",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/expense_report",
    component: <ExpenseReport />,
  },
  {
    type: "collapse",
    name: "ClientGrowthReport",
    key: "ClientGrowthReport",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/client_growth_report",
    component: <ClientGrowthReport />,
  },
  {
    type: "collapse",
    name: "CandidatePlacementReport",
    key: "CandidatePlacementReport",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/candidate_placement_report",
    component: <CandidatePlacementReport />,
  },
  {
    type: "collapse",
    name: "CreditNote",
    key: "CreditNote",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/credit_note",
    component: <CreditReport />,
  },
];

export default routes;
