import { useContext, useEffect } from "react";

// react-router-dom components
import { useLocation, NavLink, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
//import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";
import { useSelector, useDispatch } from "react-redux";
import { getPermissions, selectPermissions } from "reducers/permissionSlice";
// Material Dashboard 2 React context

import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import { useAuth } from "utils/auth";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";

import { PermissionContext } from "utils/PermissionContext";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const permissionData = useContext(PermissionContext);
  const permissions = permissionData?.permission;
  const userId = permissionData?.user_id;

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    sidenavColor,
  } = controller;
  const location = useLocation();
  let controllerName = location.pathname.split("/")[1];

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);
  const { logout } = useAuth();

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : transparentSidenav
      );
      setWhiteSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : whiteSidenav
      );
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  //START : Permission block
  // const reduxDispatch = useDispatch();
  // var permissions = useSelector(selectPermissions);

  // console.log("Sidenav", permissions);
  // useEffect(() => {
  //   reduxDispatch(getPermissions());
  // }, []);

  //END : Permission block

  //START : MDS block
  //Check only for non super admin/users
  if (userId !== 1) {
    routes.map((route) => {
      const { key } = route;
      if (key === "applicant") {
        route.isDisplayed = permissions?.includes("appView");
      }
      if (key === "company_list") {
        route.isDisplayed = permissions?.includes("cmpView");
      }
      if (key === "ra_list") {
        route.isDisplayed = permissions?.includes("raView");
      }
      if (key === "call_report_list") {
        route.isDisplayed = permissions?.includes("clrView");
      }
      if (key === "lead") {
        route.isDisplayed = permissions?.includes("ledView");
      }
      if (key === "meeting") {
        route.isDisplayed = permissions?.includes("metView");
      }
      if (key === "deal") {
        route.isDisplayed = permissions?.includes("delView");
      }
      if (key === "attestation_list") {
        route.isDisplayed = permissions?.includes("attView");
      }
      if (key === "interview_list") {
        route.isDisplayed = permissions?.includes("intView");
      }
      if (key === "booking_list") {
        route.isDisplayed = permissions?.includes("bokView");
      }
      if (key === "deployment_list") {
        route.isDisplayed = permissions?.includes("depView");
      }
      if (key === "tax_list") {
        route.isDisplayed = permissions?.includes("taxView");
      }
      if (key === "account") {
        route.isDisplayed = permissions?.includes("accView");
      }
      if (key === "task") {
        route.isDisplayed = permissions?.includes("tskView");
      }
      if (key === "staffs") {
        route.isDisplayed = permissions?.includes("usrView");
      }
      if (key === "settings") {
        route.isDisplayed = permissions?.includes("setView");
      }
      // if (key === "invoice_list") {
      //   route.isDisplayed = permissions.includes("invView");
      // }
      // if (key === "report") {
      //   route.isDisplayed = permissions.includes("repView");
      // }
    });
  }
  //END : MDS block

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map((singleRoute) => {
    const {
      type,
      name,
      icon,
      title,
      subMenu,
      noCollapse,
      key,
      isDisplayed,
      href,
      route,
      dropdown,
    } = singleRoute;
    if (isDisplayed) {
      let returnValue;
      //Custom Dropdown Created by MDS
      if (type === "dropdown") {
        let menuItems = dropdown?.map(({ name, route }) => {
          return (
            <MenuItem
              active={"/" + controllerName === route}
              component={<Link to={route} />}
            >
              {name}
            </MenuItem>
          );
        });
        returnValue = (
          <Sidebar className="mdsDropdown">
            <Menu className="mdsDropdown1">
              <SubMenu label={name} className="mdsDropdown2" icon={icon}>
                {menuItems}
              </SubMenu>
            </Menu>
          </Sidebar>
        );
      } else if (type === "collapse") {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              name={name}
              icon={icon}
              active={subMenu?.includes(controllerName)}
              noCollapse={noCollapse}
            />
          </Link>
        ) : (
          <NavLink key={key} to={route} className="mdsNavLink">
            <SidenavCollapse
              name={name}
              icon={icon}
              active={subMenu?.includes(controllerName)}
            />
          </NavLink>
        );
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }
      return returnValue;
    }
  });

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          {/* {brand && <MDBox component="img" src={brand} alt="Brand" width="2rem" />} */}
          <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography
              component="h6"
              variant="button"
              fontWeight="medium"
              color={textColor}
            >
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        sx={{ my: 0 }}
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>
      <MDBox p={2}>
        {!miniSidenav && (
          <MDButton
            component="button"
            onClick={logout}
            variant="contained"
            color="primary"
            fullWidth
          >
            Logout
          </MDButton>
        )}
      </MDBox>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
