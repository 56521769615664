import * as Yup from "yup";

export const ProfileSchema = Yup.object({
  first_name: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter first name ")
    .max(255),
  last_name: Yup.string()
    .min(2, "Please enter minimum two characters")
    .required("Please enter last name ")
    .max(255),
  mobile_number: Yup.string()
    .required("Please enter mobile number ")
    .max(20, "Maximum 20 numbers allowed"),
});
