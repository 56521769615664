// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
//import useSWR from "swr";
import { useEffect, useState } from "react";
//import { Token } from "utils/clientFunctions";
import MDButton from "components/MDButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  Tooltip,
} from "@mui/material";
//import { settingDefault } from "utils/clientFunctions";
import { ErrorMessage, Field, Form, Formik } from "formik";
import MDInput from "components/MDInput";
import * as Yup from "yup";
import { updateData } from "utils/clientFunctions";

import { fetchData } from "utils/clientFunctions";
import useSWR from "swr";

import { postData, doUpload } from "utils/clientFunctions";
import { ToastProvider, useToasts } from "react-toast-notifications";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button } from "@mui/material";
import { useNavigate, useParams, Navigate, Link } from "react-router-dom";
import MDAvatar from "components/MDAvatar";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "../../../../src/assets/css/Custom.css";
import { useFormik } from "formik";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IconButton from "@mui/material/IconButton";
import { removeDuplicates } from "utils/clientFunctions";
import { invoiceSchema } from "../components/schemas";
import { useSelector, useDispatch } from "react-redux";
import { getPermissions, selectPermissions } from "reducers/permissionSlice";
import { liveSearchData } from "layouts/commonComponents/liveSearchData";
import { isNumeric, toFixed } from "utils/clientFunctions";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
function SaveInvoice() {
  const { id: invoiceId } = useParams();

  const [paymentTypes, setPaymentTypes] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [InvoiceTypeList, setInvoiceTypeList] = useState([]);

  let [total, setTotal] = useState(0); //Sum of products
  let [subTotal, setSubTotal] = useState(0); // total-discount
  let [taxTotal, setTaxTotal] = useState(0); // subTotal tax
  let [invoiceTotal, setInvoiceTotal] = useState(0); // sum of subTotal and taxTotal

  const [usersList, setUserList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [applicantList, setApplicantList] = useState([]);
  const [raList, setRaList] = useState([]);

  const [userCompanyList, setUserCompanyList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [taxList, setTaxList] = useState([]);

  //Only for display purposes
  const [invoiceNumber, setInvoiceNumber] = useState("");

  const initDescriptionProduct = {
    applicant_id: null,
    payment_type_id: null,
    quantity: "",
    unit_price: "",
  };

  const [descriptions, setDescriptions] = useState([
    {
      products: [initDescriptionProduct],
    },
  ]);

  const { addToast } = useToasts();
  const navigate = useNavigate();
  //Start : For permission
  const dispatch = useDispatch();
  const permissions = useSelector(selectPermissions);
  if (invoiceId) {
    if (
      !(
        permissions?.user_id === 1 ||
        permissions?.permission?.includes("bokEdit")
      )
    ) {
      navigate("/Invoice_list");
    }
  } else {
    if (
      !(
        permissions?.user_id === 1 ||
        permissions?.permission?.includes("bokCreate")
      )
    ) {
      navigate("/Invoice_list");
    }
  }
  useEffect(() => {
    dispatch(getPermissions());
  }, []);
  //End : For permission

  const [formData, setFormData] = useState({
    //invoice_number: "",
    invoice_date: new Date().toISOString().slice(0, 10),
    due_date: "",
    bill_to_vendor_type: { label: "Recruiting agency", value: "ra" },
    bill_to_vendor_type_id: "",
    //discount_amount: "",
    br_user_company_id: "",
    status_id: "",
    taxes: [],
  });

  const getFetchData = async (url) => {
    return await fetchData(url);
  };

  const vendorOptions = [
    { label: "Recruiting agency", value: "ra" },
    { label: "Company", value: "company" },
    { label: "Applicant", value: "applicant" },
  ];

  useEffect(() => {
    if (invoiceId) {
      getFetchData(`invoice/${invoiceId}`).then((res) => {
        if (res.data) {
          let {
            invoice_number,
            invoice_date,
            due_date,
            bill_to_vendor_type,
            //discount_amount,
            br_user_company_info,
            status_info,
            tax_list,
            description_list,
          } = res.data;
          setInvoiceNumber(invoice_number);
          let updatedVendorType = vendorOptions?.filter((vendor) => {
            return vendor.value === bill_to_vendor_type;
          });

          let updatedTaxList = [];

          if (tax_list) {
            tax_list?.map(({ tax_info }) => {
              updatedTaxList.push(tax_info);
            });
          }

          setFormData({
            //invoice_number,
            invoice_date,
            due_date,
            bill_to_vendor_type: updatedVendorType && updatedVendorType[0],
            bill_to_vendor_type_id: res?.vendorType,
            //discount_amount,
            br_user_company_id: br_user_company_info,
            status_id: status_info,
            taxes: updatedTaxList,
          });

          let descriptionList = [];
          description_list?.map(({ description, products_list }) => {
            let productsList = [];
            products_list?.map(
              ({ applicant_info, payment_type_info, quantity, unit_price }) => {
                productsList.push({
                  applicant_id: applicant_info,
                  payment_type_id: payment_type_info,
                  quantity,
                  unit_price,
                });
              }
            );
            descriptionList.push({
              description,
              products: productsList,
            });
          });
          setDescriptions(descriptionList);
        }
      });
    }

    //Get countries list
    const countryUrl = `country/list`;
    getFetchData(countryUrl).then((res) => {
      let countryList = res.data;
      setCountryList(countryList);
      let CurrencyList = removeDuplicates(countryList, "currency_code"); //countryList?.filter((cr)=>cr.currency_code != "")
      setCurrencyList(CurrencyList);
    });

    //Get call report status list
    const statusUrl = `setting/type/18`;
    getFetchData(statusUrl).then((res) => {
      setStatusList(res.data);
    });

    //Get company list
    const companyApiUrl = `company/list?page=1`;
    getFetchData(companyApiUrl).then((res) => {
      setCompanyList(res.data);
    });

    const applicantUrl = `applicant/list?page=1`;
    getFetchData(applicantUrl).then((res) => {
      setApplicantList(res.data);
    });

    const raUrl = `recruiting_agency/list?page=1`;
    getFetchData(raUrl).then((res) => {
      setRaList(res.data);
    });

    getFetchData(`user/companies`).then((res) => {
      setUserCompanyList(res.data);
    });

    getFetchData(`tax/list/1`).then((res) => {
      setTaxList(res.data);
    });

    getFetchData(`setting/type/10`).then((res) => {
      setPaymentTypes(res.data);
    });
  }, []);

  const handleFormSubmit = async (data, resetForm) => {
    let submitData = {};
    submitData = data;

    let vendorType = data.bill_to_vendor_type?.value;
    let vendorTypeId =
      vendorType === "company"
        ? data.bill_to_vendor_type_id?.company_id
        : vendorType === "ra"
        ? data.bill_to_vendor_type_id?.ra_id
        : vendorType === "applicant"
        ? data.bill_to_vendor_type_id?.applicant_id
        : "";

    submitData.bill_to_vendor_type = vendorType;
    submitData.bill_to_vendor_type_id = vendorTypeId;
    submitData.br_user_company_id = data.br_user_company_id?.user_company_id;
    submitData.status_id = data.status_id?.setting_id;

    let taxData = [];
    if (data?.taxes?.length > 0) {
      data?.taxes.map(({ tax_id }) => {
        taxData.push(tax_id);
      });
    }
    submitData.taxes = taxData;

    //Convert Descriptions into array
    let allDescriptions = [];
    if (descriptions.length) {
      descriptions?.map((descriptionItem) => {
        const { products, description } = descriptionItem;

        let allproducts = [];
        if (description && products.length) {
          products?.map(
            ({ applicant_id, payment_type_id, quantity, unit_price }) => {
              if (applicant_id || payment_type_id) {
                allproducts.push({
                  applicant_id: applicant_id
                    ? applicant_id?.applicant_id
                    : null,
                  payment_type_id: payment_type_id
                    ? payment_type_id?.setting_id
                    : null,
                  quantity,
                  unit_price,
                });
              }
            }
          );
        }

        allDescriptions.push({
          description,
          products: allproducts,
        });
      });
    }
    submitData.descriptions = allDescriptions;

    // console.log("submitData", submitData);
    // return false;

    const invoiceUrl = `invoice`;
    const response = invoiceId
      ? await updateData(`${invoiceUrl}/${invoiceId}`, submitData)
      : await postData(invoiceUrl, submitData);

    if (!response.status) {
      if (response.message) {
        addToast(response.message, { appearance: "error", autoDismiss: true });
      }
      if (response.errors) {
        let apiErrors = response.errors;
        for (let i = 0; i < apiErrors.length; i++) {
          let errorMessage = apiErrors[i].message;
          errorMessage = errorMessage.replace(/['"]+/g, "").replace(/_/g, " ");
          errorMessage =
            errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
          addToast(errorMessage, { appearance: "error", autoDismiss: true });
        }
      }
    } else {
      let successMessage = invoiceId
        ? "Invoice has been updated successfully"
        : "New Invoice has been created successfully";

      addToast(successMessage, {
        appearance: "success",
        autoDismiss: true,
      });
      navigate(-1);
    }
  };

  const getInvoiceNumber = async (br_user_company_id) => {
    let invoice = await postData(`invoice/get-invoice-number`, {
      br_user_company_id,
    });
    let invoiceNumber = invoice.data.invoice_number;
    setInvoiceNumber(invoiceNumber);
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: formData,
    enableReinitialize: true,
    validationSchema: invoiceSchema,
    onSubmit: (values, action) => {
      handleFormSubmit(values, action);
    },
  });

  // START Manage Description
  const addMoreDescription = () => {
    setDescriptions([
      ...descriptions,
      {
        products: [initDescriptionProduct],
      },
    ]);
  };
  const handleDescriptionChange = async (e, i) => {
    const { name, value } = e.target;
    const onchangeVal = [...descriptions];
    onchangeVal[i][name] = value;
    setDescriptions(onchangeVal);
  };
  const handleDescriptionDelete = (i) => {
    const deleteVal = [...descriptions];
    deleteVal.splice(i, 1);
    setDescriptions(deleteVal);
  };
  // END Manage Description

  // START Manage Description Products
  const addMoreProduct = (i) => {
    let allDescription = [...descriptions];
    let products = allDescription[i]["products"];
    products.push(initDescriptionProduct);
    allDescription[i]["products"] = products;
    setDescriptions(allDescription);
  };

  const handleProductChange = async (e, mainIndex, subIndex) => {
    const { name, value } = e.target;
    const onchangeVal = [...descriptions];
    onchangeVal[mainIndex]["products"][subIndex][name] = value;
    setDescriptions(onchangeVal);
  };

  const handleProductDelete = (mainIndx, subIndex) => {
    let allDescription = [...descriptions];
    let products = allDescription[mainIndx]["products"];
    products.splice(subIndex, 1);
    allDescription[mainIndx]["products"] = products;
    setDescriptions(allDescription);
  };
  // END Manage Description Products

  const commaonError = (fieldName) => {
    return errors?.[fieldName] && touched?.[fieldName] ? (
      <p className="formError">{errors[fieldName]}</p>
    ) : null;
  };
  const searchSpot = (value, key) => {
    liveSearchData({
      countryId: null,
      value,
      key,
      setRaList,
      setCompanyList,
      setApplicantList,
      setUserList,
    });
  };

  //Invoice Calculate
  useEffect(() => {
    let initTotal = 0;
    let initSubTotal = 0;
    let initTaxTotal = 0;
    let initInvoiceTotal = 0;

    // GetSum of products
    descriptions?.map((description) => {
      description?.products.map(({ unit_price, quantity }) => {
        if (unit_price && quantity) {
          initTotal = initTotal + unit_price * quantity;
        }
      });
    });
    setTotal(initTotal);

    // if (values?.discount_amount) {
    //   initSubTotal = initTotal - parseFloat(values?.discount_amount);
    // } else {
    //   initSubTotal = initTotal;
    // }
    initSubTotal = initTotal;
    setSubTotal(initSubTotal);

    //Get the sum of taxes in percentage
    let taxTotalPercentage = 0;
    if (values?.taxes.length > 0) {
      taxTotalPercentage = toFixed(
        values?.taxes
          .map(({ percentage }) => percentage * 1)
          .reduce((sum, i) => sum + i, 0)
      );
    }

    initTaxTotal = (taxTotalPercentage * initSubTotal) / 100;
    setTaxTotal(initTaxTotal);

    initInvoiceTotal = initSubTotal + initTaxTotal;
    setInvoiceTotal(initInvoiceTotal);
  }, [descriptions, values?.taxes]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Formik
          initialValues={formData}
          enableReinitialze={true}
          onSubmit={handleSubmit}
        >
          <Form>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-5}
                    py={1}
                    borderRadius="lg"
                    px={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    variant="gradient"
                    bgColor="info"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      {`${invoiceId ? "Update" : "New"} `} Invoice
                    </MDTypography>
                    <MDButton
                      variant="outlined"
                      onClick={() => navigate(-1)}
                      color="white"
                      size="small"
                    >
                      Back
                    </MDButton>
                  </MDBox>
                  <MDBox
                    variant="contained"
                    bgColor="white"
                    color="black"
                    borderRadius="md"
                    shadow="md"
                  >
                    <DialogContent>
                      <Box sx={{ flexGrow: 1 }}>
                        <MDBox pt={1} pb={1}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.br_user_company_id}
                                  size="small"
                                  name="br_user_company_id"
                                  onChange={(event, newValue) => {
                                    if (newValue) {
                                      setFieldValue(
                                        "br_user_company_id",
                                        newValue
                                      );
                                      getInvoiceNumber(
                                        newValue.user_company_id
                                      );
                                    } else {
                                      setFieldValue("br_user_company_id", "");
                                    }
                                  }}
                                  options={userCompanyList}
                                  getOptionLabel={(userCompanyList) =>
                                    userCompanyList.name || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Select Branch"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {commaonError("br_user_company_id")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  type="text"
                                  name="invoice_number"
                                  label="Invoice Number"
                                  disabled
                                  fullWidth
                                  value={invoiceNumber}
                                />
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  type="date"
                                  name="invoice_date"
                                  label="Invoice Date"
                                  fullWidth
                                  value={values.invoice_date}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("invoice_date")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  type="date"
                                  name="due_date"
                                  label="Due Date"
                                  fullWidth
                                  value={values.due_date}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("due_date")}
                              </MDBox>
                            </Grid>
                            {/* <Grid item xs={12} md={3}>
                              <MDBox mb={2}>
                                <MDInput
                                  type="number"
                                  name="discount_amount"
                                  label="Discount Amount"
                                  fullWidth
                                  value={values.discount_amount}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {commaonError("discount_amount")}
                              </MDBox>
                            </Grid> */}

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.bill_to_vendor_type}
                                  size="small"
                                  defaultValue={vendorOptions[0]}
                                  name="bill_to_vendor_type"
                                  onChange={(event, newValue) => {
                                    if (newValue) {
                                      setFieldValue(
                                        "bill_to_vendor_type",
                                        newValue
                                      );
                                      setFieldValue(
                                        "bill_to_vendor_type_id",
                                        ""
                                      );
                                    } else {
                                      setFieldValue("bill_to_vendor_type", "");
                                    }
                                  }}
                                  options={vendorOptions}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Bill To"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {commaonError("bill_to_vendor_type")}
                              </MDBox>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.bill_to_vendor_type_id}
                                  size="small"
                                  name="bill_to_vendor_type_id"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue(
                                          "bill_to_vendor_type_id",
                                          newValue
                                        )
                                      : setFieldValue(
                                          "bill_to_vendor_type_id",
                                          ""
                                        )
                                  }
                                  options={
                                    values.bill_to_vendor_type?.value === "ra"
                                      ? raList
                                      : values.bill_to_vendor_type?.value ===
                                        "applicant"
                                      ? applicantList
                                      : values.bill_to_vendor_type?.value ===
                                        "company"
                                      ? companyList
                                      : raList
                                  }
                                  getOptionLabel={(vendorTypeOptions) => {
                                    if (
                                      values.bill_to_vendor_type?.value === "ra"
                                    ) {
                                      return vendorTypeOptions?.ra_name || "";
                                    } else if (
                                      values.bill_to_vendor_type?.value ===
                                      "applicant"
                                    ) {
                                      return vendorTypeOptions?.first_name
                                        ? vendorTypeOptions?.first_name +
                                            " " +
                                            vendorTypeOptions?.last_name
                                        : "";
                                    } else if (
                                      values.bill_to_vendor_type?.value ===
                                      "company"
                                    ) {
                                      return vendorTypeOptions?.name
                                        ? vendorTypeOptions?.name
                                        : "";
                                    } else {
                                      return (
                                        vendorTypeOptions?.ra_name || "" || ""
                                      );
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Select Vendor Name"
                                      InputLabelProps={{ shrink: true }}
                                      onChange={(e) =>
                                        searchSpot(
                                          values.bill_to_vendor_type?.value,
                                          e.target.value
                                        )
                                      }
                                    />
                                  )}
                                />
                                {commaonError("bill_to_vendor_type_id")}
                              </MDBox>
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <MDBox mb={2} className="mdsAutocompleteGrid">
                                <Autocomplete
                                  value={values.status_id}
                                  size="small"
                                  name="status_id"
                                  onChange={(event, newValue) =>
                                    newValue
                                      ? setFieldValue("status_id", newValue)
                                      : setFieldValue("status_id", "")
                                  }
                                  options={statusList}
                                  getOptionLabel={(statusList) =>
                                    statusList.setting_value || ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Status"
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  )}
                                />
                                {commaonError("status_id")}
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Box>
                    </DialogContent>
                  </MDBox>
                </Card>

                {/* Start Description Management */}
                <Card className="mt4rem">
                  <MDBox
                    mx={2}
                    mt={-5}
                    py={1}
                    borderRadius="lg"
                    px={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    variant="gradient"
                    bgColor="info"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Description
                    </MDTypography>
                    <MDButton
                      variant="outlined"
                      onClick={() => addMoreDescription()}
                      color="white"
                      size="small"
                    >
                      Add
                    </MDButton>
                  </MDBox>

                  <MDBox
                    variant="contained"
                    bgColor="white"
                    color="black"
                    borderRadius="md"
                    shadow="md"
                  >
                    <DialogContent>
                      <Box sx={{ flexGrow: 1 }}>
                        <MDBox pt={1} pb={1}>
                          <Grid container spacing={2}>
                            {descriptions.map((val, i) => (
                              <Grid
                                item
                                className="borderBottom"
                                style={{
                                  marginBottom: "10px",
                                }}
                                container
                                xs={12}
                                md={12}
                                key={i}
                                spacing={2}
                              >
                                <Grid item xs={12} md={12}>
                                  <TableContainer sx={{ borderRadius: 0 }}>
                                    <Table className="border" stickyHeader>
                                      <TableBody>
                                        <TableRow>
                                          <TableCell colSpan={5}>
                                            <Grid item xs={12} md={3}>
                                              <MDBox mb={2}>
                                                <MDInput
                                                  name="description"
                                                  type="text"
                                                  required
                                                  fullWidth
                                                  value={val.description}
                                                  label={`Description`}
                                                  onChange={(e) =>
                                                    handleDescriptionChange(
                                                      e,
                                                      i
                                                    )
                                                  }
                                                />
                                              </MDBox>
                                            </Grid>
                                          </TableCell>
                                          <TableCell>
                                            <Grid item xs={12} md={2}>
                                              <IconButton className="mdsRemoveBtn">
                                                <DeleteForeverIcon
                                                  variant="gradient"
                                                  color="error"
                                                  type="button"
                                                  fontSize="large"
                                                  onClick={() =>
                                                    handleDescriptionDelete(i)
                                                  }
                                                />
                                              </IconButton>
                                            </Grid>
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>
                                            <b>Applicant</b>
                                          </TableCell>
                                          <TableCell>
                                            <b>Description</b>
                                          </TableCell>
                                          <TableCell>
                                            <b>Unit Amount</b>
                                          </TableCell>
                                          <TableCell>
                                            <b>Quantity</b>
                                          </TableCell>
                                          <TableCell>
                                            <b>Total Amount</b>
                                          </TableCell>
                                          <TableCell>
                                            <b>Action</b>
                                          </TableCell>
                                        </TableRow>
                                        {val?.products.map((product, index) => (
                                          <TableRow>
                                            <TableCell style={{ width: "20%" }}>
                                              <Grid item xs={12} md={12}>
                                                <MDBox
                                                  mb={2}
                                                  className="mdsAutocompleteGridNoLabel"
                                                >
                                                  <Autocomplete
                                                    value={product.applicant_id}
                                                    size="small"
                                                    name="applicant_id"
                                                    onChange={(e, newValue) => {
                                                      handleProductChange(
                                                        {
                                                          target: {
                                                            name: "applicant_id",
                                                            value: newValue,
                                                          },
                                                        },
                                                        i,
                                                        index
                                                      );
                                                    }}
                                                    options={applicantList}
                                                    getOptionLabel={(
                                                      applicantList
                                                    ) => {
                                                      return applicantList?.passport_number
                                                        ? applicantList?.passport_number
                                                        : "";
                                                    }}
                                                    renderInput={(params) => (
                                                      <TextField
                                                        {...params}
                                                        InputLabelProps={{
                                                          shrink: true,
                                                        }}
                                                        onChange={(e) =>
                                                          searchSpot(
                                                            "applicant",
                                                            e.target.value
                                                          )
                                                        }
                                                      />
                                                    )}
                                                  />
                                                  <span className="applicant_name">
                                                    {product?.applicant_id
                                                      ? product?.applicant_id
                                                          ?.first_name +
                                                        " " +
                                                        product?.applicant_id
                                                          ?.last_name
                                                      : null}
                                                  </span>
                                                </MDBox>
                                              </Grid>
                                            </TableCell>
                                            <TableCell style={{ width: "20%" }}>
                                              <Grid item xs={12} md={12}>
                                                <MDBox
                                                  mb={2}
                                                  className="mdsAutocompleteGrid"
                                                >
                                                  <Autocomplete
                                                    value={
                                                      product.payment_type_id
                                                    }
                                                    size="small"
                                                    name="payment_type_id"
                                                    onChange={(e, newValue) => {
                                                      handleProductChange(
                                                        {
                                                          target: {
                                                            name: "payment_type_id",
                                                            value: newValue,
                                                          },
                                                        },
                                                        i,
                                                        index
                                                      );
                                                    }}
                                                    options={paymentTypes}
                                                    getOptionLabel={(
                                                      paymentTypes
                                                    ) =>
                                                      paymentTypes.setting_value ||
                                                      ""
                                                    }
                                                    renderInput={(params) => (
                                                      <TextField
                                                        {...params}
                                                        label="Payment Type"
                                                        InputLabelProps={{
                                                          shrink: true,
                                                        }}
                                                      />
                                                    )}
                                                  />
                                                </MDBox>
                                                {/* <MDBox mb={2}>
                                                  <MDInput
                                                    name="other_description"
                                                    type="text"
                                                    required={
                                                      product.applicant_id
                                                        ? false
                                                        : true
                                                    }
                                                    fullWidth
                                                    value={
                                                      product.other_description
                                                    }
                                                    onChange={(e) =>
                                                      handleProductChange(
                                                        e,
                                                        i,
                                                        index
                                                      )
                                                    }
                                                  />
                                                </MDBox> */}
                                              </Grid>
                                            </TableCell>
                                            <TableCell style={{ width: "20%" }}>
                                              <Grid item xs={12} md={12}>
                                                <MDBox mb={2}>
                                                  <MDInput
                                                    name="unit_price"
                                                    type="number"
                                                    required
                                                    fullWidth
                                                    value={product.unit_price}
                                                    onChange={(e) =>
                                                      handleProductChange(
                                                        e,
                                                        i,
                                                        index
                                                      )
                                                    }
                                                  />
                                                </MDBox>
                                              </Grid>
                                            </TableCell>
                                            <TableCell style={{ width: "10%" }}>
                                              <Grid item xs={12} md={12}>
                                                <MDBox mb={2}>
                                                  <MDInput
                                                    name="quantity"
                                                    type="number"
                                                    required
                                                    fullWidth
                                                    value={product.quantity}
                                                    onChange={(e) =>
                                                      handleProductChange(
                                                        e,
                                                        i,
                                                        index
                                                      )
                                                    }
                                                  />
                                                </MDBox>
                                              </Grid>
                                            </TableCell>
                                            <TableCell style={{ width: "20%" }}>
                                              <Grid item xs={12} md={12}>
                                                <MDBox mb={2}>
                                                  <MDInput
                                                    type="text"
                                                    fullWidth
                                                    disabled
                                                    value={
                                                      product.quantity &&
                                                      product.unit_price
                                                        ? toFixed(
                                                            product.quantity *
                                                              product.unit_price
                                                          )
                                                        : ""
                                                    }
                                                  />
                                                </MDBox>
                                              </Grid>
                                            </TableCell>
                                            <TableCell style={{ width: "10%" }}>
                                              <Grid item xs={12} md={12}>
                                                <IconButton className="mdsRemoveBtn">
                                                  <DeleteForeverIcon
                                                    variant="gradient"
                                                    color="error"
                                                    type="button"
                                                    fontSize="large"
                                                    onClick={() =>
                                                      handleProductDelete(
                                                        i,
                                                        index
                                                      )
                                                    }
                                                  />
                                                </IconButton>
                                              </Grid>
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                        {/* Repeat this */}
                                        <TableRow>
                                          <TableCell colSpan={5}></TableCell>
                                          <TableCell>
                                            <Grid item xs={12} md={2}>
                                              <IconButton className="mdsRemoveBtn">
                                                <AddCircleOutlineIcon
                                                  variant="gradient"
                                                  color="primary"
                                                  type="button"
                                                  fontSize="large"
                                                  onClick={() =>
                                                    addMoreProduct(i)
                                                  }
                                                />
                                              </IconButton>
                                            </Grid>
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </Grid>
                              </Grid>
                            ))}
                            <Grid item xs={12} md={12}>
                              <TableContainer sx={{ borderRadius: 0 }}>
                                <Table className="border" stickyHeader>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell
                                        colSpan={5}
                                        align="right"
                                        style={{ width: "70%" }}
                                      >
                                        <b>Total :</b>
                                      </TableCell>
                                      <TableCell>
                                        {
                                          values?.br_user_company_id
                                            ?.currency_info?.currency_code
                                        }{" "}
                                        {toFixed(total)}
                                      </TableCell>
                                    </TableRow>
                                    {/* <TableRow>
                                      <TableCell
                                        colSpan={5}
                                        align="right"
                                        style={{ width: "70%" }}
                                      >
                                        <b>Discount Amount :</b>
                                      </TableCell>
                                      <TableCell>
                                        {
                                          values?.br_user_company_id
                                            ?.currency_info?.currency_code
                                        }{" "}
                                        {isNumeric(values?.discount_amount)
                                          ? toFixed(values.discount_amount * 1)
                                          : toFixed(0)}
                                      </TableCell>
                                    </TableRow> */}
                                    <TableRow>
                                      <TableCell
                                        colSpan={5}
                                        align="right"
                                        style={{ width: "70%" }}
                                      >
                                        <b>Sub Total :</b>
                                      </TableCell>
                                      <TableCell>
                                        {
                                          values?.br_user_company_id
                                            ?.currency_info?.currency_code
                                        }{" "}
                                        {toFixed(subTotal)}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell
                                        colSpan={5}
                                        align="right"
                                        style={{
                                          width: "70%",
                                          verticalAlign: "middle",
                                        }}
                                      >
                                        <Grid container spacing={2}>
                                          <Grid item xs={12} md={6} mt={1}>
                                            <MDBox
                                              mb={2}
                                              className="mdsAutocompleteGrid"
                                            >
                                              <Autocomplete
                                                multiple
                                                value={values.taxes}
                                                id="checkboxes-tags-demo"
                                                size="small"
                                                name="taxes"
                                                onChange={(event, newValue) =>
                                                  newValue
                                                    ? setFieldValue(
                                                        "taxes",
                                                        newValue
                                                      )
                                                    : setFieldValue("taxes", "")
                                                }
                                                isOptionEqualToValue={(
                                                  option,
                                                  value
                                                ) =>
                                                  option.tax_id === value.tax_id
                                                }
                                                options={taxList}
                                                disableCloseOnSelect
                                                getOptionLabel={(option) =>
                                                  option.name +
                                                  " (" +
                                                  option.percentage * 1 +
                                                  "%)"
                                                }
                                                renderOption={(
                                                  props,
                                                  option,
                                                  { selected }
                                                ) => (
                                                  <li {...props}>
                                                    <Checkbox
                                                      icon={icon}
                                                      checkedIcon={checkedIcon}
                                                      style={{ marginRight: 8 }}
                                                      checked={selected}
                                                    />
                                                    {option.name +
                                                      " (" +
                                                      option.percentage * 1 +
                                                      "%)"}
                                                  </li>
                                                )}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    label="Select Taxes"
                                                    placeholder="Tax"
                                                    InputLabelProps={{
                                                      shrink: true,
                                                    }}
                                                    // onChange={(e) =>
                                                    //   searchSpot("user",e.target.value)
                                                    // }
                                                  />
                                                )}
                                              />
                                            </MDBox>
                                          </Grid>
                                          <Grid item xs={12} md={6} mt={2.5}>
                                            <b>Tax Total :</b>
                                          </Grid>
                                        </Grid>
                                      </TableCell>
                                      <TableCell>
                                        {
                                          values?.br_user_company_id
                                            ?.currency_info?.currency_code
                                        }{" "}
                                        {toFixed(taxTotal)}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell colSpan={5} align="right">
                                        <b>Invoice Total :</b>
                                      </TableCell>
                                      <TableCell>
                                        {
                                          values?.br_user_company_id
                                            ?.currency_info?.currency_code
                                        }{" "}
                                        {toFixed(invoiceTotal)}
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Box>
                    </DialogContent>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
            <MDBox mt={2} display="flex" alignItems="center">
              <MDButton
                variant="gradient"
                color="primary"
                size="small"
                type="submit"
                className="saveButton"
              >
                Save
              </MDButton>
            </MDBox>
          </Form>
        </Formik>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default SaveInvoice;
