// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { fetchData } from "utils/clientFunctions";
import useSWR from "swr";
import { useEffect, useState } from "react";
import { Token } from "utils/clientFunctions";
import MDButton from "components/MDButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  Tooltip,
} from "@mui/material";
import { settingDefault } from "utils/clientFunctions";
import { ErrorMessage, Field, Form, Formik } from "formik";
import MDInput from "components/MDInput";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MDAvatar from "components/MDAvatar";
import { Delete, Edit, RemoveRedEye, TramSharp } from "@mui/icons-material";
import { useAuth } from "utils/auth";
import pxToRem from "assets/theme/functions/pxToRem";
import MobileList from "../components/mobileList";
import { dateFormat } from "utils/clientFunctions";
import { CardTopBoxAdd } from "layouts/commonComponents/cardTopBoxAdd";
import { dateTimeFormat } from "utils/clientFunctions";
import { useSelector, useDispatch } from "react-redux";
import { getPermissions, selectPermissions } from "reducers/permissionSlice";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function DeploymentList() {
  const [pageIndex, setPageIndex] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [pageResize, setPageResize] = useState(
    window.innerWidth < 992 ? true : false
  );
  const navigate = useNavigate();

  //Start : For permission
  const dispatch = useDispatch();
  var permissions = useSelector(selectPermissions);
  if (
    !(
      permissions?.user_id === 1 || permissions?.permission?.includes("depView")
    )
  ) {
    navigate("/dashboard");
  }
  let showAddButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("depCreate")
      ? true
      : false;
  let showEditButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("depEdit")
      ? true
      : false;
  let showDeleteButton =
    permissions?.user_id === 1 || permissions?.permission?.includes("depDelete")
      ? true
      : false;
  useEffect(() => {
    dispatch(getPermissions());
  }, []);
  //End : For permission

  const [DeploymentList, setDeploymentList] = useState([]);

  //View More RA data
  const [deploymentData, setDeploymentData] = useState({});
  const { logout } = useAuth();

  const initialLoadData = async () => {
    const url = `deployment/list/${pageIndex}`;
    const data = await fetchData(url);
    if (
      data?.name === "JsonWebTokenError" ||
      data?.name === "TokenExpiredError" ||
      data?.message === "You are not authorized"
    ) {
      logout();
    }
    if (data && data.data) {
      let deploymentCount = data.count / 10;
      deploymentCount = Math.ceil(deploymentCount);
      setPageCount(deploymentCount);
      setDeploymentList(data.data || []);
    }
  };

  useEffect(() => {
    initialLoadData();
  }, []);

  const handleChange = (event, value) => {
    setPageIndex(value);
  };

  //Dialog popup
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = (callReportInfo) => {
    setDeploymentData(callReportInfo);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setDeploymentData({});
    setOpenDialog(false);
  };

  window.addEventListener("resize", () => {
    if (window.innerWidth < 992) {
      setPageResize(true);
    } else {
      setPageResize(false);
    }
  });

  const getFetchData = async (settingUrl) => {
    return await fetchData(settingUrl);
  };

  const displayTypoInfo = (label, value) => {
    return (
      <>
        <Grid item xs={6} md={6} lg={6}>
          <MDTypography variant="h6" fontWeight="medium">
            {label}
          </MDTypography>
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <MDTypography variant="h6" fontWeight="regular">
            {value}
          </MDTypography>
        </Grid>
      </>
    );
  };

  const ViewMoreRa = ({ openDialog, deploymentData, handleCloseDialog }) => {
    let {
      deployment_id,
      deployment_date,
      applicant_info,
      comment,
      deployment_type_info,
      status_info,
    } = deploymentData;

    return (
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        fullScreen={window.innerWidth < 768 ? true : false}
      >
        <DialogTitle sx={{ m: 0 }} id="customized-dialog-title">
          {dateFormat(deployment_date)}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container p={2} spacing={1}>
            {displayTypoInfo("Deployment Date", dateFormat(deployment_date))}
            {displayTypoInfo(
              "Application Name",
              applicant_info?.salutation +
                " " +
                applicant_info?.first_name +
                "  " +
                applicant_info?.last_name
            )}
            {displayTypoInfo(
              "Deployment Type",
              deployment_type_info?.setting_value
            )}
            {displayTypoInfo("Remarks", comment)}
            {displayTypoInfo("Status", status_info?.setting_value)}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialog}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <CardTopBoxAdd
                title={"Deployments"}
                link={"/deployment"}
                showAddButton={showAddButton}
              />
              <MDBox>
                <Grid container p={2} spacing={2}>
                  {!pageResize && (
                    <Grid item xs={12} md={12} lg={12}>
                      <MDBox
                        borderRadius="lg"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p={1}
                        sx={{
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Date of Deployment
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Applicant name
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Type of Deployment
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={2}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Status
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={1}>
                          <MDTypography
                            fontSize={pxToRem(14)}
                            fontWeight="medium"
                          >
                            Action
                          </MDTypography>
                        </Grid>
                      </MDBox>
                    </Grid>
                  )}
                  {DeploymentList.map((deploymentItem, index) => {
                    let {
                      deployment_id,
                      deployment_date,
                      applicant_info,
                      deployment_type_info,
                      status_info,
                    } = deploymentItem;
                    return (
                      <>
                        {!pageResize ? (
                          <Grid
                            item
                            key={deployment_id}
                            xs={12}
                            md={12}
                            lg={12}
                          >
                            <MDBox
                              borderRadius="lg"
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              p={1}
                              sx={{
                                border: ({
                                  borders: { borderWidth, borderColor },
                                }) => `${borderWidth[1]} solid ${borderColor}`,
                              }}
                            >
                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {dateFormat(deployment_date)}
                                </MDTypography>
                              </Grid>
                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {applicant_info?.salutation +
                                    " " +
                                    applicant_info?.first_name +
                                    " " +
                                    applicant_info?.last_name}
                                </MDTypography>
                              </Grid>

                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {deployment_type_info?.setting_value}
                                </MDTypography>
                              </Grid>
                              <Grid item xs={4} md={4} lg={2}>
                                <MDTypography
                                  fontSize={pxToRem(14)}
                                  fontWeight="regular"
                                >
                                  {status_info?.setting_value}
                                </MDTypography>
                              </Grid>

                              <Grid item xs={4} md={4} lg={1}>
                                <IconButton
                                  size="small"
                                  disableRipple
                                  color="inherit"
                                  onClick={() =>
                                    handleOpenDialog(deploymentItem)
                                  }
                                >
                                  <RemoveRedEye />
                                </IconButton>
                                {showEditButton && (
                                  <IconButton
                                    size="small"
                                    disableRipple
                                    color="inherit"
                                    onClick={() =>
                                      navigate(`/deployment/${deployment_id}`)
                                    }
                                  >
                                    <Edit />
                                  </IconButton>
                                )}
                                {showDeleteButton && (
                                  <IconButton
                                    size="small"
                                    disableRipple
                                    color="inherit"
                                  >
                                    <Delete />
                                  </IconButton>
                                )}
                              </Grid>
                            </MDBox>
                          </Grid>
                        ) : (
                          <MobileList
                            key={deployment_id}
                            deploymentItem={deploymentItem}
                            index={index + 1}
                            handleOpenDialog={handleOpenDialog}
                            showEditButton={showEditButton}
                            showDeleteButton={showDeleteButton}
                          />
                        )}
                      </>
                    );
                  })}
                </Grid>
                <Grid item xs={12} md={3}>
                  <Stack spacing={2} mb={2} ml={2}>
                    <Pagination
                      count={pageCount}
                      page={pageIndex}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Stack>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <ViewMoreRa
        openDialog={openDialog}
        deploymentData={deploymentData}
        handleCloseDialog={handleCloseDialog}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default DeploymentList;
